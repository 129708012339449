import React from 'react';
import { t } from "i18next";
import { Modal } from "react-bootstrap"
import "./style.css";

export const Launcher = (props) => {
    const url = props.data.Launcher.url;
    const close = () => {
        props.data.Launcher.func.close();
    }

    return <Modal
        className="LauncherModal"
        size="lg"
        show={props.data.Launcher.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center p-0">
            {props.data.isMobile ? <>
                <h4 className="text-center">{t('launch.please_click_below_button_to_continue')}</h4>
                {props.data.Launcher.isGame ? <>
                    <a className="btn btn-default" href={url} target="_blank" rel="noreferrer">{t('launch.launch_game')}</a>
                </> : <>
                    <a className="btn btn-default" href={url} target="_blank" rel="noreferrer">{t('launch.pay_now')}</a>
                </>}

            </> : <iframe title="url" className="w-100 h-100" src={url} allowFullScreen={true}
                webkitallowfullscreen="true"
                mozallowfullscreen="true" />}
            <button className="btn btn-close" onClick={close}></button>
        </Modal.Body>
    </Modal>
}