import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import "./GamePage.css";
import { Container } from "react-bootstrap";
import { t } from "i18next";
import { category as category_js } from '../../Models/Pixel/Pixel';
import Game from "../../Components/Games/Game";

function GamePage(props) {
  const seo = props.data.Seo.data;
  const helpers = props.data.helpers;
  const category = props.current_category;

  const isAuth = () => {
    return props.data.Authentication.func.isLogin();
  };

  useEffect(() => {
    category_js(category);
    return () => { };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  return (
    <div className={isAuth() ? "content logined" : "content"}>
      <>
        <Helmet>
          <title> {seo ? helpers.t(seo.meta_title) : t(category) + " | BD88"}</title>
          <meta name="keywords" content={seo ? helpers.t(seo.meta_keyword) : ""} />
          <meta name="description" content={seo ? helpers.t(seo.meta_description) : ""} />
        </Helmet>

        <Container className="pb-4 px-lg-4">
          <Game data={props.data} current_category={category} forceRefresh={true} />
        </Container>
      </>
    </div>
  );
}

export default GamePage;
