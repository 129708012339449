import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import { toast } from "react-toastify";
import { UserService } from "../../Models/User/UserService";
import { useFilePicker } from 'use-file-picker';
import "./style.css"

export const IdentifyVerify = (props) => {
    const authentication = props.data.Authentication;
    const user = authentication.user;
    const IdentifyVerify = props.data.IdentifyVerify;

    const [isSubmit, setIsSubmit] = useState(false);
    const [isInit, setIsInit] = useState(false);
    const [form, setForm] = useState({ attachment: {} });
    const [fileId, setFileID] = useState(null);

    const [openFileSelector, { filesContent }] = useFilePicker({
        multiple: false,
        accept: '.jpg,.jpeg,.png',
        limitFilesConfig: { min: 1, max: 1 },
        readAs: 'DataURL',
        maxFileSize: 10,
    });

    useEffect(() => {
        setIsInit(true)
    }, [isInit]);

    const close = () => {
        IdentifyVerify.func.close()
    }

    const chooseFile = (id) => {
        setFileID(id);
        openFileSelector();
    }

    useEffect(() => {
        if (filesContent[0]) {
            setForm({ ...form, attachment: { ...form.attachment, [fileId]: filesContent[0] } })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesContent]);

    const verify = async (e) => {
        setIsSubmit(true);
        e.preventDefault();

        if (filesContent[0] !== undefined) {
            UserService.verify_identify({ ...form }).then((response) => {
                setIsSubmit(false);
                if (response.status) {
                    toast.success(t(response.message));
                    props.data.Authentication.func.get();
                    close();
                }
            }).catch((res) => {
                setIsSubmit(false);
            })
        } else {
            toast.error(t('identify.required'))
            setIsSubmit(false);
        }
    }

    return <Modal
        className="IdentifyVerify"
        size="lg"
        show={IdentifyVerify.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('identify_verify.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={verify}>
                <p>{t('identify_verify.description')}</p>
                <Row>
                    {Object.values(user.user_identifies).map((v, i) => {
                        return <Col key={i} xs={12}>
                            <p className="fs-normal mb-0">
                                {t(v.name)}
                            </p>
                            <div className="d-flex align-content-center align-items-center mb-3">
                                <Button variant="default" size="sm" className="me-2 w-30 fs-smaller" onClick={() => chooseFile(v.id)}>{t('choose_file')}</Button>
                                <span className="fs-smallest">{form.attachment.hasOwnProperty(v.id) ? form.attachment[v.id].name : t('upload_desc')}</span>
                            </div>
                        </Col>
                    })}
                    <Col xs={12} className="text-center">
                        <Button variant="blue" className="w-100" disabled={isSubmit} onClick={verify}>{t('identify_verify.submit')}</Button>
                    </Col>
                </Row>
            </Form>
        </Modal.Body>
    </Modal>
}