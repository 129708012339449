import React from 'react';
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import MobileFooter from "./Components/MobileFooter/MobileFooter";
import MobileSidebar from "./Components/MobileSidebar/MobileSidebar";

import ScrollToTop from "./Scripts/scroll_to_top";
import './App.css';

import Home from "./Pages/Home/Home";
import Page from "./Pages/Page/Page";
import Promos from "./Pages/Promos/Promos";
import Profile from "./Pages/Profile/Profile";
import Reward from "./Pages/Reward/Reward";

import AuthenticationHook from "./Models/User/AuthenticationHook";
import MobileSidebarHook from "./Components/MobileSidebar/MobileSidebarHook";
import MobileFooterHook from "./Components/MobileFooter/MobileFooterHook";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './Scripts/i18n';
import './Scripts/axios';
import './Scripts/query';
import './Scripts/device_detect';
import './Scripts/gtag';

import helpers from "./Scripts/helpers";
import Footer from "./Components/Footer/Footer";
import Loading from "./Components/Loading/Loading";

import { RegisterModal } from "./Components/Register/RegisterModal";
import RegisterModalHook from "./Components/Register/RegisterModalHook";

import { LoginModal } from "./Components/Login/LoginModal";
import LoginModalHook from "./Components/Login/LoginModalHook";

import { ForgetPasswordModal } from "./Components/ForgetPasswordModal/ForgetPasswordModal";
import ForgetPasswordModalHook from "./Components/ForgetPasswordModal/ForgetPasswordModalHook";

import { CashierModal } from "./Components/Cashier/CashierModal";
import CashierModalHook from "./Components/Cashier/CashierModalHook";

import { PromoTerms } from "./Components/PromoTerms/PromoTerms";
import PromoTermsHook from "./Components/PromoTerms/PromoTermsHook";

import TransactionCanvas from "./Components/TransactionCanvas/TransactionCanvas";
import TransactionCanvasHook from "./Components/TransactionCanvas/TransactionCanvasHook";

import PaymentCanvas from "./Components/PaymentCanvas/PaymentCanvas";
import PaymentCanvasHook from "./Components/PaymentCanvas/PaymentCanvasHook";

import { EmailVerify } from "./Components/ProfileVerify/EmailVerify";
import EmailVerifyHook from "./Components/ProfileVerify/EmailVerifyHook";

import { PhoneVerify } from "./Components/ProfileVerify/Phone";
import PhoneVerifyHook from "./Components/ProfileVerify/PhoneVerifyHook";

import { IdentifyVerify } from "./Components/ProfileVerify/IdentifyVerify";
import IdentifyVerifyHook from "./Components/ProfileVerify/IdentifyVerifyHook";

import MessageCanvas from "./Components/MessageCanvas/MessageCanvas";
import MessageHook from "./Components/MessageCanvas/MessageHook";
import Contact from "./Pages/Contact/Contact";
import ProviderHook from "./Models/Game/ProviderHook";
import PromoHook from "./Models/Page/PromoHook";
import GamePage from "./Pages/Game/GamePage";
import InboxCanvasHook from "./Components/InboxCanvas/InboxCanvasHook";
import InboxCanvas from "./Components/InboxCanvas/InboxCanvas";
import SearchCanvasHook from "./Components/SearchCanvas/SearchCanvasHook";
import SearchCanvas from "./Components/SearchCanvas/SearchCanvas";
import ComposeCanvasHook from "./Components/ComposeCanvas/ComposeCanvasHook";
import ComposeCanvas from "./Components/ComposeCanvas/ComposeCanvas";
import Sidebar from "./Components/Sidebar/Sidebar";
import IsMobile from "./Scripts/device_detect";
import SidebarHook from "./Components/Sidebar/SidebarHook";
import MaintenanceModalHook from "./Components/MaintenanceModal/MaintenanceModalHook";
import { MaintenanceModal } from "./Components/MaintenanceModal/MaintenanceModal";
import LauncherHook from "./Components/Launcher/LauncherHook";
import { Launcher } from "./Components/Launcher/Launcher";
import LanguageHook from "./Models/Language/Language";
import TopBar from "./Components/TopBar/TopBar";
import CampaignHook from "./Models/Campaign/CampaignHook";
import { LauncherLoading } from "./Components/Launcher/LauncherLoading";
import PopupHook from "./Components/Popup/PopupHook";
import { Popup } from "./Components/Popup/Popup";
import TopBarHook from "./Components/TopBar/TopBarHook";
import SeoHook from "./Models/Seo/SeoHook";
import MissionModalHook from "./Components/Mission/MissionModalHook";
import { MissionModal } from "./Components/Mission/MissionModal";
import Referral from "./Pages/Referral/Referral";
import FloatingIconHook from "./Components/FloatingIcon/FloatingIconHook";
import FloatingIcon from "./Components/FloatingIcon/FloatingIcon";
import { InactivePopup } from "./Components/InactivePopup/InactivePopup";
import InactivePopupHook from "./Components/InactivePopup/InactivePopupHook";
import PopularGameHook from './Models/Game/PopularGameHook';
import NewGameHook from './Models/Game/NewGameHook';
import AngpowHook from './Components/SpecialEvents/AngpowHook';
import Angpow from './Components/SpecialEvents/Angpow';
import LiveChatHook from './Components/LiveChat/LiveChatHook';
import { LiveChat } from './Components/LiveChat/LiveChat';
function App() {

  const data = {
    isMobile: IsMobile(),
    toast: {
      success: toast.success,
      error: toast.error,
    },
    Authentication: AuthenticationHook(),
    MobileSidebar: MobileSidebarHook(),
    MobileFooter: MobileFooterHook(),
    RegisterModal: RegisterModalHook(),
    LoginModal: LoginModalHook(),
    ForgetPasswordModal: ForgetPasswordModalHook(),
    CashierModal: CashierModalHook(),
    EmailVerify: EmailVerifyHook(),
    PhoneVerify: PhoneVerifyHook(),
    IdentifyVerify: IdentifyVerifyHook(),
    PromoTerms: PromoTermsHook(),
    Message: MessageHook(),
    helpers: helpers(),
    Provider: ProviderHook(),
    PromoHook: PromoHook(),
    MissionModal: MissionModalHook(),

    // Canvas Hook
    PaymentCanvas: PaymentCanvasHook(),
    TransactionCanvas: TransactionCanvasHook(),
    InboxCanvas: InboxCanvasHook(),
    SearchCanvas: SearchCanvasHook(),
    ComposeCanvas: ComposeCanvasHook(),
    Sidebar: SidebarHook(),
    MaintenanceModal: MaintenanceModalHook(),
    Launcher: LauncherHook(),
    Language: LanguageHook(),
    Campaign: CampaignHook(),
    Popup: PopupHook(),
    TopBar: TopBarHook(),
    Seo: SeoHook(),
    FloatingIcon: FloatingIconHook(),
    InactivePopup: InactivePopupHook(),
    Angpow: AngpowHook(),
    // WorldCupGiveaway: WorldCupGiveawayHook(),
    PopularGame: PopularGameHook(),
    NewGame: NewGameHook(),
    LiveChat: LiveChatHook(),
  };

  function isLogin() {
    return data.Authentication.func.isLogin();
  }

  function getRoutes() {
    return (
      // Global Path
      <>
        <Route path="/" element={<Home data={data} />} />
        <Route path="/promotions" element={<Promos data={data} forceRefresh={true} />} />
        <Route path="/contact-us" element={<Contact data={data} />} />
        <Route path="/slots" element={<GamePage data={data} current_category="slots" forceRefresh={true} />} />
        <Route path="/sportsbook" element={<GamePage data={data} current_category="sportsbook" forceRefresh={true} />} />
        <Route path="/live-dealer" element={<GamePage data={data} current_category="live_dealer" forceRefresh={true} />} />
        <Route path="/:slug" element={<Page data={data} forceRefresh={true} />} />
        {(!isLogin()) ? <></> : <><Route path="/profile" element={<Profile data={data} />} /></>}
        {(!isLogin()) ? <></> : <><Route path="/referral" element={<Referral data={data} />} /></>}
        <Route path="/rewards" element={<Reward data={data} />} />
      </>
    )
  }

  function getModals() {
    if (isLogin()) {
      return (
        <>
          <CashierModal data={data} />
          <MissionModal data={data} />
        </>
      )
    } else {
      return (
        <>
          <RegisterModal data={data} />
          <LoginModal data={data} />
          <ForgetPasswordModal data={data} />
        </>
      )
    }
  }

  function getApp() {
    return (
      <>
        <div className={data.isMobile ? "wrapper" : data.Sidebar.status ? "wrapper-desktop" : "wrapper-desktop sidebar-close"}>
          <Sidebar data={data} />
          <div className="body">
            <TopBar data={data} />
            <Header data={data} />
            <Routes>{getRoutes()}</Routes>
            <Footer data={data} />
            <MobileFooter data={data} />
            <MobileSidebar data={data} />
          </div>

          {!isLogin()
            ? <></> :
            <>
              <TransactionCanvas data={data} />
              <PaymentCanvas data={data} />
              <PromoTerms data={data} />
              <EmailVerify data={data} />
              <PhoneVerify data={data} />
              <IdentifyVerify data={data} />
              <MessageCanvas data={data} />
              <InboxCanvas data={data} />
              <ComposeCanvas data={data} />
              <InactivePopup data={data} />
              <Launcher data={data} />
              <LauncherLoading data={data} />
              {/* <WorldCupGiveaway data={data} /> */}
            </>
          }

          <SearchCanvas data={data} />
          <MaintenanceModal data={data} />
          <LiveChat data={data} />
          {getModals()}
          <Popup data={data} />
          <Angpow data={data} />
          <FloatingIcon data={data} />
          <ScrollToTop />
        </div>
      </>);
  }

  return (
    <>
      {data.Authentication.loading ? <Loading /> : getApp()}
    </>
  );
}

export default App;
