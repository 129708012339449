import { useState } from "react";

export default function TransactionCanvasHook() {
    const [transactionCanvas, setTransactionCanvas] = useState(false);
    const [data, setData] = useState();
    const open = () => { setTransactionCanvas(true) };
    const close = () => setTransactionCanvas(false);

    return {
        status: transactionCanvas,
        data: data,
        setData: setData,
        func: {
            open,
            close
        },
    };
}