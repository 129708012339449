import React, { useState, useEffect } from "react";
import { Modal, Container, Card, Row, Col, Button, Form, ButtonGroup } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import SimpleReactValidator from "simple-react-validator";
import Select from "react-select";
import { useFilePicker } from 'use-file-picker';
import Clipboard from 'react-clipboard.js';
import { WalletService } from "../../../Models/User/WalletService";
import amount_icon from '../../../Assets/Cashier/amount.png';
import bonus_icon from '../../../Assets/Cashier/bonus.png';
import bank_icon from '../../../Assets/Cashier/bank.png';
import copy_icon from '../../../Assets/copy-icon.png';
import Loading from "../../Loading/Loading";
import "./Deposit.css";
import { Dropdown } from "../../Inputs/Dropdown/Dropdown";
import Keypad from "../../Inputs/Keypad/Keypad";
import { deposit_event } from "../../../Models/Pixel/Pixel";

export const Deposit = (props) => {
    const { isMobile } = props.data;
    const CashierModal = props.data.CashierModal;
    const PromoTerms = props.data.PromoTerms;
    const helpers = props.data.helpers;
    const toast = props.data.toast;
    const user = props.user;
    const { t } = useTranslation();
    const [isInit, setIsInit] = useState(false);

    const [isSubmit, setIsSubmit] = useState(false);
    const [gateway, setGateway] = useState("online_banking");

    const userWallets = user.user_wallets.map(wallet => {
        return {
            name: t(helpers.getWalletType(wallet.type)) + " (" + user.currency + " " + helpers.number_format(wallet.withdrawable_balance, 2) + ")",
            image: "/assets/" + helpers.getWalletType(wallet.type) + ".png",
            value: wallet.id,
            label: t(helpers.getWalletType(wallet.type)),
            custom: user.currency + " " + helpers.number_format(wallet.withdrawable_balance, 2)
        };
    });

    const [payments, setPayments] = useState(null);
    const [banks, setBanks] = useState([]);
    const [bankDetail, setBankDetail] = useState(null);
    const [isCrypto, setIsCrypto] = useState(false);

    const [LBT_banks, setLBTBanks] = useState([]);
    const [LBT_types, setLBTTypes] = useState([]);

    const [promotions, setPromotions] = useState([]);
    const [isPromoCode, setIsPromoCode] = useState(false);
    const [promotionData, setPromotionData] = useState(null);
    const [promotionsLoading, setPromotionsLoading] = useState(false);
    const [minDeposit, setMinDeposit] = useState(30);

    const [openFileSelector, { filesContent, clear }] = useFilePicker({
        multiple: false,
        accept: '.jpg,.jpeg,.png',
        limitFilesConfig: { min: 1, max: 1 },
        readAs: 'DataURL',
        maxFileSize: 10,
    });

    const [form, setForm] = useState({
        wallet: props.depositWallet ? props.depositWallet : parseInt(localStorage.getItem("depositWallet")) ?? null,
        amount: null,
        payment: localStorage.getItem("depositPayment") ?? null,
        bonus: null,
        bonus_code: null,
        attachment: null,
        lbt_bank: localStorage.getItem("depositLBTBank") ?? null,
        lbt_type: localStorage.getItem("depositLBTType") ?? null,
    });

    const [errors, setErrors] = useState({
        wallet: null,
        amount: null,
        payment: null,
        bonus: null,
        bonus_code: null,
        attachment: null,
    });

    const updateWallet = value => {
        props.setDepositWallet(null);
        checkPromos(value);
        localStorage.setItem("depositWallet", value);
        setForm({ ...form, bonus: null, bonus_code: null, "wallet": value });
        return true;
    }

    const handleInputs = name => event => {
        if (name === "amount") {
            setForm({ ...form, "amount": event.target.value });
            return true;
        }

        if (name === "payment") {
            banks.map(bank => {
                if (parseInt(bank.value) === parseInt(event.value)) {
                    setBankDetail(bank);
                }
                return true;
            })
            localStorage.setItem("depositPayment", event.value);
            setForm({ ...form, "payment": event.value });
            return true;
        }

        if (name === "bonus") {
            setPromotionData(null);
            promotions.map(promotion => {
                if (parseInt(promotion.id) === parseInt(event.value)) {
                    setPromotionData(promotion);
                }
                return true;
            })
            setForm({ ...form, "bonus": event.value });
            return true;
        }

        if (name === "gateway") {
            setBankDetail(null);
            setForm({ ...form, [name]: event.target.value });
            return true;
        }

        if (name === "lbt_bank") {
            localStorage.setItem("depositLBTBank", event.value);
            setForm({ ...form, [name]: event.value });
            return true;
        };

        if (name === "lbt_type") {
            localStorage.setItem("depositLBTType", event.value);
            setForm({ ...form, [name]: event.value });
            return true;
        }

        setForm({ ...form, [name]: event.target.value });
    };


    const checkPayments = () => {
        setIsInit(false);
        WalletService.deposit_payments({}).then(response => {
            setIsInit(true);
            if (response && response.status) {
                setPayments(response.data);
            }
        });
    }

    const showBanks = () => {
        setBankDetail(null);

        var banks = [];
        var lbt_banks = [];

        if (payments !== null) {
            if (gateway === "online_banking") {
                Object.values(payments).map((v, i) => {
                    if ([1, 2, 3, 4].includes(v.category)) {
                        if (!lbt_banks.some((lbt_bank) => {
                            return lbt_bank.label === v.name;
                        })) {
                            lbt_banks.push({
                                value: v.name,
                                label: v.name,
                                image: v.image
                            });
                        }
                        banks.push(v);
                    }

                    return true;
                });
            }

            if (gateway === "local_bank_transfer") {
                Object.values(payments).map((v, i) => {
                    if ([5].includes(v.category)) {
                        banks.push(v);
                    }

                    return true;
                });
            }

            if (gateway === "e-wallet") {
                Object.values(payments).map((v, i) => {
                    if ([6, 7, 8, 9].includes(v.category)) {
                        if (!lbt_banks.some((lbt_bank) => {
                            return lbt_bank.label === v.name;
                        })) {
                            lbt_banks.push({
                                value: v.name,
                                label: v.name,
                                image: v.image
                            });
                        }
                        banks.push(v);
                    }

                    return true;
                });
            }

        }

        setLBTBanks(lbt_banks);
        setBanks(banks.map(bank => {
            return {
                name: bank.name,
                value: bank.id,
                label: bank.name,
                image: bank.image,
                type: bank.type,
                identify: bank.identify,
                account: bank.account,
                isCrypto: bank.isCrypto
            }
        }));
    }

    const checkPromos = (value) => {
        if (value === "" || value === null || isNaN(value) || !value) {
            setPromotions([]);
            return true;
        }
        setPromotionsLoading(true);
        WalletService.wallet_promotions({ wallet: value }).then(response => {
            if (response && response.status) {
                setPromotions(response.data);
                response.data.map((v, i) => {
                    if (v.selected) {
                        setForm({ ...form, bonus: v.id });
                    }
                    return true;
                });
            } else {
                setPromotions([]);
            }
            setPromotionsLoading(false);
        });
    }

    const checkBonusCode = () => {
        if (form.wallet === null || form.wallet === "") {
            toast.error(t("wallet.required"));
            return;
        }
        if (form.bonus_code === null || form.bonus_code === "") {
            toast.error(t("bonus_code.required"));
            return;
        }
        setPromotionsLoading(true);
        WalletService.bonus_code({ wallet: form.wallet, bonus_code: form.bonus_code }).then(response => {
            if (response.status) {
                setIsPromoCode(true);
                setPromotions([response.data]);
                setPromotionData(response.data);
                setForm({ ...form, bonus: response.data.id });
            }
            setPromotionsLoading(false);
        });
    }

    const openTerms = () => {
        PromoTerms.setData(promotionData);
        PromoTerms.func.open();
    }

    const removeBonusCode = () => {
        setIsPromoCode(false);
        setPromotionData(null);
        setForm({ ...form, bonus: null });
        setForm({ ...form, bonus_code: null });
        checkPromos(form.wallet);
    }

    useEffect(() => {
        if (filesContent[0]) {
            setForm({
                ...form,
                attachment: filesContent[0].content,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesContent])

    useEffect(() => {
        setIsCrypto(false);

        banks.map(bank => {
            if (bank.name === form.lbt_bank) {
                setIsCrypto(bank.isCrypto);
            }
            return false;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.lbt_bank]);

    useEffect(() => {
        checkPayments();
        checkPromos(form.wallet);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        showBanks();
        clear();

        setForm({ ...form });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payments, gateway]);

    useEffect(() => {
        setForm({ ...form, payment: null, lbt_bank: null, lbt_type: null });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gateway]);

    useEffect(() => {
        if (form.payment === null) {
            banks.map((v, i) => {
                var hasPayment = false;

                if (parseInt(v.id) === parseInt(localStorage.getItem("depositPayment"))) {
                    hasPayment = true;
                }

                if (hasPayment) {
                    setForm({ ...form, payment: localStorage.getItem("depositPayment") });
                    setBankDetail(v);
                }

                return hasPayment;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        const validator = new SimpleReactValidator({
            element: false,
        });

        validator.message('amount', form.amount, 'required|numeric|min:' + parseInt(minDeposit) + ',num|max:' + user.max_deposit + ',num', {
            messages: {
                required: t('amount.required'),
                numeric: t('amount.numeric'),
                min: t('amount.min'),
                max: t('amount.max')
            },
        });

        const validate = () => {
            return new Promise(async function (resolve, reject) {
                var $errors = {};
                for (const [key, value] of Object.entries(validator.fields)) {
                    if (!value) {
                        $errors[key] = validator.errorMessages[key];
                    } else {
                        $errors[key] = null;
                    }
                }
                return resolve($errors);
            });
        }

        validate().then(errors => setErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        if (document.getElementById("deposit_wallet")) {
            document.getElementById("deposit_wallet").classList.remove('error');
        }
    }, [form.wallet]);

    useEffect(() => {
        if (document.getElementById("deposit_wallet")) {
            document.getElementById("deposit_wallet").classList.remove('error');
        }
    }, [form.payment]);

    useEffect(() => {
        if (form.lbt_bank && payments) {
            var lbt_types = [];
            Object.values(payments).map((v, i) => {
                if (
                    [1, 2, 3, 4, 6, 7, 8, 9].includes(v.category)
                ) {
                    if (form.lbt_bank === v.name) {
                        lbt_types.push({
                            name: v.name + " (" + t(helpers.getPaymentCategory(v.category)) + ")",
                            value: helpers.getPaymentCategory(v.category),
                            image: "/assets/payments/" + helpers.getPaymentCategory(v.category) + ".svg"
                        });
                    }
                    banks.push(v);
                }
                return true;
            });

            setLBTTypes(lbt_types);
            if (lbt_types.length === 1) {
                localStorage.setItem("depositLBTType", lbt_types[0].value);
                setForm({ ...form, lbt_type: lbt_types[0].value });
            }
        }

        if (payments) {
            if (form.lbt_bank && form.lbt_type) {
                Object.values(payments).map((v, i) => {
                    if (form.lbt_bank === v.name && helpers.getPaymentCategory(v.category) === form.lbt_type) {
                        setForm({ ...form, lbt_bank: v.name, lbt_type: helpers.getPaymentCategory(v.category), payment: v.id });
                    }
                    return true;
                });
            } else if (localStorage.getItem('depositLBTBank') && !localStorage.getItem('depositLBTType') && !form.payment) {
                Object.values(payments).map((v, i) => {
                    if (gateway === "online_banking") {
                        if ([1, 2, 3, 4].includes(v.category)) {
                            if (localStorage.getItem('depositLBTBank') === v.name && helpers.getPaymentCategory(v.category) === "FPX") {
                                setForm({ ...form, lbt_bank: v.name, lbt_type: helpers.getPaymentCategory(v.category), payment: v.id });
                            }
                        }
                    }
                    return true;
                });
            } else if (localStorage.getItem('depositLBTBank') && localStorage.getItem('depositLBTType') && !form.payment) {
                Object.values(payments).map((v, i) => {
                    if (gateway === "online_banking") {
                        if ([1, 2, 3, 4].includes(v.category)) {
                            if (localStorage.getItem('depositLBTBank') === v.name && helpers.getPaymentCategory(v.category) === "FPX") {
                                setForm({ ...form, lbt_bank: v.name, lbt_type: helpers.getPaymentCategory(v.category), payment: v.id });
                            }
                        }
                    }
                    if (gateway === "local_bank_transfer") {
                        if ([5].includes(v.category)) {
                            if (localStorage.getItem('depositLBTBank') === v.name && helpers.getPaymentCategory(v.category) === localStorage.getItem('depositLBTType')) {
                                setForm({ ...form, lbt_bank: v.name, lbt_type: helpers.getPaymentCategory(v.category), payment: v.id });
                            }
                        }
                    }
                    if (gateway === "e-wallet") {
                        if ([6, 7, 8, 9].includes(v.category)) {
                            if (localStorage.getItem('depositLBTBank') === v.name && helpers.getPaymentCategory(v.category) === localStorage.getItem('depositLBTType')) {
                                setForm({ ...form, lbt_bank: v.name, lbt_type: helpers.getPaymentCategory(v.category), payment: v.id });
                            }
                        }
                    }
                    return true;
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payments, form.lbt_type, form.lbt_bank]);

    const deposit = async (e) => {
        e.preventDefault();

        if (!form.wallet) {
            toast.error(t("wallet.required"));
            return false;
        }

        if (!form.payment) {
            toast.error(t("payment.required"));
            return false;
        }

        if (gateway === "instant_transfer") {
            if (!form.attachment) {
                toast.error(t("attachment.required"));
                return false;
            }
        }
        setIsSubmit(true);

        await WalletService.deposit(form).then((response) => {
            if (response.status) {
                deposit_event(user.username, response.transaction_id, form.amount);
                if (window.fbq) {
                    window.fbq('track', 'AddToCart', {
                        value: form.amount,
                        currency: 'MYR',
                    });
                }

                if (window.dataLayer) {
                    window.dataLayer.push({
                        event: 'add_to_cart',
                        eventProps: {
                            value: form.amount,
                            currency: 'MYR',
                            products: [{
                                'name': t(helpers.getWalletType(userWallets.filter(function (option) { return parseInt(option.value) === parseInt(form.wallet); })[0].name)),
                                'quantity': form.amount,
                            }]
                        }
                    });
                }

                setForm({ ...form, amount: null, bonus: null, bonus_code: null, attachment: null });

                if (gateway === "local_bank_transfer") {
                    toast.success(t(response.message));
                } else {
                    if (response.redirect) {
                        window.location.href = response.redirect;
                    } else if (response.iframe) {
                        CashierModal.func.close();
                        props.data.Launcher.func.launch(response.iframe, false, false);
                    }
                }
            }
            setIsSubmit(false);
        }).catch((res) => {
            setIsSubmit(false);
        })

        return false;
    }

    const setAmount = (value) => {
        setForm({ ...form, amount: value });
    }

    useEffect(() => {
        // Set Min Deposit
        var value = 0;
        if (user.min_deposit) {
            value = user.min_deposit;
        }
        if (promotionData && parseInt(promotionData.min_deposit) > value) {
            value = parseInt(promotionData.min_deposit);
        }
        if (form.lbt_type && form.lbt_type === "FPX" && value < 100) {
            value = 100;
        }
        if (form.lbt_type && form.lbt_type === "Eeziepay" && value < 100) {
            value = 100;
        }
        setMinDeposit(value);
    }, [user, form.lbt_type, promotionData]);

    if (!isInit || !payments) {
        return <Loading />
    }

    return <>
        <Modal.Header>
            <button type="button" className="btn-back" aria-label="Back" onClick={CashierModal.func.wallet}></button>
            <Container className="text-center">
                <span className="title">{t('deposit.title')}</span>
            </Container>
            <button type="button" className="btn-close" aria-label="Close" onClick={CashierModal.func.close}></button>
        </Modal.Header>
        <Modal.Body className="p-0">
            <Container>
                <Row className="mb-2">
                    <Col xs="12">
                        <span className="title fs-normal">{t('deposit.sub_title')}</span>
                    </Col>
                </Row>

                <div className="d-flex-equal align-items-center gap-2 mb-3">
                    <div>
                        <Button variant="outline-secondary" size="sm" className={gateway === "online_banking" ? "gateway w-100 btn-bold fs-smaller active" : "gateway w-100 btn-bold fs-smaller"} onClick={() => setGateway("online_banking")}>{t('deposit.online_banking')}</Button>
                    </div>
                    <div>
                        <Button variant="outline-secondary" size="sm" className={gateway === "local_bank_transfer" ? "gateway w-100 btn-bold fs-smaller active" : "gateway w-100 btn-bold fs-smaller"} onClick={() => setGateway("local_bank_transfer")}>{t('deposit.local_bank_transfer')}</Button>
                    </div>
                    <div>
                        <Button variant="outline-secondary" size="sm" className={gateway === "e-wallet" ? "gateway w-100 btn-bold fs-smaller active" : "gateway w-100 btn-bold fs-smaller"} onClick={() => setGateway("e-wallet")}>{t('deposit.e-wallet')}</Button>
                    </div>
                </div>

                <Card>
                    <Card.Body>
                        <Form onSubmit={deposit} noValidate>
                            <div className="mb-3">
                                <div className="wallets-selections">
                                    {userWallets.map((v, i) => {
                                        return <div className={v.value === form.wallet ? "wallet-selection selected " : "wallet-selection "} key={i} onClick={() => updateWallet(v.value)}>
                                            {v.image ? <>
                                                <div className={v.label ? "wallet-selection-image max-width-32px" : "wallet-selection-image"}>
                                                    <img src={v.image} alt={v.image} />
                                                </div>
                                            </> : <></>}
                                            {v.label ? <>
                                                <div className="wallet-selection-label">
                                                    {v.label}
                                                </div>
                                            </> : <></>}
                                            {v.custom ? <>
                                                <div className="wallet-selection-custom">
                                                    {v.custom}
                                                </div>
                                            </> : <></>}
                                        </div>
                                    })}
                                </div>
                            </div>

                            <div className="select-payments mb-3">
                                {gateway === "online_banking" || gateway === "e-wallet" ? <>
                                    {!form.lbt_bank ? <>
                                        <Dropdown
                                            key={1}
                                            options={LBT_banks}
                                            value={form.lbt_bank}
                                            onChange={handleInputs("lbt_bank")}
                                            direction={"dropdown-rows"}
                                            onDefault={localStorage.getItem('depositLBTBank')} />
                                    </> : <>
                                        {!form.lbt_type ? <>
                                            <Dropdown
                                                key={2}
                                                options={LBT_types}
                                                value={form.lbt_type}
                                                onChange={handleInputs("lbt_type")}
                                                onReset={() => {
                                                    setForm({ ...form, payment: null, lbt_bank: null, lbt_type: null });
                                                    localStorage.removeItem("depositLBTType");
                                                    localStorage.removeItem("depositLBTBank");
                                                }}
                                                direction={"dropdown-rows"}
                                                status={true} />
                                        </> : <>
                                            <Dropdown
                                                key={3}
                                                options={LBT_types}
                                                value={form.lbt_type}
                                                onChange={handleInputs("lbt_type")}
                                                onReset={() => {
                                                    setForm({ ...form, payment: null, lbt_bank: null, lbt_type: null });
                                                    localStorage.removeItem("depositLBTType");
                                                    localStorage.removeItem("depositLBTBank");
                                                }}
                                                direction={"dropdown-rows"} />
                                        </>}
                                    </>}

                                </> : <>
                                    <Dropdown
                                        options={banks}
                                        value={form.payment}
                                        onChange={handleInputs("payment")}
                                        direction={"dropdown-rows"} />
                                </>}

                            </div>

                            <div className="input-amount mb-3">
                                <div className="mb-2">
                                    <div className="label mb-2">
                                        <img className="icon cashier-icon" src={amount_icon} alt="bank" />
                                        <span className="ms-2 fs-small font-bold text-grey">{t('deposit.amount')}</span>
                                    </div>

                                    <div className="label mb-2">
                                        <span className="fs-small font-bold text-grey">{t('deposit.quick_select')}</span>
                                    </div>
                                    <div className="block">

                                        <ButtonGroup size="sm" className="mb-2 w-100">
                                            {
                                                user.quick_select.map((quick_select, i) => {
                                                    return <Button key={i} className={form.amount === quick_select ? "rounded-0 fs-small px-0 active" : "rounded-0 fs-small px-0"} variant="quick-select" onClick={() => setAmount(quick_select)}>{user.currency} {quick_select}</Button>;
                                                })
                                            }
                                        </ButtonGroup>
                                    </div>

                                    <div className="label mb-2">
                                        <span className="fs-small font-bold text-grey">{t('deposit.enter_amount')}</span>
                                    </div>
                                    <Keypad value={form.amount} error={errors.amount} onChange={handleInputs("amount")} isMobile={isMobile} />
                                    <span className="fs-smaller text-grey">{t("deposit.minimun_amount")} : {parseInt(minDeposit).toFixed(2)}</span>
                                </div>
                            </div>

                            <div className="bonus-amount mb-3">
                                <div className="mb-2">
                                    <div className="label mb-2">
                                        <img className="icon cashier-icon" src={bonus_icon} alt="bonus" />
                                        <span className="ms-2 fs-small font-bold text-grey">{t('deposit.bonus')}</span>
                                    </div>
                                    <>

                                        <Select
                                            className="fs-small font-bold text-grey"
                                            value={promotions.map(promotion => {
                                                return { value: promotion.id, label: promotion.title };
                                            }).filter(function (option) {
                                                return option.value === form.bonus;
                                            })}
                                            onChange={handleInputs("bonus")}
                                            options={promotions.map(promotion => {
                                                return { value: promotion.id, label: promotion.title };
                                            })}
                                            disabled={isPromoCode || promotionsLoading}
                                            placeholder={t('deposit.choose_your_bonus')}
                                            styles={helpers.reactSelectStylesPromo()}
                                            isSearchable={false}
                                        >
                                        </Select>
                                    </>
                                    {!promotionData ? <></> : <div className="bonus_terms" onClick={openTerms}>{t('deposit.terms')}</div>}

                                    <div className="label mb-2">
                                        <span className="fs-small font-bold text-grey">{t('deposit.bonus_code')}</span>
                                    </div>
                                    <Row>
                                        <Col className="pe-0" xs="7">
                                            <Form.Control
                                                type="text"
                                                className="form-control fs-small font-bold text-grey"
                                                value={form.bonus_code === null ? '' : form.bonus_code}
                                                placeholder=" "
                                                autoComplete="none"
                                                onChange={handleInputs("bonus_code")}
                                            />
                                        </Col>
                                        <Col xs="5">
                                            {isPromoCode
                                                ? <Button className="w-100" size="sm" onClick={removeBonusCode}>{t('deposit.remove')}</Button>
                                                : <Button className="w-100" size="sm" onClick={checkBonusCode}>{t('deposit.redeem')}</Button>}

                                        </Col>
                                    </Row>

                                </div>
                            </div>

                            {bankDetail && gateway === "local_bank_transfer" ?
                                <div className="bonus-amount mb-3">
                                    <div className="mb-2">
                                        <div className="label mb-2">
                                            <img className="icon cashier-icon" src={bank_icon} alt="bank" />
                                            <span className="ms-2 fs-small font-bold text-grey">{t('deposit.attachment')}</span>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="mb-2">
                                            <div className="w-100 d-flex align-items-center justify-content-between">
                                                <div className="">
                                                    <span className="fs-small font-bold d-block">{t('deposit.bd88_account_name')}</span>
                                                    <span className="fs-small font-light d-block">{bankDetail.identify}</span>
                                                </div>
                                                <div className="">
                                                    <Clipboard
                                                        className="btn py-0 px-0 copy fs-small d-flex align-items-center"
                                                        data-clipboard-text={bankDetail.identify}
                                                        onSuccess={() => toast.success("Copied!")}
                                                    >
                                                        <img className="copy-icon" src={copy_icon} alt="copy" />
                                                        <span>{t('deposit.copy')}</span>
                                                    </Clipboard>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <div className="w-100 d-flex align-items-center justify-content-between">
                                                <div className="">
                                                    <span className="fs-small font-bold d-block">{t('deposit.bd88_account_number')}</span>
                                                    <span className="fs-small font-light d-block">{bankDetail.account}</span>
                                                </div>
                                                <div className="">
                                                    <Clipboard
                                                        className="btn py-0 px-0 copy fs-small d-flex align-items-center"
                                                        data-clipboard-text={bankDetail.account}
                                                        onSuccess={() => toast.success("Copied!")}>
                                                        <img className="copy-icon" src={copy_icon} alt="copy" />
                                                        <span>{t('deposit.copy')}</span>
                                                    </Clipboard>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <p className="fs-smallest text-grey mb-0">
                                                {t('deposit.attachment_terms')}
                                            </p>
                                        </div>
                                        <div className="d-flex align-content-center align-items-center mb-3">
                                            <div className="min-width-100px">
                                                <Button variant="default" size="sm" className="w-100 fs-smallest" onClick={() => openFileSelector()}>{t('choose_file')}</Button>
                                            </div>
                                            <div className="ms-2">
                                                <span className="fs-smallest">{filesContent[0] ? filesContent[0].name : t('upload_desc')}</span>
                                            </div>
                                        </div>
                                        <span className="fs-small font-bold d-block mb-1">{t("deposit.important_reminder")}</span>
                                        <p className="fs-smallest text-grey mb-1">{t("deposit.check_avaliable_bank_account")}</p>
                                        <p className="fs-smallest text-grey mb-1">{t("deposit.if_we_don_receive_your_deposit")}</p>
                                    </div>
                                </div>
                                : <></>}

                            {isCrypto && <>
                                <div className="bonus-amount mb-3">
                                    <span className="fs-small font-bold d-block mb-1">{t("deposit.important_reminder")}</span>
                                    <p className="fs-smallest text-grey mb-1">{t("deposit.check_payment_adresss")}</p>
                                    <p className="fs-smallest text-grey mb-1">{t("deposit.please_include_transaction_fee")}</p>
                                </div>
                            </>}

                            <div className="d-grid gap-2">
                                <Button variant="primary" type="submit" disabled={isSubmit}>
                                    {t('deposit.submit')}
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
                <div className="card bg-transparent mt-3">
                    <span className="font-bold text-center">
                        <Trans i18nKey="deposit.contact_us">
                            <span className="text-link" onClick={() => props.data.LiveChat.func.open()}></span>
                        </Trans>
                    </span>
                </div>
            </Container>
        </Modal.Body>
    </>;
}