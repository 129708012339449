import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

export const Slider = (props) => {
    const swiperRef = useRef(null);
    const helpers = props.data.helpers;
    const banners = props.banners;
    const [currentBanner, setCurrentBanner] = useState(null);

    useEffect(() => {
        if (banners && banners.length > 0) {
            setCurrentBanner(banners[0]);
        }
    }, [banners]);

    if (!currentBanner) {
        return <></>;
    }

    return <>
        <div className="swiper">
            <Swiper
                ref={swiperRef}
                modules={[Navigation]}
                loop={true}
                slidesPerView={1}
                pagination={{ clickable: true }}
                scrollbar={{ draggable: true }}
            >
                {banners.map((banner, i) => {
                    return <SwiperSlide key={i}>
                        <Link to={banner.location} >
                            <img className="w-100 hero-banner" style={{ width: "100%", height: "100%", aspectRatio: "71/23" }} src={helpers.t(banner.image)} alt={banner.location} />
                        </Link>
                    </SwiperSlide>;
                })}

            </Swiper>
            <div className='swiper-navigations'>
                <div className='swiper-navigations-wrapper'>
                    <div className="swiper-navigation swiper-slider-prev" onClick={() => swiperRef.current.swiper.slidePrev()} >
                        <img className="carousel-icon" src={"/assets/icons/prev.png"} alt="prev" />
                    </div>
                    <div className="swiper-navigation swiper-slider-next" onClick={() => swiperRef.current.swiper.slideNext()} >
                        <img className="carousel-icon" src={"/assets/icons/next.png"} alt="next" />
                    </div>
                </div>
            </div>
        </div>
    </>;
}