import axios from "axios";
import { t } from "i18next";
import { toast } from 'react-toastify';
import jose from 'node-jose';
import i18next from 'i18next';

const privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIG5AIBAAKCAYEA1dpmdDjqlTyOhbttRyc5G5MGSt7+sUG3QKtzFTig6Un4bOEL
NcJNfUas/1DaG/ma1FXrirWGofDz7mOKrUxxlxZoF31TEc+XnVPIYxQj9L/ZafBZ
uVeorSf2HSMR7Cdk9/LFW+l+0GSTu3Q26f5u9bHg8OK6FjZHMI9Qc2C9ge7Ih5OQ
uTVv+HtMjqmEYIhdKF4pQfyTBGh6dpoCGdjlUTMBrItjn3oWjIzQiWkRG9QFWvRL
HCiX6Mzww/CsqGMERiXrNbOLMg/L/ECQGdBWmc63ryFyVTf5EbUW1F3+bh/XooQ9
v6y8W9xFa2cusoBInTXVDyfsBGMbTV6Iv2PWkv+lLNxPk3DzbBBjl0TNzDFtmZph
oyPvpjuFQP63GmpzpGxjoj6zzV5DyoYBkeXgv3rm80cLSByKVuIoSG84N7wAfSY9
ubWR8+cLOyob3+e8SoKq93oSKk7Y3WyqLqWQtrdqW1sWfzpxoe+AKjMpj0mDXV4e
i/YJxgnKz78eFksTAgMBAAECggGAWNgaNnCq7IMzBtYeCTB/ZjhHpfVMxKFzm3df
IVLcbu8fOUSpXpUxkjk5h/y0EGk7bLmhWIgfvcmX3dMW+vZGPqm9ZvzW9RBUgbUc
DCWC5pyJm5yR+hEz73QpqGM4f+7B4MzIN24jFDqksX0Pj1j4CjTzKcBX5QB5xO/V
UYnAUWm9f63Ls1ZDBW1cGezNJTmh0jKEW3iuL2ekcP98nfZhFsHlEST7p3aWRZ/+
xweSZwhHjUjup20p5zq1UAH+CRrRGcO9j//0ffiEDnA5JPi331VpFq8IEbWPe8aL
GrtXsPuKurQ5NZ0u2cXrSCCszk4poG8uTpxNo+C77qLtk8uF9d04S4/ofAivNBs9
tKiseXGvHjCvGcYDKfx3CjqoGTdDmaTLUFV7kIcI439VnFPbiO49c2H0oymznGwU
re2TRRKBON9BYvBj51z2dbU8g7WAfdKm0d1XWQnKajBOdf71WJ78G+55O9F+4AHb
CKJJOib8I4MrADddiU/NntuFBEDhAoHBAPyWcHPXWEik6gtkc34g5Hf/Cyv1Y4fG
AQso/9v/OH95Yx519CRLDkIanuqpnF68qAVJO9KHq3lEFrI1TuUtR9nBn7n/KDq+
AHhpdPuLtBhzcHs29rN5yUhsTfEVCPFtLHdJrfV8inJuNB7oW7rGXpkTibMqk+i0
bW8DrZB+e6bTAWwUtz2Foa7Le73ojLQyaBWg5hQjv2GXiHJXHiW4ppc5f+tCZnoo
ynKH1TG4i2Ul5NCQ5s7tEl4UriFLFnJzIwKBwQDYvf/qvK/LqwtliC4ff9LwY67X
4wp2Bl2YzUJU5TSFQ/x2mfO6B6Dtn6Qrr8AqC76ehGJsL+jV+tRvF9kpD5sEsfE+
soD6+7/0d0XdIdecf3Ko4O2UI+vdUb2I5Pd0CUIqaiGE/1qAa2rNmn86S6dzklbV
BejgskM6mDRjZC2C6XuF6tnHh/Jg39Gx5M1b4t9XN97aSmFasAESRXvg7ta9OVPb
1VrvOGJy3EuUDtk1L1w4d+Uuc/8jTp+yuHsQ/1ECgcEAs6ENIeTAfREC/M0YMVeV
Ax1WxZFCkhknPteOddbgSA98hPofgPv7jNk/sOSkNt91oiS9rXAcayYX+e5dSDBW
i67eN7kU1AmxbfCZaBanIkhectHYyjRPB/k1yMBL1t3sJH06gH1sPArReDWInqBa
QAScBdW+0FwYAz4bXcoFh9NBjKqC0fbJz/QX8SuDfNboHJGLbkEp5m6Mc7wuzWau
2pbiuN1E4ZVGvAKLhEuYnns/aW/TJM+GI3ATkcvDj+r/AoHBAJVpVRQCgsVJ4g9Z
80GvokAo14znbHy0Iq2FQea4Ip6DoXQ46LUjMSLd3BRpkXTqp7sq05Z06HMp+Guk
6wTNRzXTXNS1xQg/p7HkzvqDq9isWuRkOiLXKG9nO9s0zAjnoP8QLo86YK8KXZPi
nzYWlRasmMM80a6j9IheCC9EDcOjJI3Kudo8sy9o22tgqNhg60l6sFuWzsnvbq2j
zKPgwZOsA00WJRunqOY5SriEC+QIA/DBZfHB8O3r81cGqX+sIQKBwEC+Qw8wygy0
wDBxsmHf5QaL2BVHyYyhhe4pl0/slorJYc1qqMf3GsT3SQEXWAWAjaTvk3YJ1OhX
cA7soI7YKJP7mKtVdC2ub2lbDxIMMSsUTgRC5eI3w97XJGXVYYdayfy174k73vJm
bAXnIbMbkRwHsVym41HYHEc7vgrDXnJnd4m3P1yKH4c8NV8d8i7twsYJsrxnl2SW
FEnF9A+omj/qVo7v8PS4KUSkN9IE+kFUL4VFjUacvEp1QVgqOdwREw==
-----END RSA PRIVATE KEY-----`;

const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEAq3wwboL9gae+RXxvkEuI
u9YsffbmToSTV+shgK/unq98/E22tCd3QHjKtalY1GzSNkgeCj/2CMT2yEme0FVm
THPeP1s9sbf2XCXXZUyXAXClDFRgcyEXLYPaeyHUKjPPGfut2rEaWbZoyPV6NGa4
o50UPjZZfrKJCtsZul9oK11nQ4Sj5XVD1/RHEoy58uz8KoNn3pVhy3Da1lYoEQWS
L39OH09ljL8vP7Gy2x/Ln1ta17AUBVwLigCZY9JkIB0RO9ZvNdAGr5hEv7bZOBRo
NdXBEDVQb+dZmuOWyh0fI62DCBK1KG7gF+cb7nEpl4le2Dm9gUl+uog0XILZX8YS
+eW78dlYLyi6JrRKn4KmiU7pLks/W+yhg0NRUXb3hOGhfXopzXOijaSd/jDaQtrb
fP+yXTVDJXPZh66+2hm7hkqxTPZB6+yhJ0FljdtAXjya3p3bCp8Xh+d7fjofMP69
nkn3VCjIZOtp3UB7YRE2RhTigsmZkWc9lJ+LIOL/vSwpAgMBAAE=
-----END PUBLIC KEY-----`;

export const request = async (url, params, headers) => {
    params = { ...params, locale: i18next.language };

    if (sessionStorage.getItem("referral")) {
        params = { ...params, referral: sessionStorage.getItem("referral") };
    }

    if (localStorage.getItem("extra")) {
        params = { ...params, extra: localStorage.getItem("extra") };
    }

    let keystone = await jose.JWK.asKey(privateKey, "pem");
    var payload = jsonEscapeUTF(JSON.stringify(params));
    return jose.JWS.createSign({ format: 'compact' }, keystone).update(payload).final().then(async function (payload) {
        return axios.post(url, { payload: payload }, headers).then(decodeResponse).then(handleResponse).catch(error => {
            if (error === "auth.not-logined") {
                window.location = "/?modal=loginModal";
            } else {
                toast.error(t(error));
            }
        });
    });
}
function jsonEscapeUTF(s) { return s.replace(/[^\x20-\x7F]/g, x => "\\u" + ("000" + x.codePointAt(0).toString(16)).slice(-4)) }

export const requestWithoutStatusError = async (url, params, headers) => {
    params = { ...params, locale: i18next.language };

    if (sessionStorage.getItem("referral")) {
        params = { ...params, referral: sessionStorage.getItem("referral") };
    }

    if (localStorage.getItem("extra")) {
        params = { ...params, extra: localStorage.getItem("extra") };
    }

    let keystone = await jose.JWK.asKey(privateKey, "pem");

    var payload = jsonEscapeUTF(JSON.stringify(params));
    return jose.JWS.createSign({ format: 'compact' }, keystone).update(payload).final().then(async function (payload) {
        return axios.post(url, { payload: payload }, headers).then(decodeResponse).catch(error => {
            toast.error(t(error));
        });
    });
}
export const decodeResponse = async (response) => {
    let keystone = await jose.JWK.asKey(publicKey, "pem");
    let verify = await jose.JWS.createVerify(keystone).verify(response.data).catch(() => {
        return false
    });

    const components = response.data.split('.');
    if (verify) {
        return JSON.parse(decodeBase64(components[1]));
    }
    return { "status": false, "data": [] };
}

export const encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
}
export const decodeBase64 = (data) => {
    return Buffer.from(data, 'base64').toString('ascii');
}

export const handleResponse = (response) => {
    return response;
}