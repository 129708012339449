import React from 'react';
import { Button, Container, Navbar, Nav } from 'react-bootstrap';
import './Header.css';
import logo from '../../Assets/logo.png';
import logo_cn from '../../Assets/logo_cn.png';
import notification from '../../Assets/notification.png';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import search_icon from '../../Assets/Header/search.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ProfileInfo } from '../ProfileInfo/ProfileInfo';
import i18n from "../../Scripts/i18n";

function Header(props) {
  const provider = props.data.Provider;
  const { t } = useTranslation();

  const registerModal = () => {
    props.data.RegisterModal.func.open();
  }
  const loginModal = () => {
    props.data.LoginModal.func.open();
  }
  const depositModal = () => {
    props.data.CashierModal.func.deposit();
  }
  const transferModal = () => {
    props.data.CashierModal.func.transfer();
  }

  const openMobileSidebar = () => {
    props.data.MobileSidebar.func.open();
  }

  function getHeaders() {
    if (props.data.Authentication.func.isLogin()) {
      return (
        <>
          <Nav.Item>
            <Button variant="outline-secondary" size="sm" className="btn-bolder px-3 d-none d-md-block" onClick={transferModal}>{t('header.transfer')}</Button>
          </Nav.Item>
          <Nav.Item>
            <Button variant="primary" size="sm" className="btn-highlight btn-bolder px-3" onClick={depositModal}>{t('header.deposit')}</Button>
          </Nav.Item>
        </>
      )
    } else {
      return (
        <>
          <Nav.Item className="ms-auto">
            <Button variant="outline-secondary" size="sm" className="btn-bolder px-3" onClick={loginModal}>{t('header.login')}</Button>
          </Nav.Item>
          <Nav.Item className="">
            <Button variant="primary" size="sm" className="btn-highlight btn-bold px-3" onClick={registerModal}>{t('header.sign_up')}</Button>
          </Nav.Item>
        </>
      )
    }
  }

  const getAuth = () => {
    return props.data.Authentication;
  }

  const isAuth = () => {
    return getAuth().func.isLogin();
  }

  function getMenu() {
    return isAuth() ?
      <div className="menu">
        <Container className='d-inline-flex'>
          <div className={provider.status === "slots" ? "menu-item active" : "menu-item"}>
            <Link className="links" to="/slots"><span>{t('slots')}</span></Link>
          </div>
          <div className={provider.status === "live-dealer" ? "menu-item active" : "menu-item"}>
            <Link className="links" to="/live-dealer"><span>{t('live_dealer')}</span></Link>
          </div>
          <div className={provider.status === "sportsbook" ? "menu-item active" : "menu-item"}>
            <Link className="links" to="/sportsbook"><span>{t('sportsbook')}</span></Link>
          </div>
          <div className={provider.status === "promos" ? "menu-item active" : "menu-item"}>
            <Link className="links" to="/promotions"><span>{t('promos')}</span></Link>
          </div>
          <div className="menu-item" onClick={props.data.SearchCanvas.func.open}>
            <img src={search_icon} height="20px" alt="Notificaiton" />
          </div>
        </Container>
      </div>
      : "";
  }

  function getNotification() {
    if (getAuth().func.isLogin()) {
      return (
        <div className="notification" onClick={props.data.Message.func.open}>
          <img src={notification} height="25px" alt="Notificaiton" />

          {
            (getAuth().user != null && (getAuth().user.notification_count + getAuth().user.inbox_count) > 0) ?
              <div className="count">
                <span>{getAuth().user.notification_count + getAuth().user.inbox_count}</span>
              </div>
              : ""
          }

        </div>
      );
    }
  }

  function getSearch() {
    return <>
      <div className="header-search" data-search="" onClick={props.data.SearchCanvas.func.open}>
        <div className="input-group input-group-search d-none d-lg-flex">
          <input className="form-control" type="search" placeholder={t("search.placeholder")} readOnly={true} />
          <span className="input-group-prepend">
            <div className="input-group-text border-0 bg-transparent">
              <FontAwesomeIcon icon={solid('magnifying-glass')} />
            </div>
          </span>
        </div>
      </div>
    </>

  }
  return (
    <header>
      <Navbar bg="light" expand={false}>
        <Container className="info my-lg-3 px-lg-4">
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            className="d-lg-none"
            onClick={openMobileSidebar} />

          <Navbar.Brand as={Link} to="/" className="header-logo d-block px-md-3 d-lg-none me-auto">
            <img src={i18n.language === "en" ? logo : logo_cn} height="32" alt="BD88 Logo" />
          </Navbar.Brand>

          {getSearch()}
          <ProfileInfo data={props.data} />
          {getNotification()}
          {getHeaders()}

        </Container>

        {getMenu()}
      </Navbar>
    </header>
  );
}

export default Header;
