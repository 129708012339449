import * as qs from 'query-string';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RegisterModalHook() {
    const navigate = useNavigate();
    const [registerModal, setRegisterModal] = useState(false);
    const open = () => { setRegisterModal(true); };
    const close = () => {
        const parsed_query = qs.parse(window.location.search);
        if (parsed_query.modal === "registerModal") {
            navigate('/');
        }
        setRegisterModal(false);
    };

    useEffect(() => {
        const parsed_query = qs.parse(window.location.search);
        if (parsed_query.modal === "registerModal") {
            open();
        }
    });

    return {
        status: registerModal,
        func: {
            open,
            close
        },
    };
}