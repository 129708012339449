import React, { useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer(props) {
  const { t } = useTranslation();

  const [curacao, setCuracao] = useState(false);

  const openCuracao = () => {
    setCuracao(true)
  }

  const closeCuracao = () => {
    setCuracao(false)
  }

  return (
    <footer>

      <Modal
        className="CuracaoModal"
        size="lg"
        show={curacao}
        onHide={closeCuracao}
        backdrop="static"
        keyboard={false}
        animation={false}
      >
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center p-0">
          <iframe title="url" className="w-100 h-100" src={"https://api.38386868.com/curacao#"} />

          <button className="btn btn-close" onClick={closeCuracao}></button>

        </Modal.Body>
      </Modal>

      <Container>
        <Row>
          <Col xs="12" className="mb-3">
            <span className="title">{t('footer.quick_links')}</span>
            <div>
              <Link className="links" to="/privacy-policy"><span>{t('footer.privacy_policy')}</span></Link>
              <Link className="links" to="/terms-and-conditions"><span>{t('footer.term_and_conditions')}</span></Link>
              <Link className="links" to="/reward-points-points-redemption"><span>{t('footer.reward')}</span></Link>
              <Link className="links" to="/contact-us"><span>{t('footer.contact')}</span></Link>
              <a className="links" href="https://bd88partners.com/" target="_blank" rel="noreferrer" ><span>{t('footer.affiliate_program')}</span></a>
            </div>
          </Col>
          <Col xs="12" className="mb-2">
            <span className="title">{t('footer.about_bd88')}</span>
            <span className="description">{t('footer.description')}</span>
          </Col>
          <Col xs="12">
            <hr />
          </Col>
          <Col xs="12" md="6" className="mb-4">
            <span className="title">{t('footer.partners')}</span>
            <div className="d-flex flex-wrap">
              <img src="/assets/providers/ag.svg" className="bg-ag me-1 mb-1" alt="asiagaming" />
              <img src="/assets/providers/evo.svg" className="bg-evo me-1 mb-1" alt="evolution" />
              <img src="/assets/providers/gameplay.svg" className="bg-gameplay me-1 mb-1" alt="gameplay" />
              <img src="/assets/providers/habanero.svg" className="bg-habanero me-1 mb-1" alt="habanero" />
              <img src="/assets/providers/playtech.svg" className="bg-playtech me-1 mb-1" alt="playtech" />
              <img src="/assets/providers/pp.svg" className="bg-pp me-1 mb-1" alt="pragmatic play" />
              <img src="/assets/providers/sexy.svg" className="bg-sexy me-1 mb-1" alt="sexy bacarrat" />
            </div>
          </Col>
          <Col xs="12" md="6" className="mb-4">
            <span className="title">{t('footer.payment_options')}</span>
            <div className="d-flex flex-wrap">
              <img src="/assets/payments/FPX-footer.svg" className="bg-fpx me-1 mb-1" alt="fpx" />
              <img src="/assets/payments/maybank.svg" className="bg-maybank me-1 mb-1" alt="maybank" />
              <img src="/assets/payments/affin-footer.svg" className="bg-affin me-1 mb-1" alt="affin bank" />
              <img src="/assets/payments/alliance-footer.svg" className="bg-alliance me-1 mb-1" alt="alliance bank" />
              <img src="/assets/payments/ambank.svg" className="bg-ambank me-1 mb-1" alt="ambank" />
              <img src="/assets/payments/bsn-footer.svg" className="bg-bsn me-1 mb-1" alt="bsn" />
              <img src="/assets/payments/cimb.svg" className="bg-cimb me-1 mb-1" alt="cimb bank" />
              <img src="/assets/payments/citi-footer.svg" className="bg-citi me-1 mb-1" alt="citi bank" />
              <img src="/assets/payments/pbb.svg" className="bg-pbb me-1 mb-1" alt="public bank" />
              <img src="/assets/payments/rhb.svg" className="bg-rhb me-1 mb-1" alt="rhb bank" />
              <img src="/assets/payments/hsbc-footer.svg" className="bg-hsbc me-1 mb-1" alt="hsbc bank" />
              <img src="/assets/payments/ocbc-footer.svg" className="bg-ocbc me-1 mb-1" alt="ocbc bank" />
              <img src="/assets/payments/scb-footer.svg" className="bg-scb me-1 mb-1" alt="scb bank" />
              <img src="/assets/payments/uob-footer.svg" className="bg-uob me-1 mb-1" alt="uob bank" />
              <img src="/assets/payments/bank-rakyat.svg" className="bg-bank_rakyat me-1 mb-1" alt="bank rakyat" />
              <img src="/assets/payments/bank-islam.svg" className="bg-bank_islam me-1 mb-1" alt="bank islam" />
              <img src="/assets/payments/duit-now.svg" className="bg-duit_now me-1 mb-1" alt="duit now" />
              <img src="/assets/payments/duit-now-qr.svg" className="bg-duit_now_qr me-1 mb-1" alt="duit now qr" />
              <img src="/assets/payments/tng.svg" className="bg-tng me-1 mb-1" alt="touch and go" />
              <img src="/assets/payments/boost-footer.svg" className="bg-tng me-1 mb-1" alt="boost" />
              <img src="/assets/payments/qr-footer.svg" className="bg-tng me-1 mb-1" alt="qr pay" />
            </div>
          </Col>
          <Col xs="12" md="6" className="mb-4">
            <span className="title">{t('footer.gaming_license')}</span>
            <div className="cursor-pointer" onClick={openCuracao}>
              <img src="/assets/curacao.svg" className="bg-curacao me-1 mb-1 w-100" alt="curacao" />
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
