import { request, requestWithoutStatusError } from '../Core.js';

export const WalletService = {
    transfer,
    withdrawal,
    withdrawal_bank,
    deposit,
    wallet_promotions,
    bonus_code,
    deposit_payments,
    redeem_rebate,
    gamelogs,
};

function deposit(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/wallet/deposit', params, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }));
    })

}

function deposit_payments(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/wallet/deposit/deposit_payments', params));
    })
}

function wallet_promotions(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/wallet/deposit/wallet_promotions', params));
    })
}

function bonus_code(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/wallet/deposit/bonus_code', params));
    })
}

function transfer(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/wallet/transfer', params));
    })
}

function withdrawal(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(requestWithoutStatusError('/wallet/withdrawal', params));
    })
}

function withdrawal_bank(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/wallet/withdrawal_bank', params));
    })
}

function redeem_rebate(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/wallet/redeem_rebate', params));
    })
}

function gamelogs(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/wallet/gamelogs', params));
    })
}