import { useState } from "react";

export default function MobileSidebarHook() {
    const [phoneSidebar, setPhoneShowSidebar] = useState(false);
    const open = () => { setPhoneShowSidebar(true) };
    const close = () => setPhoneShowSidebar(false);

    return {
        status: phoneSidebar,
        func: {
            open,
            close
        },
    };
}