import { t } from "i18next";
import GameCard from "./GameCard";
import GameCardPlaceholder from "./GameCardPlaceholder";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from "react";

function PopularGames(props) {
    const games = props.data.PopularGame;
    const [swiper, setSwiper] = useState(null);
    const slidesPerView = (window.innerWidth >= 768) ? (window.innerWidth >= 1024) ? 5 : 3.85 : 2.5;

    return <div className="mb-4">
        <h5>{t('game.popular_games')}</h5>
        <Swiper
            className="position-relative"
            spaceBetween={0}
            slidesPerView={slidesPerView}
            loop
            observer
            observeParents
            parallax
            onSwiper={(s) => setSwiper(s)}
        >
            {swiper && games.length === 0 ? <>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
            </> : <>
                {games.map((element, i) => {
                    return <SwiperSlide key={i}>
                        <GameCard data={props.data} key={i} element={element} index={i} isShow={true} />
                    </SwiperSlide>
                })}
            </>}

            <div className='swiper-navigations games'>
                <div className='swiper-navigations-wrapper'>
                    <div className="swiper-navigation swiper-slider-prev" onClick={() => swiper.slidePrev()} >
                        <img className="carousel-icon" src={"/assets/icons/prev.png"} alt="prev" />
                    </div>
                    <div className="swiper-navigation swiper-slider-next" onClick={() => swiper.slideNext()} >
                        <img className="carousel-icon" src={"/assets/icons/next.png"} alt="next" />
                    </div>
                </div>
            </div>
        </Swiper>

    </div>;
}

export default PopularGames;