import { useEffect, useState } from "react";
import { Modal, Button, ProgressBar, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Referral.css";
import { ReferralService } from "../../Models/User/ReferralService";
import { t } from "i18next";
import { Helmet } from "react-helmet";
import Clipboard from 'react-clipboard.js';
import Select from "react-select";
import * as moment from 'moment'
import {
    EmailShareButton,
    FacebookMessengerShareButton,
    FacebookShareButton,
    TelegramShareButton,
    WhatsappShareButton,
} from "react-share";
import Loading from "react-loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

function Referral(props) {
    const user = props.data.Authentication.user;
    const toast = props.data.toast;
    const seo = props.data.Seo.data;
    const helpers = props.data.helpers;
    const types = [
        {
            "label": "Profit Sharing",
            "value": "Profit Sharing",
        }, {
            "label": "First Time Deposit",
            "value": "First Time Deposit",
        }
    ];

    const [showInfo, setShowInfo] = useState(false);
    const handleInfoClose = () => setShowInfo(false);
    const handleInfoOpen = () => setShowInfo(true);

    const [data, setData] = useState(null);
    const [currentLevel, setCurrentLevel] = useState(user.referral.level + 1)
    const [userWallets, setUserWallets] = useState([]);
    const [redeemWallet, setWithdrawWallet] = useState(null);
    const [redeemType, setWithdrawType] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);
    const [ratio, setRatio] = useState("9-16");
    const [report, setReport] = useState("profit_sharing");

    const isAuth = () => {
        return props.data.Authentication.func.isLogin();
    }

    const refresh = () => {
        props.data.Authentication.func.get().then((response) => {
            if (response) {
                setCurrentLevel(response.referral.level + 1);
                ReferralService.index().then((response) => {
                    setData(response.data);
                });
            }
        });
    }

    const upgrade = () => {
        ReferralService.upgrade().then((response) => {
            if (response) {
                refresh();
                if (response.status) {
                    toast.success(t(response.message));
                } else {
                    toast.error(t(response.message));
                }
            }
        });
    }

    const withdraw = (e) => {
        setIsSubmit(true);
        e.preventDefault();

        ReferralService.withdraw({ type: redeemType, wallet: redeemWallet }).then((response) => {
            setIsSubmit(false);
            if (response.status) {
                toast.success(t(response.message));
                props.data.Authentication.func.get();
                refresh();
            } else {
                toast.error(t(response.message));
            }
        }).catch((res) => {
            setIsSubmit(false);
        })
    }

    function HalfCircleChart({ min, max, value }) {
        const angle = (value >= max) ? 180 : (value / max) * 180;
        const style = { '--angle': angle + 'deg' };
        return <div className="sc-gauge">
            <div className="sc-background">
                <div className="sc-percentage" style={style}></div>
                <div className="sc-percentage-background" style={{ '--angle': 180 + 'deg' }}></div>
                <div className="sc-mask"></div>
                <div className="sc-mask-2"></div>
                <span className="sc-value">
                    <span className="sc-title">{value}</span>
                    <span className="sc-subtitle">{t('referral.total_active_players')}</span>
                    <span className="sc-description">{t('referral.total_active_players.description', { active_player: (max - value) < 0 ? 0 : max - value })}</span>
                </span>
            </div>
            <span className="sc-min">{min}</span>
            <span className="sc-max">{max}</span>
        </div>;
    }

    function LineChart({ min, max, value }) {
        return <div className="progress-container">
            <ProgressBar variant="default" now={value} max={max} min={min} >
            </ProgressBar>
            <div className="progress-text">
                <span className="progress-left-span">{t('referral.turnover_needed', { turnover: helpers.number_format(value >= max ? 0 : max - value) })}</span>
                <span className="progress-right-span">{helpers.number_format(max)}</span>
            </div>
        </div>;
    }

    const getDescription = () => {
        return <div className="description">
            <div className="description-one mt-auto mb-auto">
                <b className="">{t("referral.level", { level: currentLevel })}</b>
            </div>
            <div className="description-two">
                <b>{t("referral.what_need_to_do")}</b>
                <span className="d-block">- {t("referral.what_need_to_do.monthly_active", { monthly_active: data.referralDatas[currentLevel].monthly_active })} </span>
                <span className="d-block">- {t("referral.what_need_to_do.monthly_turnover", { monthly_turnover: helpers.number_format(data.referralDatas[currentLevel].monthly_turnover) })} </span>
            </div>
            <div className="description-three">
                <b>{t("referral.what_can_get")}</b>
                <span className="d-block">-  {t("referral.what_can_get.profit_sharing", { profit_sharing: data.referralDatas[currentLevel].profit_sharing })}</span>
                <span className="d-block">-  {t("referral.what_can_get.first_time_deposit", { first_time_deposit: data.referralDatas[currentLevel].first_time_deposit, first_time_deposit_cap: data.referralDatas[currentLevel].first_time_deposit_cap })}</span>
                {data.referralDatas[currentLevel].gift ? <span className="d-block">- {data.referralDatas[currentLevel].gift}</span> : <></>}
            </div>

            {currentLevel === data.referral_level_next.level ? <>
                <button onClick={upgrade} className="btn btn-primary d-md-none mt-2" disabled={!data.upgradeable}>
                    {t('referral.unlock_now')}
                </button>
            </> : <>
            </>}

            <a href="#invite" className="btn btn-primary d-md-none mt-2">
                {t('referral.invite_friends')}
            </a>

            <button className="btn btn-blue d-md-none mt-2" onClick={handleInfoOpen}>
                {t('referral.view_progress')}
            </button>
        </div>;
    }

    const getBox = () => {
        return <div className="box">
            <div className="box-body">
                <HalfCircleChart
                    min={0}
                    max={data.referralDatas[currentLevel].monthly_active}
                    value={data ? data.current_active_player : 0}
                />

                <span className="referral-plus">+</span>
                <LineChart min={0} max={data.referralDatas[currentLevel].monthly_turnover} value={data ? data.current_turnover : 0} />

                <div className="notes">
                    <span>
                        <b>{t("referral.notes")}</b>
                    </span>
                    <p className="note">
                        {t("referral.notes.required_to_completed")}
                    </p>
                    <p className="note">
                        {t("referral.notes.requirements", {
                            active_player: (data.referralDatas[currentLevel].monthly_active - (data ? data.current_active_player : 0)) < 0 ? 0 : data.referralDatas[currentLevel].monthly_active - (data ? data.current_active_player : 0),
                            turnover: helpers.number_format((data ? data.current_turnover : 0) >= data.referralDatas[currentLevel].monthly_turnover ? 0 : (data.referralDatas[currentLevel].monthly_turnover - (data ? data.current_turnover : 0))),
                            date: moment().endOf('month').format('DD/MM/YYYY')
                        })}
                    </p>
                </div>

                {currentLevel === data.referral_level_next.level ? <>
                    <button onClick={upgrade} className="btn btn-success btn-block mt-3" disabled={!data.upgradeable}>
                        {t('referral.unlock_now')}
                    </button>
                </> : <>
                </>}
                <a href="#invite" onClick={handleInfoClose} className="btn btn-primary btn-block mt-3" >
                    {t('referral.invite_friends')}
                </a>
            </div>
        </div>;
    }

    const getReport = () => {
        if (report === "profit_sharing") {
            return <>
                <div className="table-responsive mt-2">
                    <table className="table table-xs table-referral table-bordered w-100">
                        <thead>
                            <tr>
                                <td>{t("referral.table.month")}</td>
                                <td>{t("referral.table.phone")}</td>
                                <td>{t("referral.table.revenue_share")}</td>
                                <td>{t("referral.table.bonus")}</td>
                                <td>{t("referral.table.transaction_fee")}</td>
                                <td>{t("referral.table.royalty_fee")}</td>
                                <td>{t("referral.table.commission")}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.values(data.profit_sharings).map(function (d, i) {
                                    return <tr key={i}>
                                        <td>{moment(d.date).format("MMMM")}</td>
                                        <td>{d.phone === "null" ? "" : d.phone}</td>
                                        <td>{parseFloat(d.ggr).toFixed(2)}</td>
                                        <td>{parseFloat(d.bonus).toFixed(2)}</td>
                                        <td>{parseFloat(d.payment_fee).toFixed(2)}</td>
                                        <td>{parseFloat(d.loyalty_fee).toFixed(2)}</td>
                                        <td>{parseFloat(d.commission).toFixed(2)}</td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>;
        }
        if (report === "first_time_deposit") {
            return <>
                <div className="table-responsive mt-2">
                    <table className="table table-sm table-referral table-bordered w-100">
                        <thead>
                            <tr>
                                <td>{t("referral.table.phone")}</td>
                                <td>{t("referral.table.first_time_deposit_date")}</td>
                                <td>{t("referral.table.first_time_deposit_amount")}</td>
                                <td>{t("referral.table.commission")}</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Object.values(data.first_time_deposits).map(function (d, i) {
                                    return <tr key={i}>
                                        <td>{d.phone === "null" ? "" : d.phone}</td>
                                        <td>{d.created_at === "null" ? "" : d.created_at}</td>
                                        <td>{parseFloat(d.deposit).toFixed(2)}</td>
                                        <td>{parseFloat(d.commission).toFixed(2)}</td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </>;
        }
        if (report === "withdrawals") {
            return <div className="pt-2">
                {/* redeemType */}
                <div className="mb-2">
                    <label className="fw-bold mb-2">{t('referral.withdraw.select_type')}</label>
                    <Select
                        className="fs-small font-bold text-grey mb-3"
                        value={types.filter(function (option) { return option.value === redeemType; })}
                        onChange={(e) => setWithdrawType(e.value)}
                        options={types}
                        placeholder={t('referral.withdraw.select_wallet')}
                        styles={helpers.reactSelectStyles()}
                        isSearchable={false}
                    />
                </div>
                <div className="mb-2">
                    <label className="fw-bold mb-2">{t('referral.withdraw.select_wallet')}</label>
                    <Select
                        className="fs-small font-bold text-grey mb-3"
                        value={userWallets.filter(function (option) { return option.value === redeemWallet; })}
                        onChange={(e) => setWithdrawWallet(e.value)}
                        options={userWallets}
                        placeholder={t('referral.withdraw.select_wallet')}
                        styles={helpers.reactSelectStyles()}
                        isSearchable={false}
                    />
                </div>
                <div className="mb-2">
                    <label className="fw-bold mb-2">{t('referral.withdraw.withdrawable_earning')}</label>
                    <input className="form-control" value={redeemType === "Profit Sharing" ? data.revenus_share_withdrawable : (redeemType === "First Time Deposit" ? data.first_time_deposit_withdrawable : "")} disabled />
                </div>
                <div className="mb-2">
                    <div className="text-center mb-2">
                        <Button
                            className="font-bold"
                            variant="blue"
                            disabled={isSubmit}
                            onClick={withdraw}
                        >{t("referral.withdraw.submit")}</Button>
                    </div>
                </div>
            </div>
        }
    }

    useEffect(() => {
        const imagesPreload = [
            "https://api.38386868.com/referral/qrcode/" + user.code + "/9-16/en",
            "https://api.38386868.com/referral/qrcode/" + user.code + "/1-1/en",
            "https://api.38386868.com/referral/qrcode/" + user.code + "/16-9/en",
            "https://api.38386868.com/referral/qrcode/" + user.code + "/9-16/zh_CN",
            "https://api.38386868.com/referral/qrcode/" + user.code + "/1-1/zh_CN",
            "https://api.38386868.com/referral/qrcode/" + user.code + "/16-9/zh_CN",
        ];

        imagesPreload.forEach((image) => {
            window[image] = new Image().src;
        });

        if (!data) {
            refresh();
        }

        if (isAuth() && user) {
            setUserWallets(user.user_wallets.map(wallet => {
                return {
                    value: wallet.id,
                    label: t(helpers.getWalletType(wallet.type))
                };
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data) {
        return <Loading />
    }

    return <>
        <Helmet>
            <title> {seo ? helpers.t(seo.meta_title) : t('referral.title') + " | BD88"}</title>
            <meta name="keywords" content={seo ? helpers.t(seo.meta_keyword) : ""} />
            <meta name="description" content={seo ? helpers.t(seo.meta_description) : ""} />
        </Helmet>

        <div className={isAuth() ? "content logined" : "content"}>
            <div className={user.referral.level + 1 !== currentLevel ? "referral not_avaliable" : "referral"}>
                <div className="container-lg mb-2">
                    <div className="referral_gift">
                        <div className="row justify-content-between">
                            <div className="col-12 col-md-7 col-xl-8 d-flex flex-column justify-content-center align-items-center">
                                <div className="m-auto">
                                    <div className="current_level">
                                        <img className="current" src="/assets/referral/current_box.png" alt="current_box" />
                                        <img className="current_spotlight" src="/assets/referral/spotlight.png" alt="spotlight" />
                                        <img className="current_holder" src="/assets/referral/holder.png" alt="holder" />
                                    </div>
                                </div>
                                <div className="d-none d-xl-block mt-5 w-100">
                                    {getDescription()}
                                </div>
                            </div>
                            <div className="col-md-5 col-xl-4 d-none d-md-block">
                                {getBox()}
                            </div>
                            <div className="col-12 col-xl-8 d-xl-none">
                                {getDescription()}
                            </div>
                            <div className="col-12">
                                <div className="levels">
                                    <div className={user.referral.level >= 1 ? (currentLevel === 1) ? "level redeemed-level current-level" : "level redeemed-level" : (currentLevel === 1) ? (user.referral.level + 1 < 1) ? "level not-avaliable-level current-level" : "level current-level" : (user.referral.level + 1 < 1) ? "level not-avaliable-level" : "level"} onClick={() => setCurrentLevel(1)}>
                                        <img src="/assets/referral/gift_box.png" alt="gift_box" />
                                        <span className="redeemed-label">
                                            {t('referral.boxes.redeemed')}
                                        </span>
                                    </div>
                                    <div className={user.referral.level >= 2 ? (currentLevel === 2) ? "level redeemed-level current-level" : "level redeemed-level" : (currentLevel === 2) ? (user.referral.level + 1 < 2) ? "level not-avaliable-level current-level" : "level current-level" : (user.referral.level + 1 < 2) ? "level not-avaliable-level" : "level"} onClick={() => setCurrentLevel(2)}>
                                        <img src="/assets/referral/gift_box.png" alt="gift_box" />
                                        <span className="redeemed-label">
                                            {t('referral.boxes.redeemed')}
                                        </span>
                                    </div>
                                    <div className={user.referral.level >= 3 ? (currentLevel === 3) ? "level redeemed-level current-level" : "level redeemed-level" : (currentLevel === 3) ? (user.referral.level + 1 < 3) ? "level not-avaliable-level current-level" : "level current-level" : (user.referral.level + 1 < 3) ? "level not-avaliable-level" : "level"} onClick={() => setCurrentLevel(3)}>
                                        <img src="/assets/referral/gift_box.png" alt="gift_box" />
                                        <span className="redeemed-label">
                                            {t('referral.boxes.redeemed')}
                                        </span>
                                    </div>
                                    <div className={user.referral.level >= 4 ? (currentLevel === 4) ? "level redeemed-level current-level" : "level redeemed-level" : (currentLevel === 4) ? (user.referral.level + 1 < 4) ? "level not-avaliable-level current-level" : "level current-level" : (user.referral.level + 1 < 4) ? "level not-avaliable-level" : "level"} onClick={() => setCurrentLevel(4)}>
                                        <img src="/assets/referral/gift_box.png" alt="gift_box" />
                                        <span className="redeemed-label">
                                            {t('referral.boxes.redeemed')}
                                        </span>
                                    </div>
                                    <div className={user.referral.level >= 5 ? (currentLevel === 5) ? "level redeemed-level current-level" : "level redeemed-level" : (currentLevel === 5) ? (user.referral.level + 1 < 5) ? "level not-avaliable-level current-level" : "level current-level" : (user.referral.level + 1 < 5) ? "level not-avaliable-level" : "level"} onClick={() => setCurrentLevel(5)}>
                                        <img src="/assets/referral/gift_box.png" alt="gift_box" />
                                        <span className="redeemed-label">
                                            {t('referral.boxes.redeemed')}
                                        </span>
                                    </div>
                                    <div className={user.referral.level >= 6 ? (currentLevel === 6) ? "level redeemed-level current-level" : "level redeemed-level" : (currentLevel === 6) ? (user.referral.level + 1 < 6) ? "level not-avaliable-level current-level" : "level current-level" : (user.referral.level + 1 < 6) ? "level not-avaliable-level" : "level"} onClick={() => setCurrentLevel(6)}>
                                        <img src="/assets/referral/iphone-box.png" alt="iphone-box" />
                                        <span className="redeemed-label">
                                            {t('referral.boxes.redeemed')}
                                        </span>
                                    </div>
                                    <div className={user.referral.level >= 7 ? (currentLevel === 7) ? "level redeemed-level current-level" : "level redeemed-level" : (currentLevel === 7) ? (user.referral.level + 1 < 7) ? "level not-avaliable-level current-level" : "level current-level" : (user.referral.level + 1 < 7) ? "level not-avaliable-level" : "level"} onClick={() => setCurrentLevel(7)}>
                                        <img src="/assets/referral/gift_box.png" alt="gift_box" />
                                        <span className="redeemed-label">
                                            {t('referral.boxes.redeemed')}
                                        </span>
                                    </div>
                                    <div className={user.referral.level >= 8 ? (currentLevel === 8) ? "level redeemed-level current-level" : "level redeemed-level" : (currentLevel === 8) ? (user.referral.level + 1 < 8) ? "level not-avaliable-level current-level" : "level current-level" : (user.referral.level + 1 < 8) ? "level not-avaliable-level" : "level"} onClick={() => setCurrentLevel(8)}>
                                        <img src="/assets/referral/rolex-box.png" alt="rolex-box" />
                                        <span className="redeemed-label">
                                            {t('referral.boxes.redeemed')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="invite" className="container mb-2">
                    <div className="row">
                        <div className="col-12">
                            <div className="steps bg-white">
                                <h5>{t("referral.step.title")}</h5>
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-12 col-md-2 step">
                                        <img src="/assets/referral/share-icon.png" alt="share to friends" />
                                        <h6 className="mt-2">{t("referral.step.one")}</h6>
                                    </div>
                                    <div className="col-12 col-md-2 d-none d-md-block text-center">
                                        <span className="text-blue">------------------</span>
                                    </div>
                                    <div className="col-12 col-md-2 step">
                                        <img src="/assets/referral/accept-refferal-icon.png" alt="accept referral" />
                                        <h6 className="mt-2">{t('referral.step.two')}</h6>
                                    </div>
                                    <div className="col-12 col-md-2 d-none d-md-block text-center">
                                        <span className="text-blue">------------------</span>
                                    </div>
                                    <div className="col-12 col-md-2 step">
                                        <img src="/assets/referral/register-icon.png" alt="register to play" />
                                        <h6 className="mt-2">{t('referral.step.three')}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row mx-1">
                        <div className="col-12 col-md-6">
                            <div className="card referral-card">
                                <div className="card-body">
                                    <h4 className="text-blue font-bolder mb-3">{t('referral.dashboard.title')}</h4>
                                    <div className="items">
                                        <div className="item">
                                            <div className="d-flex justify-content-between">
                                                <span>{t('referral.dashboard.revenue_share_reward')}</span>
                                                <span className="font-bolder">{user.currency} {data.revenus_share_reward}</span>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="d-flex justify-content-between">
                                                <span>
                                                    {t('referral.dashboard.first_time_deposit_reward')}

                                                    <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="ftd1"> {t("referral.box.first_time_deposit")}</Tooltip>}>
                                                        <button className="border-0 bg-transparent p-0">
                                                            <FontAwesomeIcon className="ms-2" icon={solid('circle-info')} />
                                                        </button>
                                                    </OverlayTrigger>
                                                </span>
                                                <span className="font-bolder">{user.currency} {data.first_time_deposit_reward}</span>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="d-flex justify-content-between">
                                                <span>{t('referral.dashboard.clicks')}</span>
                                                <span className="font-bolder">{data.click_count}</span>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="d-flex justify-content-between">
                                                <span>{t('referral.dashboard.sign_up')}</span>
                                                <span className="font-bolder">{data.signup_count}</span>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="d-flex justify-content-between">
                                                <span>
                                                    {t('referral.dashboard.first_time_deposit')}

                                                    <OverlayTrigger delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="ftd2"> {t("referral.box.first_time_deposit")}</Tooltip>}>
                                                        <button className="border-0 bg-transparent p-0">
                                                            <FontAwesomeIcon className="ms-2" icon={solid('circle-info')} />
                                                        </button>
                                                    </OverlayTrigger>
                                                </span>
                                                <span className="font-bolder">{data.ftd_count}</span>
                                            </div>
                                        </div>
                                        <div className="item mb-1">
                                            <div className="d-flex justify-content-between">
                                                <span>{t('referral.dashboard.total_earning')}</span>
                                                <span className="font-bolder">{data.total_earning}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card referral-card p-0">
                                <div className="card-body">
                                    <div className="size-selector d-none d-lg-block">
                                        <div className={ratio === "9-16" ? "size-selector-item active" : "size-selector-item"} onClick={() => setRatio("9-16")}>
                                            <img className="me-2" src="/assets/referral/9:16_ratio.svg" alt="9-16" />
                                            <span className="me-1">9:16</span>
                                            <svg className="check ms-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className={ratio === "1-1" ? "size-selector-item active" : "size-selector-item"} onClick={() => setRatio("1-1")}>
                                            <img className="me-2" src="/assets/referral/1:1_ratio.svg" alt="1-1" />
                                            <span className="me-1">1:1</span>
                                            <svg className="check ms-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className={ratio === "16-9" ? "size-selector-item active" : "size-selector-item"} onClick={() => setRatio("16-9")}>
                                            <img className="me-2" src="/assets/referral/16:9_ratio.svg" alt="16-9" />
                                            <span className="me-1">16:9</span>
                                            <svg className="check ms-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="size-selector mobile-size-selector">
                                        <div className={ratio === "9-16" ? "size-selector-item active" : "size-selector-item"} onClick={() => setRatio("9-16")}>
                                            <img className="me-2" src="/assets/referral/9:16_ratio.svg" alt="9-16" />
                                            <span className="me-1">9:16</span>
                                            <svg className="check ms-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className={ratio === "1-1" ? "size-selector-item active" : "size-selector-item"} onClick={() => setRatio("1-1")}>
                                            <img className="me-2" src="/assets/referral/1:1_ratio.svg" alt="1-1" />
                                            <span className="me-1">1:1</span>
                                            <svg className="check ms-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" fill="currentColor" />
                                            </svg>
                                        </div>
                                        <div className={ratio === "16-9" ? "size-selector-item active" : "size-selector-item"} onClick={() => setRatio("16-9")}>
                                            <img className="me-2" src="/assets/referral/16:9_ratio.svg" alt="16-9" />
                                            <span className="me-1">16:9</span>
                                            <svg className="check ms-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z" fill="currentColor" />
                                            </svg>
                                        </div>
                                    </div>

                                    <div className="banner">
                                        <img className={ratio} src={data.qrcode_image + ratio + "/" + helpers.getLocale()} alt="banner" />
                                    </div>
                                </div>
                                <div className="card-footer bg-referral px-5">
                                    <div className="row">
                                        <div className="col-12 col-md-6">
                                            <span className="text-white">{t('referral.invite.referral_code')}</span>

                                            <div className="input-group input-group-sm mb-3">
                                                <input type="text" className="form-control form-control-sm bg-white" value={data.referral_code} readOnly />
                                                <Clipboard className="btn btn-input-copy bg-white"
                                                    data-clipboard-text={data.referral_code}
                                                    onSuccess={() => toast.success("Copied!")}
                                                >
                                                    <img className="copy-icon" src="/assets/referral/copy-icon.png" alt="copy" />
                                                </Clipboard>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6">
                                            <span className="text-white">{t('referral.invite.referral_link')}</span>

                                            <div className="input-group input-group-sm mb-3">
                                                <input type="text" className="form-control form-control-sm bg-white" value={data.referral_link} readOnly />
                                                <Clipboard className="btn btn-input-copy bg-white"
                                                    data-clipboard-text={data.referral_link}
                                                    onSuccess={() => toast.success("Copied!")}
                                                >
                                                    <img className="copy-icon" src="/assets/referral/copy-icon.png" alt="copy" />
                                                </Clipboard>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="share">
                                                <WhatsappShareButton
                                                    url={data.referral_link}
                                                    quote={""}
                                                    hashtag={" "}
                                                >
                                                    <img className="share-icon" src="/assets/referral/whatsapp.png" alt="whatsapp" />
                                                </WhatsappShareButton>
                                                <FacebookMessengerShareButton
                                                    url={data.referral_link}
                                                    quote={""}
                                                    hashtag={" "}
                                                >
                                                    <img className="share-icon" src="/assets/referral/facebook_messenger.png" alt="facebook messenger" />
                                                </FacebookMessengerShareButton>
                                                <TelegramShareButton
                                                    url={data.referral_link}
                                                    quote={""}
                                                    hashtag={" "}
                                                >
                                                    <img className="share-icon" src="/assets/referral/telegram.png" alt="telegram" />
                                                </TelegramShareButton>
                                                <FacebookShareButton
                                                    url={data.referral_link}
                                                    quote={""}
                                                    hashtag={" "}
                                                >
                                                    <img className="share-icon" src="/assets/referral/facebook.png" alt="facebook" />
                                                </FacebookShareButton>
                                                <EmailShareButton
                                                    url={data.referral_link}
                                                    quote={""}
                                                    hashtag={" "}
                                                >
                                                    <img className="share-icon" src="/assets/referral/gmail.png" alt="gmail" />
                                                </EmailShareButton>
                                                <a href={data.qrcode_image + ratio + "/" + helpers.getLocale()} target="_blank" rel="noreferrer" download={ratio + ".svg"}>
                                                    <img className="share-icon" src="/assets/referral/download.png" alt="download" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card referral-card mx-3">
                        <div className="card-body">
                            <h5 className="text-blue">{t('referral.report.title')}</h5>
                            <span className="fs-smaller font-bold">{t('referral.report.subtitle')}</span>
                            <div className="buttons mt-2">
                                <div className={report === "profit_sharing" ? "button active" : "button"} onClick={() => setReport("profit_sharing")}>
                                    {t('referral.report.profit_sharing')}
                                </div>
                                <div className={report === "first_time_deposit" ? "button active" : "button"} onClick={() => setReport("first_time_deposit")}>
                                    {t('referral.report.first_time_deposit')}
                                </div>
                                <div className={report === "withdrawals" ? "button active" : "button"} onClick={() => setReport("withdrawals")}>
                                    {t('referral.report.withdrawals')}
                                </div>
                            </div>

                            {getReport()}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal className="referral" show={showInfo} onHide={handleInfoClose}>
            <Modal.Dialog>
                <button type="button" className="btn-close z-index-999999" aria-label="Close" onClick={handleInfoClose}></button>
                <Modal.Body>
                    {getBox()}
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    </>;
}

export default Referral;
