import React from "react";
import "./style.css";
import { Carousel } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { t } from "i18next";

export const Testimonials = (props) => {
    const testimonials = [
        'BD88 Online Casino is where I found my happy place. The games, bonuses, and customer service team are all top-notch.',
        'As a regular at BD88, I can attest to the fact that this casino is trustworthy and offers exciting opportunities to win big.',
        'I’ve tried many online casinos, but none compare to BD88’s variety of games and user-friendly interface.',
        'I never thought I would win big at an online casino, but BD88 proved me wrong. I just hit a huge jackpot playing slots!',
        'I highly recommend BD88 Online Casino to anyone who loves to gamble. It’s a safe and fun environment to play in.',
        'BD88 Online Casino is my go-to for entertainment and relaxation. The games are addictive and the payouts are generous.',
        'I signed up for BD88 last week and already won over $1000 on blackjack. It’s the best decision I’ve made in a long time.',
        'Thanks to BD88, my weekends have become even more exciting. Their live dealer games are so realistic that I forget I’m not in an actual casino.',
        'If you’re looking for an online casino with excellent customer service, look no further than BD88. I’ve had such positive experiences interacting with their team.',
    ];
    const chunk = (arr, chunkSize) => {
        var R = [];
        for (var i = 0, len = arr.length; i < len; i += chunkSize)
            R.push(arr.slice(i, i + chunkSize));
        return R;
    }

    return <div className="container px-lg-4 mt-5 mb-2">
        <h4>{t('testimonials')}</h4>
        <Carousel indicators={false} prevIcon={"<"} nextIcon={">"} touch={true}>
            {
                chunk(testimonials, 3).map((chunk, k) => {
                    return <Carousel.Item className="w-100" key={k}>
                        <div className="row">
                            {chunk.map(function (testimonial, i) {
                                return <div className="col-12 col-md-4 mb-4" key={i}>
                                    <div className="card testimonial-card" >
                                        <div className="card-body">
                                            <p className="text-muted">
                                                <FontAwesomeIcon className="pe-2" icon={solid('quote-left')} />
                                                {testimonial}
                                            </p>
                                            <ul className="list-unstyled d-flex justify-content-center text-warning mb-0">
                                                <li><FontAwesomeIcon icon={solid('star')} /></li>
                                                <li><FontAwesomeIcon icon={solid('star')} /></li>
                                                <li><FontAwesomeIcon icon={solid('star')} /></li>
                                                <li><FontAwesomeIcon icon={solid('star')} /></li>
                                                <li><FontAwesomeIcon icon={solid('star')} /></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </Carousel.Item>;
                })
            }

        </Carousel>
    </div>;
};

export default Testimonials;
