import React from 'react';
import { Helmet } from "react-helmet";
import "./Contact.css";
import { Button, Card, Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import i18next, { t } from "i18next";
import contact_background from "./../../Assets/Contact/background.jpg";
import mobile_contact_background from "./../../Assets/Contact/mobile_background.jpeg";

import cn_contact_background from "./../../Assets/Contact/cn_background.jpg";
import cn_mobile_contact_background from "./../../Assets/Contact/cn_mobile_background.jpg";

import { useState, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { PageService } from "../../Models/Page/PageService";

function Contact(props) {
    const SITE_KEY = "6LdgHvcdAAAAAG7IO_tpitkM0rC-PBZL3jrxgwfC";
    const seo = props.data.Seo.data;
    const helpers = props.data.helpers;
    const toast = props.data.toast;
    const [isSubmit, setIsSubmit] = useState(false);
    const [form, setForm] = useState({
        username: null,
        email: null,
        phone: null,
        type: null,
        message: null,
    });

    const [errors, setErrors] = useState({
        username: null,
        email: null,
        phone: null,
        type: null,
        message: null,
    });

    const getAuth = () => {
        return props.data.Authentication;
    };

    const isAuth = () => {
        return getAuth().func.isLogin();
    };

    const handleInputs = name => event => {
        setForm({ ...form, [name]: event.target.value });
    };

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
        });
    }, []);


    useEffect(() => {
        const validator = new SimpleReactValidator({
            element: false,
            validators: {
                phone_format: {
                    rule: (val, params, validator) => {
                        return validator.helpers.testRegex(val, /^(1)[0-46-9][0-9]{7,8}$/i) && params.indexOf(val) === -1;
                    },
                }
            }
        });

        validator.message('username', form.username, 'required', {
            messages: {
                required: t('username.required'),
            },
        });

        validator.message('email', form.email, 'required|email', {
            messages: {
                required: t('email.required'),
                email: t('email.email'),
            },
        });

        validator.message('phone', form.phone, 'required', {
            messages: {
                required: t('phone.required'),
            },
        });

        validator.message('type', form.type, 'required', {
            messages: {
                required: t('type.required'),
            },
        });

        validator.message('message', form.message, 'required', {
            messages: {
                required: t('message.required'),
            },
        });

        const validate = () => {
            var $errors = {};
            for (const [key, value] of Object.entries(validator.fields)) {
                if (!value) {
                    $errors[key] = validator.errorMessages[key];
                } else {
                    $errors[key] = null;
                }
            }
            return $errors;
        }

        setErrors(validate());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const contact = (e) => {
        setIsSubmit(true);
        e.preventDefault();
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                PageService.contact({ ...form, token: token }).then(response => {
                    setIsSubmit(false);
                    if (response.status) {
                        setForm({ username: null, email: null, phone: null, type: null, message: null });
                        toast.success(t(response.message));
                        if (window.fbq) {
                            window.fbq('track', 'Lead');
                        }
                        if (window.gtag) {
                            window.gtag('event', 'lend');
                        }
                    } else {
                        toast.error(t(response.message));
                    }
                }).catch(error => {
                    setIsSubmit(false);
                });
            });
        });

        return false;
    }

    return (
        <div className={isAuth() ? "content logined" : "content"}>
            <Helmet>
                <title> {seo ? helpers.t(seo.meta_title) : t("contact.title") + " | BD88"}</title>
                <meta name="keywords" content={seo ? helpers.t(seo.meta_keyword) : ""} />
                <meta name="description" content={seo ? helpers.t(seo.meta_description) : ""} />
            </Helmet>
            <div className={props.data.isMobile ? "bg-grey pb-5" : "bg-white mb-5"}>
                <img className="w-100" src={!props.data.isMobile ? (i18next.language === "en") ? contact_background : cn_contact_background : (i18next.language === "en") ? mobile_contact_background : cn_mobile_contact_background} alt="contact" />

                <Container className="follow-us">
                    <Card className="border-0">
                        <Card.Body>
                            <h5 className="text-center font-boldest mb-3">{t('contact.follow_us')}</h5>
                            <Button className="mb-2 fs-small" variant="default" size="block" onClick={() => props.data.LiveChat.func.open()}>{t('contact.24_hours_live_chat')}</Button>
                            <Button className="mb-2 fs-small" variant="default" size="block" onClick={() => window.open('mailto:cs@bd88my.com', '_blank')}>{t('contact.email')}</Button>
                            <Button className="mb-2 fs-small" variant="default" size="block" onClick={() => window.open('https://www.facebook.com/official.BD88', '_blank')}>{t('contact.facebook')}</Button>
                        </Card.Body>
                    </Card>
                </Container>

                <Container className="contact-form">
                    <Card className="border-0">
                        <Card.Body>
                            <h5 className="text-center font-boldest mb-3">{t('contact.contact_bd88')}</h5>

                            <Form onSubmit={contact} noValidate>
                                <Row>
                                    <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                        <FloatingLabel label={t('contact.name_or_username') + "*"}>
                                            <Form.Control
                                                name="username"
                                                type="text"
                                                value={form.username == null ? '' : form.username}
                                                onChange={handleInputs("username")}
                                                placeholder=" "
                                                isInvalid={form.username !== null && errors.username !== null}
                                                isValid={form.username !== null && errors.username === null}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                        <FloatingLabel label={t('contact.email') + "*"}>
                                            <Form.Control
                                                name="email"
                                                type="text"
                                                value={form.email == null ? '' : form.email}
                                                onChange={handleInputs("email")}
                                                placeholder=" "
                                                isInvalid={form.email !== null && errors.email !== null}
                                                isValid={form.email !== null && errors.email === null}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                        <FloatingLabel label={t('contact.phone_number') + "*"}>
                                            <Form.Control
                                                name="phone"
                                                type="text"
                                                value={form.phone == null ? '' : form.phone}
                                                onChange={handleInputs("phone")}
                                                placeholder=" "
                                                isInvalid={form.phone !== null && errors.phone !== null}
                                                isValid={form.phone !== null && errors.phone === null}
                                                autoComplete="off"
                                            />
                                            <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} md={6} className="mb-3">
                                        <FloatingLabel label={t('contact.type_of_query') + "*"}>
                                            <Form.Select
                                                value={form.type == null ? '' : form.type}
                                                onChange={handleInputs("type")}
                                                placeholder=" "
                                                isInvalid={form.type !== null && errors.type !== null}
                                                isValid={form.type !== null && errors.type === null}
                                                autoComplete="off"
                                            >
                                                <option value="">{t('contact.please_select')}</option>
                                                <option value="Account">{t('contact.account')}</option>
                                                <option value="Deposit">{t('contact.deposit')}</option>
                                                <option value="Withdrawal">{t('contact.withdrawal')}</option>
                                                <option value="Promotions">{t('contact.promotions')}</option>
                                                <option value="Others">{t('contact.others')}</option>
                                            </Form.Select>

                                            <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>

                                    <Form.Group as={Col} xs={12} className="mb-3">
                                        <FloatingLabel label={t('contact.message') + "*"}>
                                            <Form.Control
                                                as="textarea"
                                                placeholder=" "
                                                style={{ height: '100px' }}
                                                onChange={handleInputs("message")}
                                                isInvalid={form.message !== null && errors.message !== null}
                                                isValid={form.message !== null && errors.message === null}
                                                autoComplete="off" />
                                            <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>


                                    <Col xs={12} className="mb-3">
                                        <div className="d-grid gap-2">
                                            <Button variant="blue" type="submit" disabled={isSubmit}>
                                                {t('contact.submit')}
                                            </Button>
                                        </div>
                                    </Col>

                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Container>
            </div>
        </div>
    );
}

export default Contact;