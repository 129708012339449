import * as qs from 'query-string';
import { useEffect, useState } from "react";
import { deposit_page } from '../../Models/Pixel/Pixel';

export default function CashierModalHook() {
    const [cashierModal, setCashierModal] = useState(false);
    const [cashierPage, setCashierPage] = useState(null);
    const [cashierCallback, setCashierCallback] = useState(null);
    const [quickTransferWallet, setQuickTransferWallet] = useState(null);
    const open = () => { setCashierModal(true); setCashierPage("wallet"); };
    const close = () => {
        setCashierModal(false);
        setCashierPage("wallet");
        if (cashierCallback) {
            cashierCallback();
        }
        setCashierCallback(null)
    }
    const wallet = () => { setCashierModal(true); setCashierPage("wallet"); };
    const deposit = (type) => {
        if (type) {
            localStorage.setItem("depositWallet", type);
        }
        setCashierModal(true);
        setCashierPage("deposit");
        deposit_page();

        if (window.fbq) {
            window.fbq('track', 'InitiateCheckout');
        }

        if (window.dataLayer) {
            window.dataLayer.push({
                event: 'begin_checkout',
                eventProps: {}
            });
        }
    };
    const withdrawal = () => { setCashierModal(true); setCashierPage("withdrawal"); };
    const transfer = () => { setCashierModal(true); setCashierPage("transfer"); };
    const quick_transfer = (wallet, func) => {
        setQuickTransferWallet(wallet);
        setCashierCallback(() => func);
        setCashierModal(true);
        setCashierPage("quick_transfer");
    };

    const gamelogs = () => { setCashierModal(true); setCashierPage("gamelogs"); }

    useEffect(() => {
        const parsed_query = qs.parse(window.location.search);
        if (parsed_query.modal === "depositModal") {
            deposit();
        }
    }, []);

    return {
        status: cashierModal,
        page: cashierPage,
        callback: cashierCallback,
        quickTransferWallet: quickTransferWallet,
        func: {
            open,
            close,
            wallet,
            deposit,
            withdrawal,
            transfer,
            gamelogs,
            quick_transfer,
        },
    };
}