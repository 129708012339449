import { useEffect, useState } from "react";
import { GameService } from "./GameService";

export default function NewGameHook() {
    const [data, setData] = useState([]);

    useEffect(() => {
        GameService.games({ type: "new_games" }).then(response => {
            setData(response);
        });
    }, []);

    return data;
}