import { useState } from "react";
import { GameService } from "../../Models/Game/GameService";

export default function LauncherHook() {
    const [isLoading, setLoading] = useState(false);
    const [status, setStatus] = useState(false);
    const [isGame, setIsGame] = useState(true);
    const [url, setUrl] = useState(null);
    const open = () => setStatus(true);
    const close = () => setStatus(false);

    const launch = (url, force = false, isGame = true) => {
        setIsGame(isGame);
        if (force || window.innerWidth <= 768) {
            if (!window.open(url, '_blank', 'noopener,noreferrer')) {
                setUrl(url);
                open();
            }
        } else {
            setUrl(url);
            open();
        }
    }

    const popup = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer')
    }

    const start = (element) => {
        setLoading(true);
        if (!isLoading) {
            GameService.launch({ 'product_id': element.product_id, 'type': element.type, 'code': element.code }).then(response => {
                if (response && response.status) {
                    if (response.type === "launch") {
                        launch(response.url);
                    }
                    if (response.type === "popup") {
                        popup(response.url);
                    }
                }
                setLoading(false);
            });
        }
    }

    return {
        isLoading: isLoading,
        status: status,
        url: url,
        isGame: isGame,
        func: {
            setLoading,
            popup,
            launch,
            close,
            start,
        },
    };
}