import React, { useState, useEffect } from "react";
import { Offcanvas, CloseButton, Form, FloatingLabel, Col, Row, Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserService } from "../../Models/User/UserService";
import SimpleReactValidator from "simple-react-validator";

export const ComposeCanvas = (props) => {
    const InboxCanvas = props.data.InboxCanvas;

    const { t } = useTranslation();
    const [isSubmit, setIsSubmit] = useState(false);

    const [form, setForm] = useState({
        title: null,
        message: null,
    });

    const [errors, setErrors] = useState({
        title: null,
        message: null,
    });

    const close = () => {
        setForm({ title: null, message: null });
        props.data.ComposeCanvas.func.close();
    }

    const handleInputs = name => event => {
        setForm({ ...form, [name]: event.target.value });
    };

    const validator = new SimpleReactValidator({
        element: false,
    });

    validator.message('title', form.title, 'required', {
        messages: {
            required: t('title.required'),
        },
    });

    validator.message('message', form.message, 'required', {
        messages: {
            required: t('message.required'),
        },
    });

    const validate = () => {
        return new Promise(async function (resolve, reject) {
            var $errors = {};
            for (const [key, value] of Object.entries(validator.fields)) {
                if (!value) {
                    $errors[key] = validator.errorMessages[key];
                } else {
                    $errors[key] = null;
                }
            }

            return resolve($errors);
        });
    }

    useEffect(() => {
        validate().then(errors => setErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const submit = (e) => {
        setIsSubmit(true);
        e.preventDefault();

        UserService.inbox_compose(form).then(response => {
            if (response.status) {
                setIsSubmit(false);
                close();

                InboxCanvas.setData(response.data);
                InboxCanvas.func.open();
            }
        });
    }

    return <Offcanvas
        id="Inbox"
        aria-labelledby="ComposeCanvasLabel"
        placement="end"
        backdrop="true"
        className="w-100"
        show={props.data.ComposeCanvas.status}
        onHide={props.data.ComposeCanvas.func.close}
    >
        <Offcanvas.Body className="px-0 pb-0">
            <h5 className="fw-bold mb-3 px-3">{t('compose.title')}</h5>
            <CloseButton onClick={close} />

            <Container>
                <Form onSubmit={submit} noValidate>
                    <Row>
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel label={t('title')}>
                                <Form.Control
                                    type="text"
                                    value={form.title == null ? '' : form.title}
                                    onChange={handleInputs("title")}
                                    placeholder=" "
                                    isInvalid={form.title !== null && errors.title !== null}
                                    isValid={form.title !== null && errors.title === null}
                                    autoComplete="none"
                                />
                                <Form.Control.Feedback type="invalid">{errors.title}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel label={t('message')}>
                                <Form.Control
                                    as="textarea"
                                    type="text"
                                    value={form.message == null ? '' : form.message}
                                    onChange={handleInputs("message")}
                                    placeholder=" "
                                    isInvalid={form.message !== null && errors.message !== null}
                                    isValid={form.message !== null && errors.message === null}
                                    autoComplete="none"
                                    style={{ height: '100px' }}
                                />
                                <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        <Col xs={12} className="mb-3">
                            <div className="d-grid gap-2">
                                <Button type="submit" disabled={isSubmit}>
                                    {t('compose.submit')}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </Offcanvas.Body>
    </Offcanvas>;
};


export default ComposeCanvas;