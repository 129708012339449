import { request } from '../Core.js';
import { useState, useEffect } from "react";

export default function CampaignHook() {
    const [topbars, setTopBars] = useState([]);
    const [popups, setPopups] = useState([]);
    const [intervals, setIntervals] = useState(JSON.parse(sessionStorage.getItem("campaigns")) ?? {});

    const fetch = (params) => {
        params = { ...params, "token": sessionStorage.getItem("authentication") };

        return new Promise((resolve, reject) => {
            resolve(request('/campaigns', params));
        });
    }

    const update = () => {
        var new_intervals = intervals;
        var popups = [];
        var topbars = [];
        fetch().then(response => {
            for (var key in response) {
                if (response.hasOwnProperty(key)) {
                    var campaign = response[key];
                    if (!intervals.hasOwnProperty(campaign.id) || new Date().getTime() > intervals[campaign.id]) {
                        new_intervals[campaign.id] = new Date().getTime() + campaign.frequent * 60000
                        if (campaign.type === 1) {
                            popups.push(campaign)
                        }
                        if (campaign.type === 2) {
                            topbars.push(campaign)
                        }
                    }
                }
            }

            setPopups(popups);
            setTopBars(topbars);
            setIntervals(new_intervals)
            sessionStorage.setItem('campaigns', JSON.stringify(new_intervals));
        });

    }

    useEffect(() => {
        update();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        update,
        topbars,
        popups,
    };

}