import React, { useState, useEffect } from "react";
import { Modal, Container, Card, Row, Col, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./QuickTransfer.css";
import amount_icon from '../../../Assets/Cashier/amount.png';
import SimpleReactValidator from "simple-react-validator";
import { WalletService } from "../../../Models/User/WalletService";
import { Dropdown } from "../../Inputs/Dropdown/Dropdown";
import Keypad from "../../Inputs/Keypad/Keypad";

export const QuickTransfer = (props) => {
    const { isMobile } = props.data;
    const CashierModal = props.data.CashierModal;
    const user = props.user;
    const { t } = useTranslation();
    const [isSubmit, setIsSubmit] = useState(false);
    const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(0);
    const helpers = props.data.helpers;
    const toast = props.data.toast;

    const userWallets = user.user_wallets.map(wallet => {
        return {
            name: t(helpers.getWalletType(wallet.type)) + " (" + user.currency + " " + helpers.number_format(wallet.withdrawable_balance, 2) + ")",
            image: "/assets/" + helpers.getWalletType(wallet.type) + ".png",
            value: wallet.id,
            label: t(helpers.getWalletType(wallet.type)),
            custom: user.currency + " " + helpers.number_format(wallet.withdrawable_balance, 2)
        };
    });

    const [form, setForm] = useState({
        wallet_type: CashierModal.quickTransferWallet,
        from_wallet: null,
        to_wallet: null,
        amount: null,
    });

    const [errors, setErrors] = useState({
        from_wallet: null,
        to_wallet: null,
        amount: null,
    });

    const handleInputs = name => event => {
        if (name === "from_wallet" || name === "to_wallet") {
            setForm({ ...form, [name]: event.value });
            if (user.user_wallets && name === "from_wallet") {
                user.user_wallets.map(wallet => {
                    if (parseInt(wallet.id) === parseInt(event.value)) {
                        setMaxWithdrawalAmount(wallet.withdrawable_balance);
                    }
                    return true;
                })
            }
            return true;
        }
        setForm({ ...form, [name]: event.target.value });
    };

    useEffect(() => {
        user.user_wallets.map(wallet => {
            if (parseInt(wallet.type) === parseInt(form.wallet_type)) {
                setForm({ ...form, to_wallet: wallet.id });
            }
            return true;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const validator = new SimpleReactValidator({
            element: false,
            validators: {
                insufficient_balance: {
                    rule: (val, params, validator) => {
                        return parseFloat(val) <= parseFloat(params[0]);
                    },
                }
            }
        });

        validator.message('from_wallet', form.from_wallet, 'required', {
            messages: {
                required: t('from_wallet.required'),
            },
        });

        validator.message('to_wallet', form.to_wallet, 'required', {
            messages: {
                required: t('to_wallet.required'),
            },
        });

        validator.message('amount', form.amount, 'required|numeric|min:1,num|insufficient_balance:' + maxWithdrawalAmount + '', {
            messages: {
                required: t('amount.required'),
                numeric: t('amount.numeric'),
                min: t('amount.min'),
                max: t('amount.max'),
                insufficient_balance: t('amount.insufficient_balance'),
            },
        });

        const validate = () => {
            return new Promise(async function (resolve, reject) {
                var $errors = {};
                for (const [key, value] of Object.entries(validator.fields)) {
                    if (!value) {
                        $errors[key] = validator.errorMessages[key];
                    } else {
                        $errors[key] = null;
                    }
                }
                return resolve($errors);
            });
        }

        validate().then(errors => setErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);


    const transfer = async (e) => {
        setIsSubmit(true);
        e.preventDefault();

        await WalletService.transfer(form).then((response) => {
            setIsSubmit(false);
            if (response.status) {
                toast.success(t(response.message));
                setForm({
                    from_wallet: null,
                    to_wallet: null,
                    amount: null,
                });
                CashierModal.func.close();
            } else {
                toast.error(t(response.message));
            }
            props.data.Authentication.func.get().then((response) => {
                props.setUser(response);

            });

        }).catch((res) => {
            setIsSubmit(false);
        })

        return false;
    }

    const getWalletTable = () => {
        return <div className="table-container">
            <table className="table table-sm bg-white mb-0 table-bordered text-center">
                <thead>
                    <tr>
                        <th className="fs-small text-start">{t('products')}</th>
                        <th className="fs-small">{t('balance')}</th>
                        <th className="fs-small">{t('transferable_fund')}</th>
                    </tr>
                </thead>
                <tbody>
                    {user.user_wallets.map(wallet =>
                        <tr key={wallet.id}>
                            <td className="fs-small text-grey text-start">{t(helpers.getWalletType(wallet.type))}</td>
                            <td className="fs-small text-grey">{user.currency} {helpers.number_format(wallet.current_balance, 2)}</td>
                            <td className="fs-small text-grey">{user.currency} {helpers.number_format(wallet.withdrawable_balance, 2)}</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>;
    }

    return <>
        <Modal.Header>
            <Container className="text-center">
                <span className="title">{t('quick_transfer.title')}</span>
            </Container>
        </Modal.Header>
        <Modal.Body className="p-0">
            <Container>
                <Row>
                    <Col xs="12" className="mb-2">
                        {getWalletTable()}
                    </Col>
                    <Col xs="12">
                        <Card>
                            <Card.Body>
                                <Form onSubmit={transfer} noValidate>
                                    <div className="select-wallet mb-3">
                                        <Dropdown
                                            key={1}
                                            options={userWallets.filter(function (wallet) {
                                                if (parseInt(form.to_wallet) === parseInt(wallet.value)) {
                                                    return false;
                                                }
                                                return true;
                                            })}
                                            value={form.from_wallet}
                                            onChange={handleInputs("from_wallet")} />
                                    </div>
                                    <div className="select-wallet mb-3">
                                        <Dropdown
                                            key={1}
                                            options={userWallets.filter(function (wallet) {
                                                if (parseInt(form.from_wallet) === parseInt(wallet.value)) {
                                                    return false;
                                                }
                                                return true;
                                            })}
                                            value={form.to_wallet}
                                            onChange={handleInputs("to_wallet")}
                                            readOnly={true} />
                                    </div>
                                    <div className="input-amount mb-3">
                                        <div className="label">
                                            <img className="icon cashier-icon" src={amount_icon} alt="amount" />
                                            <span className="text-grey font-bold">{t('transfer.amount')}</span>
                                        </div>
                                        <Keypad value={form.amount} error={errors.amount} onChange={handleInputs("amount")} isMobile={isMobile} />
                                    </div>
                                    <div className="d-grid gap-2 mb-2">
                                        <Button variant="primary" type="submit" disabled={isSubmit}>
                                            {t('transfer.transfer_and_play')}
                                        </Button>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <Button variant="blue" type="button" onClick={CashierModal.func.close} disabled={isSubmit}>
                                            {t('transfer.close')}
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Modal.Body>
    </>;
}