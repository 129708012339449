import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// Importing translation files
import en from "../Locale/en.json";
import zh_CN from "../Locale/zh_CN.json";

var default_language = "en";
var chinese_domains = ["xn--bxyq25c.net", "www.xn--bxyq25c.net", "www.xn--88-d87er50g.com", "xn--88-d87er50g.com"];
if (chinese_domains.includes(window.location.hostname)) {
    default_language = "zh_CN";
}

//i18N Initialization
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        lng: localStorage.getItem("i18nextLng") ?? default_language,
        fallbackLng: default_language,
        resources: {
            en: {
                translation: en,
            },
            zh_CN: {
                translation: zh_CN,
            },
        },
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;