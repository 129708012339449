import React from 'react';
import { Link } from 'react-router-dom';
import { t } from "i18next";

function PromoCard(props) {
    const helpers = props.data.helpers;
    const value = props.value;
    const close = props.close;
    return (
        <Link to={"/" + value.slug} className="promotion-card pointer-cursor" onClick={close}>
            <img className="promotion-banner" src={helpers.t(value.thumbnail)} alt={helpers.t(value.title)} />
            <div className='promotion-details'>
                <div className="promotion-text">
                    <label className="promotion-title">{helpers.t(value.title)}</label>
                    <label className="promotion-subtitle">{helpers.t(value.subtitle)}</label>
                </div>
                <button className="font-bold btn btn-blue promotion-button">{t('promotion.read_more')}</button>
            </div>
        </Link>
    );
}

export default PromoCard;
