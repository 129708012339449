import { Helmet } from "react-helmet";
import "./Page.css";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { PageService } from "../../Models/Page/PageService";
import { Container } from "react-bootstrap";
import Loading from "../../Components/Loading/Loading";
import NotFound from "../Errors/NotFound";

function Page(props) {
  const seo = props.data.Seo.data;
  const helpers = props.data.helpers;
  const [data, setData] = useState({ page: null, isFetching: true });
  let { slug } = useParams();

  const getAuth = () => {
    return props.data.Authentication;
  };

  const isAuth = () => {
    return getAuth().func.isLogin();
  };

  useEffect(() => {
    setData({ page: null, isFetching: true });
    const fetchPage = async () => {
      const response = await PageService.get({ slug: slug });
      if (response.status) {
        setData({ page: response.data, isFetching: false });
      } else {
        setData({ page: null, isFetching: false });
      }
    };
    fetchPage();
  }, [slug]);

  if (!data.isFetching && !data.page) {
    return <NotFound data={props.data} />;
  }

  return (
    <div className={isAuth() ? "content logined" : "content"}>
      {data.isFetching ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title> {seo ? helpers.t(seo.meta_title) : helpers.t(data.page.title) + " | BD88"}</title>
            <meta name="keywords" content={ seo ? helpers.t(seo.meta_keyword) : ""} />
            <meta name="description" content={ seo ? helpers.t(seo.meta_description) : ""}  />
          </Helmet>
          <Container className="px-lg-4">
              {!props.data.isMobile && data.page.details ? <img className="w-100" src={helpers.t(data.page.details)} alt={helpers.t(data.page.title)} /> : <></>}
              {props.data.isMobile && data.page.mobile_details ? <img className="w-100" src={helpers.t(data.page.mobile_details)} alt={helpers.t(data.page.title)} /> : <></>}

                <div className="page mt-3" dangerouslySetInnerHTML={{ __html: helpers.t(data.page.content) }} />
          </Container>
        </>
      )}
    </div>
  );
}

export default Page;
