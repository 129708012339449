import { t } from "i18next";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import 'react-lazy-load-image-component/src/effects/blur.css';
import GameCard from "./GameCard";
import GameCardPlaceholder from "./GameCardPlaceholder";

function GameList(props) {
    const hasAdvertisment = props.hasAdvertisment;
    const index_list = (window.innerWidth >= 768) ? (window.innerWidth >= 1024) ? hasAdvertisment ? 29 : 30 : 25 : hasAdvertisment ? 12 : 12;
    const [index, setIndex] = useState(index_list);
    const games = props.games;
    const isLoading = props.isLoading;

    useEffect(() => {
        setIndex(index_list);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [games])

    const hasLoadMore = () => {
        var total_count = games.length;
        if (index >= total_count) {
            return false;
        }
        return true;
    }

    const loadMore = () => {
        if (window.innerWidth < 768) {
            setIndex(index + index_list);
        } else if (window.innerWidth < 1024) {
            setIndex(index + index_list);
        } else {
            setIndex(index + (hasAdvertisment ? index_list + 1 : index_list));
        }
    }

    const getIndexArray = () => {
        const rows = [];
        for (let i = 0; i < index_list; i++) {
            rows.push(i);
        }
        return rows;
    }


    const launch_aviator = () => {
        if (!props.data.Authentication.func.isLogin()) {
            return props.data.LoginModal.func.open();
        }

        props.data.CashierModal.func.quick_transfer(4, () => {
            props.data.Launcher.func.start({
                product_id: 42,
                type: 4,
                code: "spribe_aviator",
            });
        });
    }
    return <>
        <div className="game-lists placeholder-glow">
            {!props.data.isMobile && hasAdvertisment ? <>
                <div className="advertisment" onClick={launch_aviator}>
                    <img className="w-100" src="/assets/aviator_banner.svg" alt={"aviator banner"} />
                </div>
            </> : <></>}
            {isLoading ? <>
                {getIndexArray().map((v, i) => {
                    return <GameCardPlaceholder key={i} />;
                })}
            </> : <>
                {games ? games.filter((e, i) => {
                    return i < index;
                }).map((element, i) => {
                    return <GameCard data={props.data} key={i} element={element} index={i} isShow={index > i} />
                }) : <></>}
            </>}
        </div>

        {hasLoadMore() ?
            <div className="mt-4 mb-2 mx-auto text-center">
                <Button onClick={loadMore}>{t('gamelist.load_more')}</Button>
            </div>
            : <> </>}
    </>;
}

export default GameList;