export const home = () => {
    window.ae_parms_kv = {
        event_type: 'home_page',
        country_code: 'MY',
        lang_code: 'EN',
        depth: 0
    };
    (function () { var el_adv_id = "79d0e869331492b859efed78f376dd30"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());
}

export const category = (category) => {
    window.ae_parms_kv = {
        event_type: 'category_page',
        country_code: 'MY',
        lang_code: 'EN',
        category: category,
        depth: 1
    };
    (function () { var el_adv_id = "79d0e869331492b859efed78f376dd30"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());
}

export const sign_up = () => {
    window.ae_parms_kv = {
        event_type: 'sign_up_event',
        country_code: 'MY',
        lang_code: 'EN',
        depth: 3
    };

    (function () { var el_adv_id = "79d0e869331492b859efed78f376dd30"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());
}

export const join_now = (username) => {
    window.ae_parms_kv = {
        event_type: 'join_now_event',
        country_code: 'MY',
        lang_code: 'EN',
        register_id: username,
        depth: 5
    };
    (function () { var el_adv_id = "79d0e869331492b859efed78f376dd30"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());
}

export const login = (username) => {
    window.ae_parms_kv = {
        event_type: 'login_event',
        country_code: 'MY',
        lang_code: 'EN',
        register_id: username,
        depth: 7
    };
    (function () { var el_adv_id = "79d0e869331492b859efed78f376dd30"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());
}

export const deposit_page = (username) => {
    window.ae_parms_kv = {
        event_type: 'deposit_page',
        country_code: 'MY',
        lang_code: 'EN',
        user_id: username,
        depth: 8
    };
    (function () { var el_adv_id = "79d0e869331492b859efed78f376dd30"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());
}

export const deposit_event = (username, transaction_id, amount) => {
    window.ae_parms_kv = {
        event_type: 'deposit_event',
        country_code: 'MY',
        lang_code: 'EN',
        user_id: username,
        trans_id: transaction_id,
        trans_amount: amount,
        depth: 9
    };
    (function () { var el_adv_id = "79d0e869331492b859efed78f376dd30"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());
}

export const deposit_success = (username, transaction_id, amount) => {
    window.ae_parms_kv = {
        event_type: 'deposit_confirm_page', // Event type
        country_code: 'MY',
        lang_code: 'EN',
        user_id: username,
        trans_id: transaction_id,
        trans_amount: amount,
        depth: 10
    };
    (function () { var el_adv_id = "79d0e869331492b859efed78f376dd30"; var scr = document.createElement("script"); var host = "//d313lzv9559yp9.cloudfront.net"; scr.type = "text/javascript"; scr.async = true; scr.src = host + "/" + el_adv_id + ".js"; var s = document.getElementsByTagName("script")[0]; s.parentNode.insertBefore(scr, s); }());
}