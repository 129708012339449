import { request } from '../Core.js';

export const SpecialEventsService = { angpow_index, angpow_redeem };

function angpow_index(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/special_events/angpow', params));
    })
}

function angpow_redeem(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/special_events/angpow/redeem', params));
    })
}

// function wcga_index(params) {
//     params = { ...params, "token": sessionStorage.getItem("authentication") };

//     return new Promise((resolve, reject) => {
//         resolve(request('/special_events/world_cup_giveaway', params));
//     })
// }

// function wcga_procced(params) {
//     params = { ...params, "token": sessionStorage.getItem("authentication") };

//     return new Promise((resolve, reject) => {
//         resolve(request('/special_events/world_cup_giveaway_proceed', params));
//     })
// }

// function wcga_redeem(params) {
//     params = { ...params, "token": sessionStorage.getItem("authentication") };

//     return new Promise((resolve, reject) => {
//         resolve(request('/special_events/world_cup_giveaway_redeem', params));
//     })
// }