import React from 'react';
import { Modal } from "react-bootstrap"
import "./LiveChat.css";
import i18next from "i18next";

export const LiveChat = (props) => {
    const close = () => {
        props.data.LiveChat.func.close();
    }

    return <Modal
        className="LiveChatModal"
        size="lg"
        show={props.data.LiveChat.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center p-0">
            <iframe title="url" className="w-100 h-100" src={(i18next.language === "en") ? "https://chat.bd88my.online/chat/default" : "https://tawk.to/chat/63a2a7c9daff0e1306dda717/1gkplur9p"} />
            <button className="btn btn-close" onClick={close} />
        </Modal.Body>
    </Modal>
}