import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Offcanvas, CloseButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserService } from "../../Models/User/UserService";
import "./InboxCanvas.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useFilePicker } from 'use-file-picker';

export const InboxCanvas = (props) => {
    const { t } = useTranslation();
    const inbox = props.data.InboxCanvas.data;
    const [data, setData] = useState(null);
    const [input, setInput] = useState("");

    const chatEl = useRef(null);

    const [openFileSelector, { filesContent, clear }] = useFilePicker({
        multiple: false,
        accept: '.jpg,.jpeg,.png',
        limitFilesConfig: { min: 1, max: 1 },
        readAs: 'DataURL',
        maxFileSize: 10,
    });

    const loadInbox = () => {
        UserService.inbox_view({ inbox_id: inbox }).then(response => {
            if (response.status) {
                setData(response.data)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        if (inbox) {
            UserService.inbox_view({ inbox_id: inbox }).then(response => {
                if (response.status) {
                    setData(response.data);
                    loadInbox();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inbox]);

    const send = () => {
        if (input !== "") {
            UserService.inbox_send({ inbox_id: inbox, message: input }).then(response => {
                if (response.status) {
                    setInput("");
                    loadInbox();
                }
            });
        }
    }

    const close = () => {
        setInput("");
        props.data.InboxCanvas.func.close()
    }


    useEffect(() => {
        if (filesContent[0]) {
            UserService.inbox_send_attachment({ attachment: filesContent[0].content, inbox_id: inbox }).then(response => {
                if (response.status) {
                    clear();
                    loadInbox();
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesContent])

    const getDateTime = (string) => {
        var now = moment();
        var target = moment(string);
        if ((now.valueOf() - target.valueOf()) > 43200000) {
            return target.format('YYYY-MM-HH') + "\r\n" + target.format("HH:MM");
        }
        return target.fromNow();

    }

    if (!data) {
        return <></>;
    }

    return <Offcanvas
        id="Inbox"
        aria-labelledby="InboxCanvasLabel"
        placement="end"
        backdrop="true"
        className="w-100"
        show={props.data.InboxCanvas.status}
        onHide={props.data.InboxCanvas.func.close}
    >
        <Offcanvas.Body className="px-0 pb-0">
            <h5 className="fw-bold mb-3 px-3">{data.title}</h5>
            <CloseButton onClick={close} />

            <div className="chat" ref={chatEl} >
                <div className="chat-body">
                    {Object.values(data.messages).reverse().map(function (inbox_message, index) {
                        return <div key={index} className={inbox_message.sender_name === "bd88 Customer Support" ? "chat-message active" : "chat-message"}>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="fs-small">{inbox_message.sender_name}</h6>
                                </div>
                                <div>
                                    {getDateTime(inbox_message.updated_at)}
                                </div>
                            </div>
                            <div>
                                <span>{inbox_message.message}</span>
                            </div>
                            {inbox_message.attachment_url ? <>
                                <div className="d-block">
                                    <a href={inbox_message.attachment_url} target="_blank" rel="noreferrer" download>{t("inbox.download_attachment")}</a>
                                </div>
                            </> : <></>}
                        </div>;
                    })}
                </div>

                <div className="chat-input-container">
                    <textarea className="chat-input" placeholder={t("inbox.message_placeholder")}
                        onChange={(event) => setInput(event.target.value)} value={input}
                        onKeyPress={event => {
                            if (!event.shiftKey && event.key === 'Enter') {
                                event.preventDefault();
                                send();
                            }
                        }}
                    />
                    <button className="btn btn-chat-button-clip" onClick={() => openFileSelector()}>
                        <FontAwesomeIcon icon={solid('paperclip')} />
                    </button>
                    <button className="btn btn-primary btn-chat-button" onClick={send}>{t("inbox.send")}</button>
                </div>

            </div>
        </Offcanvas.Body>
    </Offcanvas>;
};


export default InboxCanvas;