
import { t } from "i18next";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { UserService } from "../../Models/User/UserService";
import "./InactivePopup.css";

export const InactivePopup = (props) => {
    const user = props.data.Authentication.user;
    const inactive_popup = props.data.InactivePopup;

    useEffect(() => {
        if (user) {
            user.user_events.map(event => {
                if (event.type === "inactive") {
                    UserService.inactive().then(response => {
                        if (response !== undefined && response.status) {
                            inactive_popup.func.setContent(response.message);
                            inactive_popup.func.open();
                            props.data.Authentication.func.updateEvent(event.id);
                        }
                    });
                }
                return true;
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return <Modal
        className="InactivePopup"
        size="lg"
        show={inactive_popup.status}
        onHide={inactive_popup.func.close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Header className="header" closeButton>
            <h4 className="mb-0">{t("inactive_popup.title")}</h4>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{ __html: inactive_popup.content }}>
        </Modal.Body>
    </Modal>
}