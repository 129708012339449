import React, { useState } from "react";
import { t } from "i18next"
import Loading from "../../../Loading/Loading";
import { Container } from "react-bootstrap";
import refresh_logo from "../../../../Assets/refresh.png";

export const RecentTransactions = (props) => {
    const auth = props.data.Authentication;
    const [transactions, setTransactions] = useState(null);
    const helpers = props.data.helpers;
    const TransactionCanvas = props.data.TransactionCanvas;

    const fetchData = async () => {
        setTransactions(await auth.func.getTransactions());
    }

    const openTransactionCanvas = async (e) => {
        var transaction = JSON.parse(e.currentTarget.dataset.transaction);
        TransactionCanvas.setData(transaction.id);
        TransactionCanvas.func.open();
    }

    const getTransactionStatusBadge = (status) => {
        var status_string = helpers.getTransactionStatus(status);
        if (status_string === "in_progress") {
            return <div className="ms-2 badge badge-status bg-warning">{t(status_string)}</div>;
        }
        if (status_string === "success") {
            return <div className="ms-2 badge badge-status bg-success">{t(status_string)}</div>;
        }
        if (status_string === "reject") {
            return <div className="ms-2 badge badge-status bg-danger">{t(status_string)}</div>;
        }
        if (status_string === "processing") {
            return <div className="ms-2 badge badge-status bg-success">{t(status_string)}</div>;
        }
        if (status_string === "cancel") {
            return <div className="ms-2 badge badge-status bg-danger">{t(status_string)}</div>;
        }
    }

    const refreshTransactions = () => {
        setTransactions(null);
    }

    const getTransactions = () => {
        if (transactions === null) {
            fetchData();
            return <Loading />;
        }

        if (transactions.length === 0) {
            return <div className="list-empty">
                <span className="d-flex" height="300px">{t('cashier.recent_transactions.empty')}</span>
            </div>;
        }

        return Object.entries(transactions).map(function (value, key) {
            const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
            var current_date = new Date(value[0]);
            return <div key={key}>
                <div className="list-date">
                    <Container>
                        <h6 className="font-bolder mb-0 py-3">{current_date.getDate()} {t(months[current_date.getMonth()])} {current_date.getFullYear()}</h6>
                    </Container>
                </div>
                {Object.values(value[1]).map(function (transaction, transaction_key) {
                    return <div key={transaction_key} data-transaction={JSON.stringify(transaction)} onClick={openTransactionCanvas} >
                        <div className="list-item" >
                            <div className="list-col">
                                <div className="type">
                                    <span className="font-bolder">{t(helpers.getTransactionType(transaction.type))}</span>
                                    {getTransactionStatusBadge(transaction.status)}
                                </div>
                                <span className="wallet">{t(helpers.getWalletType(parseInt(transaction.wallet)))}</span>
                            </div>
                            <div className={helpers.getTransactionStatus(transaction.status) === "success" ? "list-col text-success" : "list-col"}>
                                <span className="currency">{transaction.currency}</span>
                                <span className="amount">{transaction.amount}</span>
                                <span className="enter text-dark">&#8250;</span>
                            </div>
                        </div>
                    </div>
                })}
            </div>;
        });
    }

    return <>
        <div className="list">
            <Container>
                <div className="list-title pb-3">
                    <h5 className="font-boldest mb-0">{t('cashier.recent_transactions')}</h5>
                    <img className={!transactions ? "icon refresh-icon loading" : "icon refresh-icon"} src={refresh_logo} alt="refresh" onClick={refreshTransactions} />
                </div>
            </Container>
            {getTransactions()}
        </div>
    </>
}