import * as qs from 'query-string';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LoginModalHook() {
    const navigate = useNavigate();
    const [loginModal, setLoginModal] = useState(false);
    const open = () => setLoginModal(true);
    const close = () => {
        const parsed_query = qs.parse(window.location.search);
        if (parsed_query.modal === "loginModal") {
            navigate('/');
        }
        setLoginModal(false);
    };

    useEffect(() => {
        const parsed_query = qs.parse(window.location.search);
        if (parsed_query.modal === "loginModal" || parsed_query.modal === "depositModal") {
            open();
        }
    });

    return {
        status: loginModal,
        func: {
            open,
            close,
        },
    };
}