import React, { useEffect, useState } from "react";
import { Offcanvas, CloseButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserService } from "../../Models/User/UserService";
import Loading from "../Loading/Loading";
import "./MessageCanvas.css";
import { MessageDescription } from "./MessageDescription";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'


export const MessageCanvas = (props) => {
    const { t } = useTranslation();
    const [active, setActive] = useState("notification");
    const [data, setData] = useState({
        data: [],
        isFetching: true
    });

    useEffect(() => {
        setActive("notification");
    }, []);

    useEffect(() => {
        setData({ data: [], isFetching: true });
        if (active === "notification") {
            UserService.notifications().then(response => {
                if (response.status) {
                    setData({ data: response.data, isFetching: false });
                } else {
                    setData({ data: [], isFetching: false });
                }
            });
        }
        if (active === "inbox") {
            UserService.inboxes().then(response => {
                if (response.status) {
                    setData({ data: response.data, isFetching: false });
                } else {
                    setData({ data: [], isFetching: false });
                }
            });
        }
    }, [props.data.Message.status,active]);

    useEffect(() => {
        if (props.data.Message.status === true) {
            UserService.notifications_views();
            props.data.Authentication.func.get();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.Message.status]);

    const close = () => {
        props.data.Message.func.close();
    }

    const compose = () => {
        props.data.ComposeCanvas.func.open();
    }


    const getAuth = () => {
        return props.data.Authentication;
    }

    return <Offcanvas
        id="Message"
        aria-labelledby="MessageLabel"
        placement="end"
        backdrop="true"
        className="w-100"
        show={props.data.Message.status}
        onHide={props.data.Message.func.close}
    >
        <Offcanvas.Body>
            <Offcanvas.Title className="text-center mb-0 p-3">
                <CloseButton onClick={close} />
                <h3 className="fw-bold">{t('message.title')}</h3>
            </Offcanvas.Title>
            <div className="nav-container">
                <div className="nav d-flex">
                    <div className={active === "notification" ? "nav-tab active" : "nav-tab"} onClick={() => setActive("notification")}>
                        {t('message.notification')}
                        {getAuth().user && getAuth().user.notification_count > 0 ? <div className="count">
                            <span>{getAuth().user.notification_count}</span>
                        </div> : <></>}
                    </div>
                    <div className={active === "inbox" ? "nav-tab active" : "nav-tab"} onClick={() => setActive("inbox")}>
                        {t('message.inbox')}
                        {getAuth().user && getAuth().user.inbox_count > 0 ? <div className="count">
                            <span>{getAuth().user.inbox_count}</span>
                        </div> : <></>}

                    </div>
                </div>
                {data.isFetching ? (
                    <Loading />
                ) : (
                    Object.entries(data.data).map(function (value, key) {
                        const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
                        var current_date = new Date(value[0]);
                        if (active === "notification") {
                            return <div className="notification" key={key}>
                                <h6 className="notification-date">{current_date.getDate()} {t(months[current_date.getMonth()])} {current_date.getFullYear()}</h6>
                                {Object.values(value[1]).map(function (notification, notification_key) {
                                    return <MessageDescription active={active} data={props.data} key={notification_key} notification={notification} />;
                                })}
                            </div>
                        }
                        if (active === "inbox") {
                            return <div className="notification" key={key}>
                                <h6 className="notification-date">{current_date.getDate()} {t(months[current_date.getMonth()])} {current_date.getFullYear()}</h6>
                                {Object.values(value[1]).map(function (notification, notification_key) {
                                    return <MessageDescription active={active} data={props.data} key={notification_key} notification={notification} />;
                                })}
                            </div>
                        }

                        return <></>;
                    })
                )}
            </div>


            {(active === "inbox") ? <>
                <button className="btn btn-circle btn-primary btn-floating-circle" onClick={compose}>
                    <FontAwesomeIcon icon={solid('pen')} />
                </button>
            </> : <></>}
        </Offcanvas.Body>
    </Offcanvas>;
};


export default MessageCanvas;