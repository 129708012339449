import { Helmet } from "react-helmet";
import { Badge, Button, Col, Container, ProgressBar, Row } from "react-bootstrap";
import "./Reward.css";
import { t } from "i18next";
import Loading from "../../Components/Loading/Loading";
import { Link } from "react-router-dom";
import Select from "react-select";
import * as moment from 'moment'
import React, { useState, useEffect, useRef } from "react";
import benefits_logo from "../../Assets/Reward/benefits-banner.png";
import { UserService } from "../../Models/User/UserService";
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'


function Reward(props) {
    const next_logo = "/assets/icons/next.png";
    const prev_logo = "/assets/icons/prev.png";
    const seo = props.data.Seo.data;
    const swiperRef = useRef(null);
    const helpers = props.data.helpers;
    const user = props.data.Authentication.user;
    const toast = props.data.toast;

    const [userWallets, setUserWallets] = useState([]);
    const [redeemWallet, setRedeemWallet] = useState(null);
    const [isSubmit, setIsSubmit] = useState(false);

    const [swiper, setSwiper] = useState(null)

    const getAuth = () => {
        return props.data.Authentication;
    };

    const isAuth = () => {
        return getAuth().func.isLogin();
    };

    useEffect(() => {
        if (isAuth() && user) {
            setUserWallets(user.user_wallets.map(wallet => {
                return {
                    value: wallet.id,
                    label: t(helpers.getWalletType(wallet.type))
                };
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user && user.level && swiper) {
            if (window.innerWidth >= 768) {
                swiper.slideTo(user.level - 2)
            } else if (window.innerWidth < 768) {
                swiper.slideTo(user.level - 1)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [swiper, window.innerWidth]);

    const handleInputs = name => event => {
        if (name === "wallet") {
            setRedeemWallet(event.value);
        }
    }

    const redeem = (e) => {
        setIsSubmit(true);
        e.preventDefault();

        UserService.redeem_points({ wallet: redeemWallet }).then((response) => {
            setIsSubmit(false);
            if (response.status) {
                toast.success(t(response.message));
                props.data.Authentication.func.get();
            } else {
                toast.error(t(response.message));

            }
        }).catch((res) => {
            setIsSubmit(false);
        })
    }

    if (!isAuth() || !user) {
        return <div className="benefits container">
            <div className="benefits-header">
                <img className="benefits-header-background" src={benefits_logo} alt="vip_level_benefits" />
                <span className="benefits-header-title">VIP LEVEL BENEFITS</span>
            </div>
            <div className="benefits-content">
                <Swiper
                    ref={swiperRef}
                    loop={true}
                    loopedSlides={100}
                    modules={[Navigation]}
                    spaceBetween={0}
                    breakpoints={{
                        425: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                    }}
                    onSwiper={setSwiper}
                >
                    <SwiperSlide key={1}>
                        <div className="rank-details">
                            <div className="rank-wrapper">
                                <div className="rank-name">{t("blue")}</div>
                                <div className="rank-points">0 - 1999</div>
                                <div className="rank-points">{t("reward.carousel.reward_points")}</div>
                                <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 1 })}`} alt={t("blue")} /></div>
                                <ol className="rank-lists">
                                    <li><span>{t("reward.carousel.slot_rebate", { percentage: "0.6%" })}</span></li>
                                    <li><span>{t("reward.carousel.sportsbook_rebate", { percentage: "0.5%" })}</span></li>
                                    <li><span>{t("reward.carousel.live_dealer_rebate", { percentage: "0.4%" })}</span></li>
                                    <li><span>{t("reward.carousel.welcome_bonuses")}</span></li>
                                    <li><span>{t("reward.carousel.24hours_customers_service")}</span></li>
                                </ol>
                                <Link className="btn btn-default" to="/reward-points-points-redemption">
                                    {t('reward.carousel.see_all_details')}
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={2}>
                        <div className="rank-details">
                            <div className="rank-wrapper">
                                <div className="rank-name">{t("silver")}</div>
                                <div className="rank-points">2,000 - 4,999</div>
                                <div className="rank-points">{t("reward.carousel.reward_points")}</div>
                                <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 2 })}`} alt={t("silver")} /></div>
                                <ol className="rank-lists">
                                    <li><span>{t("reward.carousel.slot_rebate", { percentage: "0.7%" })}</span></li>
                                    <li><span>{t("reward.carousel.sportsbook_rebate", { percentage: "0.6%" })}</span></li>
                                    <li><span>{t("reward.carousel.live_dealer_rebate", { percentage: "0.5%" })}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_promotions")}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_games")}</span></li>
                                    <li><span>{t("reward.carousel.birthday_bonus")}</span></li>
                                    <li><span>{t("reward.carousel.surprise_bonus")}</span></li>
                                    <li><span>{t("reward.carousel.24hours_customers_service")}</span></li>
                                </ol>
                                <Link className="btn btn-default" to="/reward-points-points-redemption">
                                    {t('reward.carousel.see_all_details')}
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={3}>
                        <div className="rank-details">
                            <div className="rank-wrapper">
                                <div className="rank-name">{t("gold")}</div>
                                <div className="rank-points">5,000 - 11,999</div>
                                <div className="rank-points">{t("reward.carousel.reward_points")}</div>
                                <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 3 })}`} alt={t("gold")} /></div>
                                <ol className="rank-lists">
                                    <li><span>{t("reward.carousel.slot_rebate", { percentage: "0.8%" })}</span></li>
                                    <li><span>{t("reward.carousel.sportsbook_rebate", { percentage: "0.7%" })}</span></li>
                                    <li><span>{t("reward.carousel.live_dealer_rebate", { percentage: "0.6%" })}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_promotions")}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_games")}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_deposit_methods")}</span></li>
                                    <li><span>{t("reward.carousel.birthday_bonus")}</span></li>
                                    <li><span>{t("reward.carousel.surprise_bonus")}</span></li>
                                    <li><span>{t("reward.carousel.vip_gifts")}</span></li>
                                    <li><span>{t("reward.carousel.higher_table_limites")}</span></li>
                                    <li><span>{t("reward.carousel.special_access_to_vip_tables")}</span></li>
                                    <li><span>{t("reward.carousel.personalised_vip_offers")}</span></li>
                                    <li><span>{t("reward.carousel.24hours_priorities_customers_service")}</span></li>
                                </ol>
                                <Link className="btn btn-default" to="/reward-points-points-redemption">
                                    {t('reward.carousel.see_all_details')}
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={4}>
                        <div className="rank-details">
                            <div className="rank-wrapper">
                                <div className="rank-name">{t("platinum")}</div>
                                <div className="rank-points">&gt; 12,000</div>
                                <div className="rank-points">{t("reward.carousel.reward_points")}</div>
                                <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 4 })}`} alt={t("platinum")} /></div>
                                <ol className="rank-lists">
                                    <li><span>{t("reward.carousel.slot_rebate", { percentage: "1.0%" })}</span></li>
                                    <li><span>{t("reward.carousel.sportsbook_rebate", { percentage: "0.9%" })}</span></li>
                                    <li><span>{t("reward.carousel.live_dealer_rebate", { percentage: "0.7%" })}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_promotions")}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_games")}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_deposit_methods")}</span></li>
                                    <li><span>{t("reward.carousel.birthday_bonus")}</span></li>
                                    <li><span>{t("reward.carousel.surprise_bonus")}</span></li>
                                    <li><span>{t("reward.carousel.vip_gifts")}</span></li>
                                    <li><span>{t("reward.carousel.higher_table_limites")}</span></li>
                                    <li><span>{t("reward.carousel.special_access_to_vip_tables")}</span></li>
                                    <li><span>{t("reward.carousel.personalised_vip_offers")}</span></li>
                                    <li><span>{t("reward.carousel.fast_payout")}</span></li>
                                    <li><span>{t("reward.carousel.24hours_priorities_customers_service")}</span></li>
                                </ol>
                                <Link className="btn btn-default" to="/reward-points-points-redemption">
                                    {t('reward.carousel.see_all_details')}
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide key={5}>
                        <div className="rank-details">
                            <div className="rank-wrapper">
                                <div className="rank-name">{t("legend")}</div>
                                <div className="rank-points">{t("reward.carousel.invitation_only")}</div>
                                <div className="rank-points"> </div>
                                <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 5 })}`} alt={t("legend")} /></div>
                                <ol className="rank-lists">
                                    <li><span>{t("reward.carousel.slot_rebate", { percentage: "1.2%" })}</span></li>
                                    <li><span>{t("reward.carousel.sportsbook_rebate", { percentage: "1.0%" })}</span></li>
                                    <li><span>{t("reward.carousel.live_dealer_rebate", { percentage: "0.9%" })}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_promotions")}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_games")}</span></li>
                                    <li><span>{t("reward.carousel.exclusive_deposit_methods")}</span></li>
                                    <li><span>{t("reward.carousel.birthday_bonus")}</span></li>
                                    <li><span>{t("reward.carousel.surprise_bonus")}</span></li>
                                    <li><span>{t("reward.carousel.vip_gifts")}</span></li>
                                    <li><span>{t("reward.carousel.higher_table_limites")}</span></li>
                                    <li><span>{t("reward.carousel.special_access_to_vip_tables")}</span></li>
                                    <li><span>{t("reward.carousel.personalised_vip_offers")}</span></li>
                                    <li><span>{t("reward.carousel.fast_payout")}</span></li>
                                    <li><span>{t("reward.carousel.24hours_priorities_customers_service")}</span></li>
                                </ol>
                                <Link className="btn btn-default" to="/reward-points-points-redemption">
                                    {t('reward.carousel.see_all_details')}
                                </Link>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                <div className='swiper-navigations reward'>
                    <div className='swiper-navigations-wrapper'>
                        <div className="swiper-navigation swiper-slider-prev" onClick={() => swiperRef.current.swiper.slidePrev()} >
                            <img className="carousel-icon" src={"/assets/icons/prev.png"} alt="prev" />
                        </div>
                        <div className="swiper-navigation swiper-slider-next" onClick={() => swiperRef.current.swiper.slideNext()} >
                            <img className="carousel-icon" src={"/assets/icons/next.png"} alt="next" />
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    return (
        <div className={isAuth() ? "content logined" : "content"}>
            <>
                <Helmet>
                    <title> {seo ? helpers.t(seo.meta_title) : t('reward.title') + " | BD88"}</title>
                    <meta name="keywords" content={seo ? helpers.t(seo.meta_keyword) : ""} />
                    <meta name="description" content={seo ? helpers.t(seo.meta_description) : ""} />
                </Helmet>
                {
                    isAuth() ?
                        <>
                            <Container className="px-lg-4">
                                <Row className="align-items-center pt-3">
                                    <Col xs="3" md="2" lg="2" xl="1">
                                        <img src={`/assets/ranks/${helpers.getRankImage(user)}`} width="100%" alt={helpers.getRank(user)} />
                                    </Col>
                                    <Col xs="9" md="10" className="d-flex justify-content-between">
                                        <Row className="w-100">
                                            <Col xs="5" md="3" lg="2" className="rank-info pe-0">
                                                <span className="fs-small d-block font-bold text-grey">{t("reward.vip_level")}</span>
                                                <span className="fs-normal d-block font-bolder text-grey">{t(helpers.getRank(user))}</span>
                                            </Col>
                                            <Col xs="7" md="3" lg="3" className="point-info ps-0">
                                                <span className="fs-small d-block font-bold text-grey">{t("reward.current_reward_points")}</span>
                                                <span className="fs-normal d-block font-bolder text-grey">{t(user.point)}</span>
                                            </Col>
                                            <Col xs="12">
                                                <Button className="font-bold" variant="blue" size="xs" as={Link} to="/reward-points-points-redemption">{t('reward.view_benefit')}</Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row >
                            </Container>
                            <hr />
                            <Container className="px-lg-4">
                                <h5 className="font-boldest">{t("reward.this_month")}</h5>
                                <div className="position-relative my-5">
                                    {
                                        user.point_to_maintain > 0 && user.point_to_upgrade > 0 ? <Badge pill bg="blue" className="maintain-remain">
                                            {t('reward.to_remain_level', { rank: t(helpers.getRank(user)) })}
                                        </Badge> : <></>
                                    }

                                    <div className="d-flex align-items-center mt-3">
                                        <div className={user.point_to_upgrade === 0 ? "w-100" : "w-80"}>
                                            <ProgressBar className="maintain-progressbar" now={props.data.Authentication.func.getPercentageToUpgrade()} />

                                            <div className="requie_points">
                                                <div className="init_level_point">
                                                    {helpers.number_format(0)}
                                                </div>

                                                {user.point_to_maintain > 0 ? <div className={user.point_to_upgrade === 0 ? "maintain_level_point final" : "maintain_level_point"}>
                                                    {helpers.number_format(user.point_to_maintain, 0)}
                                                </div> : <></>}

                                                {user.point_to_upgrade > 0 ? <div className="upgrade_level_point">
                                                    {helpers.number_format(user.point_to_upgrade, 0)}
                                                </div> : <></>}
                                            </div>
                                        </div>

                                        <div className={user.point_to_upgrade === 0 ? "d-none" : "w-20 ms-3"} >
                                            <img className="reward-image" src={`/assets/ranks/${helpers.getNextRankImage(user)}`} width="100%" alt={helpers.getNextRank(user)} />
                                        </div>
                                    </div>
                                </div>

                                {user.point_to_upgrade > 0 || user.point_to_maintain > 0 ?
                                    <>
                                        <h6>{t("reward.notes")}</h6>
                                        <ol className="ps-3 text-grey fs-small">
                                            {user.point_to_upgrade > 0 ? <li> {t('reward.to_upgrade_level_desc', { point: user.point_to_upgrade - user.level_point, date: moment().endOf('month').format('DD/MM/YYYY'), rank: t(helpers.getNextRank(user)) })}</li> : <></>}
                                            {user.point_to_maintain > 0 ? <li> {t('reward.to_maintain_level_desc', { point: user.point_to_maintain - user.level_point, date: moment().endOf('month').format('DD/MM/YYYY'), rank: t(helpers.getRank(user)) })}</li> : <></>}
                                        </ol>
                                    </> : <></>
                                }

                            </Container>
                            <hr />
                            <Container className="px-lg-4">
                                <Row>
                                    <Col sm="12" md="6">
                                        <div className="reward-table">
                                            <div className="reward-table-row">
                                                <div className="w-50">
                                                    <span className="d-block font-bold fs-small">{t('reward.available_reward_points_to_convert')}</span>
                                                </div>
                                                <div className="w-50">
                                                    <span className="d-block font-bold text-center">
                                                        {helpers.number_format(user.point)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="reward-table-row">
                                                <div className="w-50">
                                                    <span className="d-block font-bold fs-small">{t('reward.reward_points_conversion_ratio')}</span>
                                                    <span className="d-block text-grey fs-smaller">{t('reward.reward_points.desc', { rate: user.point_exchange_rate, currency: user.currency })}</span>
                                                </div>
                                                <div className="w-50">
                                                    <span className="d-block font-bold text-center">
                                                        {helpers.number_format(user.point_exchange_rate)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="reward-table-row">
                                                <div className="w-50">
                                                    <span className="d-block font-bold fs-small">{t("reward.real_money_conversion")}</span>
                                                </div>
                                                <div className="w-50">
                                                    <span className="d-block font-bold text-center">{user.currency} {helpers.number_format(Math.floor(user.point / user.point_exchange_rate), 2)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <div className="reward-redeem">
                                            <div className="reward-redeem-header">
                                                {t("reward.redeem.title")}
                                            </div>
                                            <div className="reward-redeem-body">
                                                <Select
                                                    className="fs-small font-bold text-grey mb-3"
                                                    value={userWallets.filter(function (option) { return option.value === redeemWallet; })}
                                                    onChange={handleInputs("wallet")}
                                                    options={userWallets}
                                                    placeholder={t('reward.redeem.select_wallet')}
                                                    styles={helpers.reactSelectStyles()}
                                                    isSearchable={false}
                                                />
                                                <div className="text-center mb-2">
                                                    <Button
                                                        className="font-bold"
                                                        variant="blue"
                                                        disabled={isSubmit}
                                                        onClick={redeem}
                                                    >{t("reward.redeem.submit")}</Button>
                                                </div>

                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                            <hr />
                            <div className="benefits container">
                                <div className="benefits-header">
                                    <img className="benefits-header-background" src={benefits_logo} alt="vip_level_benefits" />
                                    <span className="benefits-header-title">VIP LEVEL BENEFITS</span>
                                </div>
                                <div className="benefits-content">
                                    <Swiper
                                        ref={swiperRef}
                                        loop={true}
                                        loopedSlides={100}
                                        modules={[Navigation]}
                                        spaceBetween={0}
                                        breakpoints={{
                                            425: {
                                                slidesPerView: 1,
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                        }}
                                        onSwiper={setSwiper}
                                    >
                                        <SwiperSlide key={1}>
                                            <div className="rank-details">

                                                {
                                                    parseInt(user.level) === 1 ? <div className="rank-title">
                                                        {t("reward.carousel.your_current_level")}
                                                    </div> : <></>
                                                }

                                                <div className="rank-wrapper">
                                                    <div className="rank-name">{t("blue")}</div>
                                                    <div className="rank-points">0 - 1999</div>
                                                    <div className="rank-points">{t("reward.carousel.reward_points")}</div>
                                                    <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 1 })}`} alt={t("blue")} /></div>
                                                    <ol className="rank-lists">
                                                        <li>{t("reward.carousel.slot_rebate", { percentage: "0.6%" })}</li>
                                                        <li>{t("reward.carousel.sportsbook_rebate", { percentage: "0.5%" })}</li>
                                                        <li>{t("reward.carousel.live_dealer_rebate", { percentage: "0.4%" })}</li>
                                                        <li>{t("reward.carousel.welcome_bonuses")}</li>
                                                        <li>{t("reward.carousel.24hours_customers_service")}</li>
                                                    </ol>
                                                    <Link className="btn btn-default" to="/reward-points-points-redemption">
                                                        {t('reward.carousel.see_all_details')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={2}>
                                            <div className="rank-details">

                                                {
                                                    parseInt(user.level) === 2 ? <div className="rank-title">
                                                        {t("reward.carousel.your_current_level")}
                                                    </div> : <></>
                                                }

                                                <div className="rank-wrapper">
                                                    <div className="rank-name">{t("silver")}</div>
                                                    <div className="rank-points">2,000 - 4,999</div>
                                                    <div className="rank-points">{t("reward.carousel.reward_points")}</div>
                                                    <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 2 })}`} alt={t("silver")} /></div>
                                                    <ol className="rank-lists">
                                                        <li>{t("reward.carousel.slot_rebate", { percentage: "0.7%" })}</li>
                                                        <li>{t("reward.carousel.sportsbook_rebate", { percentage: "0.6%" })}</li>
                                                        <li>{t("reward.carousel.live_dealer_rebate", { percentage: "0.5%" })}</li>
                                                        <li>{t("reward.carousel.exclusive_promotions")}</li>
                                                        <li>{t("reward.carousel.exclusive_games")}</li>
                                                        <li>{t("reward.carousel.birthday_bonus")}</li>
                                                        <li>{t("reward.carousel.surprise_bonus")}</li>
                                                        <li>{t("reward.carousel.24hours_customers_service")}</li>
                                                    </ol>
                                                    <Link className="btn btn-default" to="/reward-points-points-redemption">
                                                        {t('reward.carousel.see_all_details')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={3}>
                                            <div className="rank-details">

                                                {
                                                    parseInt(user.level) === 3 ? <div className="rank-title">
                                                        {t("reward.carousel.your_current_level")}
                                                    </div> : <></>
                                                }

                                                <div className="rank-wrapper">
                                                    <div className="rank-name">{t("gold")}</div>
                                                    <div className="rank-points">5,000 - 11,999</div>
                                                    <div className="rank-points">{t("reward.carousel.reward_points")}</div>
                                                    <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 3 })}`} alt={t("gold")} /></div>
                                                    <ol className="rank-lists">
                                                        <li>{t("reward.carousel.slot_rebate", { percentage: "0.8%" })}</li>
                                                        <li>{t("reward.carousel.sportsbook_rebate", { percentage: "0.7%" })}</li>
                                                        <li>{t("reward.carousel.live_dealer_rebate", { percentage: "0.6%" })}</li>
                                                        <li>{t("reward.carousel.exclusive_promotions")}</li>
                                                        <li>{t("reward.carousel.exclusive_games")}</li>
                                                        <li>{t("reward.carousel.exclusive_deposit_methods")}</li>
                                                        <li>{t("reward.carousel.birthday_bonus")}</li>
                                                        <li>{t("reward.carousel.surprise_bonus")}</li>
                                                        <li>{t("reward.carousel.vip_gifts")}</li>
                                                        <li>{t("reward.carousel.higher_table_limites")}</li>
                                                        <li>{t("reward.carousel.special_access_to_vip_tables")}</li>
                                                        <li>{t("reward.carousel.personalised_vip_offers")}</li>
                                                        <li>{t("reward.carousel.24hours_priorities_customers_service")}</li>
                                                    </ol>
                                                    <Link className="btn btn-default" to="/reward-points-points-redemption">
                                                        {t('reward.carousel.see_all_details')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={4}>
                                            <div className="rank-details">

                                                {
                                                    parseInt(user.level) === 4 ? <div className="rank-title">
                                                        {t("reward.carousel.your_current_level")}
                                                    </div> : <></>
                                                }

                                                <div className="rank-wrapper">
                                                    <div className="rank-name">{t("platinum")}</div>
                                                    <div className="rank-points">&gt; 12,000</div>
                                                    <div className="rank-points">{t("reward.carousel.reward_points")}</div>
                                                    <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 4 })}`} alt={t("platinum")} /></div>
                                                    <ol className="rank-lists">
                                                        <li>{t("reward.carousel.slot_rebate", { percentage: "1.0%" })}</li>
                                                        <li>{t("reward.carousel.sportsbook_rebate", { percentage: "0.9%" })}</li>
                                                        <li>{t("reward.carousel.live_dealer_rebate", { percentage: "0.7%" })}</li>
                                                        <li>{t("reward.carousel.exclusive_promotions")}</li>
                                                        <li>{t("reward.carousel.exclusive_games")}</li>
                                                        <li>{t("reward.carousel.exclusive_deposit_methods")}</li>
                                                        <li>{t("reward.carousel.birthday_bonus")}</li>
                                                        <li>{t("reward.carousel.surprise_bonus")}</li>
                                                        <li>{t("reward.carousel.vip_gifts")}</li>
                                                        <li>{t("reward.carousel.higher_table_limites")}</li>
                                                        <li>{t("reward.carousel.special_access_to_vip_tables")}</li>
                                                        <li>{t("reward.carousel.personalised_vip_offers")}</li>
                                                        <li>{t("reward.carousel.fast_payout")}</li>
                                                        <li>{t("reward.carousel.24hours_priorities_customers_service")}</li>
                                                    </ol>
                                                    <Link className="btn btn-default" to="/reward-points-points-redemption">
                                                        {t('reward.carousel.see_all_details')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide key={5}>

                                            <div className="rank-details">

                                                {
                                                    parseInt(user.level) === 5 ? <div className="rank-title">
                                                        {t("reward.carousel.your_current_level")}
                                                    </div> : <></>
                                                }

                                                <div className="rank-wrapper">
                                                    <div className="rank-name">{t("legend")}</div>
                                                    <div className="rank-points">{t("reward.carousel.invitation_only")}</div>
                                                    <div className="rank-points"> </div>
                                                    <div className="rank-image"><img src={`/assets/ranks/${helpers.getRankImage({ level: 5 })}`} alt={t("legend")} /></div>
                                                    <ol className="rank-lists">
                                                        <li>{t("reward.carousel.slot_rebate", { percentage: "1.2%" })}</li>
                                                        <li>{t("reward.carousel.sportsbook_rebate", { percentage: "1.0%" })}</li>
                                                        <li>{t("reward.carousel.live_dealer_rebate", { percentage: "0.9%" })}</li>
                                                        <li>{t("reward.carousel.exclusive_promotions")}</li>
                                                        <li>{t("reward.carousel.exclusive_games")}</li>
                                                        <li>{t("reward.carousel.exclusive_deposit_methods")}</li>
                                                        <li>{t("reward.carousel.birthday_bonus")}</li>
                                                        <li>{t("reward.carousel.surprise_bonus")}</li>
                                                        <li>{t("reward.carousel.vip_gifts")}</li>
                                                        <li>{t("reward.carousel.higher_table_limites")}</li>
                                                        <li>{t("reward.carousel.special_access_to_vip_tables")}</li>
                                                        <li>{t("reward.carousel.personalised_vip_offers")}</li>
                                                        <li>{t("reward.carousel.fast_payout")}</li>
                                                        <li>{t("reward.carousel.24hours_priorities_customers_service")}</li>
                                                    </ol>
                                                    <Link className="btn btn-default" to="/reward-points-points-redemption">
                                                        {t('reward.carousel.see_all_details')}
                                                    </Link>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                    <div className="swiper-navigation swiper-prev" onClick={() => swiperRef.current.swiper.slidePrev()} >
                                        <img className="carousel-icon" src={prev_logo} alt="prev" />
                                    </div>
                                    <div className="swiper-navigation swiper-next" onClick={() => swiperRef.current.swiper.slideNext()} >
                                        <img className="carousel-icon" src={next_logo} alt="next" />
                                    </div>
                                </div>
                            </div>
                        </>
                        : <Loading />
                }
            </>
        </div>
    );
}

export default Reward;
