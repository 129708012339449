import { useState } from "react";
import { PageService } from "./PageService";

export default function PromoHook() {
    const [data, setData] = useState(null);

    const getData = async () => {
        PageService.promotions({}).then(response => {
            if (response.status) {
                var category_order = ["highlight", "slots", "live_dealer", "sportsbook", 'aviator'];
                setData(Object.entries(response.data).sort((a, b) => {
                    return category_order.indexOf(a[0]) - category_order.indexOf(b[0]);
                }));
            }
        });
    }

    return {
        data: data,
        getData: getData,
    };
}