import React, { useEffect, useState, useRef } from "react";
import { t } from "i18next";
import "./Dropdown.css";

export const Dropdown = (props) => {
    const dropdownRef = useRef(null);
    const { options, onChange, value, direction, status, onReset, readOnly } = props;
    const [open, setOpen] = useState(false);
    const name = options.filter(function (option) { return option.value === value; })[0] ? options.filter(function (option) { return option.value === value; })[0].name : t("withdrawal.please_select");

    const close = () => {
        setOpen(false)
    };
    const expand = (e) => {
        if (!readOnly && e.type === "click") {
            setOpen(!open)
        }
    };

    const triggerChange = (v) => {
        close();
        onChange(v)
    }

    useEffect(() => {
        if (status) {
            dropdownRef.current.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

    return <>

        {onReset ? <>
            <span className="dropdown-reset" onClick={onReset} >{t("Reset")}</span>
        </> : <></>}
        <div ref={dropdownRef} className="dropdown-control" tabIndex={1} onFocus={expand} onBlur={close}>
            <div className="dropdown-value" onClick={expand}>
                <div className="dropdown-singleValue">{name}</div>
            </div>
            {!readOnly ? <>
                <div className="dropdown-indicator" onClick={expand}>
                    <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></svg>
                </div>
            </> : <></>}

            {open ? <>
                <div className="dropdown-options">
                    {options.map((v, i) => {
                        return <div className={v.value === value ? "dropdown-option selected " + direction : "dropdown-option " + direction} key={i} onClick={() => triggerChange(v)}>
                            {v.image ? <>
                                <div className={v.label ? "dropdown-option-image max-width-32px" : "dropdown-option-image w-100"}>
                                    <img src={v.image} alt={v.image}  />
                                </div>
                            </> : <></>}
                            {v.label ? <>
                                <div className="dropdown-option-label">
                                    {v.label}
                                </div>
                            </> : <></>}
                            {v.custom ? <>
                                <div className="dropdown-option-custom">
                                    {v.custom}
                                </div>
                            </> : <></>}
                        </div>
                    })}
                </div>
            </> : <></>}
        </div>
    </>;
}