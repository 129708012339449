import { Card, Container, Modal } from "react-bootstrap";
import { t } from "i18next";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import { WalletService } from "../../../Models/User/WalletService";
import moment from "moment";
import i18n from "../../../Scripts/i18n";

export const Gamelogs = (props) => {
    require("moment/min/locales.min");
    moment.locale(i18n.language);
    const CashierModal = props.data.CashierModal;
    const helpers = props.data.helpers;
    const [interval, setInterval] = useState({ value: "1440", label: t("24 Hours") });
    const [data, setData] = useState([]);

    useEffect(() => {
        WalletService.gamelogs({ interval: interval.value }).then((response) => {
            setData(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interval])

    return <>
        <Modal.Header closeButton>
            <button type="button" className="btn-back" aria-label="Back" onClick={CashierModal.func.wallet}></button>
            <Container className="text-center">
                <span className="title">{t('gamelogs')}</span>
            </Container>
            <button type="button" className="btn-close" aria-label="Close" onClick={CashierModal.func.close}></button>
        </Modal.Header>
        <Modal.Body className="p-0">
            <Container>
                <Card className="mb-3">
                    <Card.Body>

                        <Select
                            className="fs-small font-bold text-grey"
                            value={interval}
                            onChange={(e) => { setInterval(e) }}
                            options={[
                                {
                                    value: "1440",
                                    label: t("24 Hours"),
                                },
                                {
                                    value: "4320",
                                    label: t("3 Days"),
                                },
                                {
                                    value: "10080",
                                    label: t("7 Days"),
                                },
                                {
                                    value: "43200",
                                    label: t("30 Days"),
                                }
                            ]}
                            placeholder={t('interval')}
                            styles={helpers.reactSelectStyles()}
                            isSearchable={false}
                        >
                        </Select>

                        <div className="table-container mt-3">
                            <table className="table table-sm bg-white mb-0 table-bordered text-center fs-smallest">
                                <thead>
                                    <tr>
                                        <th>{t('Datetime')}</th>
                                        <th>{t('Product')}</th>
                                        <th>{t('Game')}</th>
                                        <th>{t('Winlose')}</th>
                                        <th>{t('Status')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map(value => {
                                            return <tr key={value.id}>
                                                <td>
                                                    {moment(value.created_at).format('YYYY-MM-DD')}<br></br>
                                                    {moment(value.created_at).format('HH:mm:ss')}
                                                </td>
                                                <td>
                                                    {value.product_name}
                                                </td>
                                                <td>
                                                    {value.game_name}
                                                </td>
                                                <td>
                                                    {value.winlose}
                                                </td>
                                                <td>
                                                    {value.status}
                                                </td>
                                            </tr>;
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </Modal.Body>
    </>;
}