import { useTranslation } from 'react-i18next';
import PromoCard from './PromoCard';
import React, { useState } from 'react';

function PromoCategory(props) {
    const { t } = useTranslation();
    const [index, setIndex] = useState("all");
    const promotion_category = props.promotion_category;

    return <>
        <div className='nav promotion-nav'>
            {
                promotion_category.map(category =>
                    <div key={category[0]} className={index === category[0] ? 'nav-link active ' : 'nav-link'} onClick={() => setIndex(category[0])}>
                        <label className="promotion-label">{t(category[0])}</label>
                    </div>
                )
            }
        </div>

        <div className='promotion-list' >
            {
                promotion_category.filter(category => category[0] === index).map(category => {
                    return category[1].map(value => { return <PromoCard data={props.data} key={value.slug} value={value} /> })
                })
            }
        </div>
    </>;
}

export default PromoCategory;
