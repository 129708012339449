import { t } from "i18next";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import "./ProfileBadge.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export const ProfileBadge = (props) => {
    const close = props.close ?? function () {
        props.data.CashierModal.func.close();
        props.data.MobileSidebar.func.close();
    };
    const user = props.data.Authentication.user;
    const helpers = props.data.helpers;
    const [isLoading, setIsLoading] = useState(false);

    const fetch_balance = () => {
        setIsLoading(true);
        props.data.Authentication.func.get().then(function () {
            setIsLoading(false);
        });
    };

    if (props.data.isMobile) {
        return <Row className="profile-badge">
            <Col xs="3" md="2">
                <img src={`/assets/ranks/${helpers.getRankImage(user)}`} width="100%" alt={helpers.getRank(user)} />
            </Col>
            <Col xs="9" md="10">
                <div className="user-info">
                    <b className="username">{user.username}</b>
                </div>
                <div className="point-info">
                    {t('user-point', { point: user.point, count: user.point > 0 ? 2 : 1 })}&nbsp;
                    <Link to="/rewards" className="cursor-pointer text-decoration-underline" onClick={() => close()}>{t("sidebar.redeem")}</Link>
                </div>
                <div className="balance-info" onClick={!isLoading ? fetch_balance : null}>
                    {isLoading ? <span className="text-dark">{t('loading')}</span> : t('user-balance', { currency: user.currency, balance: helpers.number_format(user.balance, 2) })}&nbsp;
                    <FontAwesomeIcon className={isLoading ? "text-grey spin active" : "text-grey"} icon={solid('refresh')} />
                </div>
            </Col>
        </Row>;
    }

    return <Row className="profile-badge">
        <Col xs="4" className="text-center justify-content-center">
            <img className="image" src={`/assets/ranks/${helpers.getRankImage(user)}`} width="100%" alt={helpers.getRank(user)} />
        </Col>
        <Col xs="8">
            <div className="user-info">
                <b className="username">{user.username}</b>
            </div>
            <div className="point-info">
                {t('user-point', { point: user.point, count: user.point > 0 ? 2 : 1 })}&nbsp;
                <Link to="/rewards" className="cursor-pointer text-decoration-underline" onClick={() => close()}>{t("sidebar.redeem")}</Link>
            </div>
        </Col>
    </Row>;
}