
import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { PageService } from "../../Models/Page/PageService";
import { PromoImage } from "./Components/PromoImage";
import { Slider } from "./Components/Slider";
import "./Promotion.css";

export const Promotion = (props) => {
    const [data, setData] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        PageService.home({}).then(response => {
            if (response.status) {
                setData(response.data);
            }
        });
    }, []);

    const registerModal = () => {
        props.data.RegisterModal.func.open();
    }

    const isAuth = () => {
        return props.data.Authentication.func.isLogin();
    }

    const render_mobile = () => {
        if (!data) {
            return <>
                <div className="promotions placeholder-glow mb-3">
                    <div className="promotion">
                        <span className="placeholder promotion-image" style={{ width: "213px" }}></span>
                    </div>
                    <div className="promotion">
                        <span className="placeholder promotion-image" style={{ width: "213px" }}></span>
                    </div>
                    <div className="promotion">
                        <span className="placeholder promotion-image" style={{ width: "213px" }}></span>
                    </div>
                </div>
            </>
        }
        return <div className="promotions mb-3">
            {data ? data.map(data => <PromoImage data={props.data} key={data.sequence} banner={data} />) : null}
        </div>;
    }

    const render_desktop = () => {
        if (!data) {
            return <>
                <div className="container placeholder-glow px-lg-4">
                    <span className="placeholder promotion-image" style={{ width: "100%", height: "100%", aspectRatio: "71/23" }}></span>
                </div>
            </>
        }
        return <div className="container px-lg-4">
            <Slider data={props.data} banners={data} />
        </div>
    }

    if (isAuth()) {
        return <></>;
    }

    if (props.data.isMobile) {
        return <div className="promotions-container">
            <Container>
                {render_mobile()}

                <div className="mb-3">
                    <span className="promotion-description">
                        {t('promotion.description')}
                    </span>
                </div>

                <div className="d-block text-center mb-3">
                    <Button variant="primary" className="w-50 btn-bolder" onClick={registerModal}>{t('promotion.sign_up_now')}</Button>
                </div>
            </Container>
        </div>
    } else {
        return <>
            {render_desktop()}
            <Container className="px-lg-4">
                <div className="card special-card mt-3">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="promotion-description mb-0">
                                {t('promotion.description')}
                            </span>
                            <Button variant="primary" className="btn-bolder" onClick={registerModal}>{t('promotion.sign_up_now')}</Button>
                        </div>
                    </div>
                </div>
            </Container>
        </>;
    }
};


export default Promotion;