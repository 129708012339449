import { useState } from "react";

export default function FloatingIconHook() {
    const [status, setStatus] = useState((new Date()).getTime() > window.localStorage.getItem('floating_icon') ? true : false);
    const open = () => { setStatus(true) };
    const close = () => setStatus(false);

    return {
        status: status,
        func: {
            open,
            close
        },
    };
}