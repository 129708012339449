import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder from "../../Assets/placeholder-image.png";
import like from "../../Assets/Game/like.png";
import like_active from "../../Assets/Game/like_active.png";
import { GameService } from "../../Models/Game/GameService";
import React, { useState } from "react";
import { t } from "i18next";

function GameCard(props) {
    const helpers = props.data.helpers;
    const maintenances = props.data.MaintenanceModal.maintenances;
    const element = props.element;
    const isShow = props.isShow;
    const index = props.index;
    const [, forceUpdate] = useState(0);

    const like_game = (game, i) => {
        if (!props.data.Authentication.func.isLogin()) {
            return props.data.LoginModal.func.open();
        }
        GameService.like({ game_id: game.id }).then(response => {
            if (response && response.status) {
                element.like = response.like;
                forceUpdate(n => !n)
            }
        });
    }

    const launch = (element) => {
        if (!props.data.Authentication.func.isLogin()) {
            return props.data.LoginModal.func.open();
        }


        const launch = function () {
            props.data.Launcher.func.start(element);
        };


        var hasBalance = false;
        props.data.Authentication.wallets.map((v, i) => {
            if (v.balance > 0) {
                hasBalance = true;
            }
            return null;
        });

        const wallet = props.data.Authentication.wallets.filter((v, i) => {
            return v.type === element.type;
        })[0];

        if (wallet === undefined) {
            launch();
        } else if (wallet.current_balance === 0) {
            if (hasBalance) {
                props.data.CashierModal.func.quick_transfer(element.type, () => {
                    launch();
                })
            } else {
                props.data.CashierModal.func.deposit(element.type);
            }
        } else {
            launch();
        }
    }

    const openMaintenance = (element) => {
        props.data.MaintenanceModal.func.open({ name: element.provider }, maintenances[element.product_id]);
    }

    return <div key={element.id} className={isShow ? "game-card show" : "game-card"}>
        <LazyLoadImage
            className={!maintenances.hasOwnProperty(element.product_id) ? "image-square" : "image-square maintenance"}
            wrapperClassName="image-wrapper"
            src={element.image}
            placeholder={<span></span>}
            placeholderSrc={placeholder}
            effect="blur"
            delayTime={300}
            onClick={() => !maintenances.hasOwnProperty(element.product_id) ? launch(element) : openMaintenance(element)} />

        <div className="details-wrapper d-flex align-items-center justify-content-between mt-2 w-100">
            {element.is_hot === 1 ? <div className="badge game-badge hot">{t("game.hot")}</div> : <></>}
            {element.is_popular === 1 ? <div className="badge game-badge popular">{t("game.popular")}</div> : <></>}
            {element.is_new === 1 ? <div className="badge game-badge new">{t("game.new")}</div> : <></>}

            <div className="game-card-desc" onClick={() => !maintenances.hasOwnProperty(element.product_id) ? launch(element) : openMaintenance(element)} >
                <span className="d-block font-bolder fs-small white-space-nowrap">{helpers.t(element.name)}</span>
                <span className="d-block fs-smaller">{element.provider}</span>
            </div>
            
            <div className="game-card-action" onClick={() => like_game(element, index)}>
                {element.like ? <img className="like" src={like_active} alt={helpers.t(element.name)} /> : <img className="like" src={like} alt={helpers.t(element.name)} />}
            </div>
        </div>
    </div>;
}
export default GameCard;