import { useState } from "react";

export default function PaymentCanvasHook() {
    const [paymentCanvas, setPaymentCanvas] = useState(false);
    const [data, setData] = useState("https://www.google.com");

    const open = (url) => {
        setPaymentCanvas(true)
        setData(url);
    };
    const close = () => {
        setPaymentCanvas(false)
        setData("https://www.google.com");
    };

    return {
        status: paymentCanvas,
        data: data,
        func: {
            open,
            close
        },
    };
}