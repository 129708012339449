import { useState } from "react";

export default function MobileFooterHook() {
    const [page, setPage] = useState('home');

    return {
        status: page,
        func: {
            setPage: setPage,
        },
    };
}