import React from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";

export const FeaturedOnDesktop = (props) => {
    const { t } = useTranslation();
    const isAuth = () => {
        return props.data.Authentication.func.isLogin();
    }

    if (isAuth()) {
        return <></>;
    }

    if (props.data.isMobile) {
        return <></>;
    }

    return <div className={props.data.isMobile ? "d-none" : "container p-md-4 pb-2 mb-0"}>
        <div className={props.data.isMobile ? "" : "card special-card"}>
            <div className="card-body">
                <h5 className="pt-3 pt-md-0">{t("Featured On")}</h5>
                <div className="row align-items-center" style={{ minHeight: "150px" }}>
                    <div className="col-4 col-md-2 placeholder-glow">
                        <a href="https://www.bloomberg.com/press-releases/2023-02-03/bd88-launches-new-game-aviator" target="_blank" rel="noreferrer" >
                            <LazyLoadImage
                                className="w-100"
                                src={"https://bd88myr.com/storage/grapesjs/media/Bloomberg.png"}
                                placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                                effect="blur"
                                delayTime={300} />
                        </a>
                    </div>
                    <div className="col-4 col-md-2">
                        <a href="https://www.digitaljournal.com/pr/news/bd88-launches-new-game-aviator-" target="_blank" rel="noreferrer" >
                            <LazyLoadImage
                                className="w-100"
                                src={"https://bd88myr.com/storage/grapesjs/media/Digital-Journal.jpg"}
                                placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                                effect="blur"
                                delayTime={300} />
                        </a>
                    </div>
                    <div className="col-4 col-md-2">
                        <a href="https://www.finanznachrichten.de/nachrichten-2023-02/58220686-exsim-marketing-enterprise-bd88-launches-new-game-aviator-296.htm" target="_blank" rel="noreferrer" >
                            <LazyLoadImage
                                className="w-100"
                                src={"https://bd88myr.com/storage/grapesjs/media/Finanznachrichten.jpeg"}
                                placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                                effect="blur"
                                delayTime={300} />
                        </a>
                    </div>
                    <div className="col-4 col-md-2">
                        <a href="https://www.marketwatch.com/press-release/bd88-launches-new-game-aviator-2023-02-02?mod=mw_quote_news_seemore" target="_blank" rel="noreferrer" >
                            <LazyLoadImage
                                className="w-100"
                                src={"https://bd88myr.com/storage/grapesjs/media/marketwatch-logo.svg"}
                                placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                                effect="blur"
                                delayTime={300} />
                        </a>
                    </div>
                    <div className="col-4 col-md-2">
                        <a href="https://www.benzinga.com/pressreleases/23/02/30701499/bd88-launches-new-game-aviator" target="_blank" rel="noreferrer" >
                            <LazyLoadImage
                                className="w-100"
                                src={"https://bd88myr.com/storage/grapesjs/media/Benzinga-Logo.png"}
                                placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                                effect="blur"
                                delayTime={300} />
                        </a>
                    </div>
                    <div className="col-4 col-md-2">
                        <a href="https://www.theglobeandmail.com/investing/markets/markets-news/NewsFile/13869388/bd88-launches-new-game-aviator/" target="_blank" rel="noreferrer" >
                            <LazyLoadImage
                                className="w-100"
                                src={"https://bd88myr.com/storage/grapesjs/media/the%20globe%20and%20mail%20BD88.png"}
                                placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                                effect="blur"
                                delayTime={300} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
