import moment from "moment";
import React, { useEffect, useState } from "react";
import { Offcanvas, Container, CloseButton, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { UserService } from "../../Models/User/UserService";
import "./TransactionCanvas.css";

export const TransactionCanvas = (props) => {
    const { t } = useTranslation();
    const transaction = props.data.TransactionCanvas.data;
    const helpers = props.data.helpers;
    const [data, setData] = useState(null);

    const loadData = () => {
        setData(null);
        if (transaction) {
            UserService.transaction_getDetails({ id: transaction }).then(response => {
                if (response.status) {
                    setData(response.data)
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transaction]);

    const close = () => {
        props.data.TransactionCanvas.func.close()
    }

    const getAmount = () => {
        return renderCardBody(t('transaction_canvas.amount'), data.amount);
    }

    const getType = () => {
        if (data.type === 1) {
            return renderCardBody(t('transaction_canvas.type'), t('deposit'));
        }
        if (data.type === 2) {
            return renderCardBody(t('transaction_canvas.type'), t('withdrawal'));
        }
        if (data.type === 3) {
            return renderCardBody(t('transaction_canvas.type'), t('bonus'));
        }
        if (data.type === 5) {
            return renderCardBody(t('transaction_canvas.type'), t('transfer'));
        }
    }

    const getPayment = () => {
        if (data.type !== 1) {
            return <></>;
        }
        var result = null;
        if (data.payment_type) {
            result = t(helpers.getPaymentType(data.payment_type));
        } else {
            result = t('adjustment');
        }
        return renderCardBody(t('transaction_canvas.paid_by'), result);
    }

    const getBank = () => {
        if (data.type !== 2) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.bank'), data.user_bank);
    }

    const getBankAccount = () => {
        if (data.type !== 2) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.bank_account'), data.user_account);
    }

    const getBankHolderName = () => {
        if (data.type !== 2) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.bank_holder_name'), data.user_name);
    }

    const getWallet = () => {
        if (data.type === 5) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.wallet'), t(helpers.getWalletType(data.wallet)));
    }

    const getFromWallet = () => {
        if (data.type !== 5) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.from_wallet'), t(helpers.getWalletType(data.from_wallet)));
    }

    const getToWallet = () => {
        if (data.type !== 5) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.to_wallet'), t(helpers.getWalletType(data.to_wallet)));
    }

    const getDateTime = () => {
        return renderCardBody(t('transaction_canvas.datetime'), moment(data.created_at).format('YYYY-MM-DD HH:mm:ss'));
    }

    const getTransactionId = () => {
        return renderCardBody(t('transaction_canvas.transaction_id'), data.id);
    }

    const getStatus = () => {
        return renderCardBody(t('transaction_canvas.status'), t(helpers.getTransactionStatus(data.status)));
    }

    const getRemark = () => {
        return renderCardBody(t('transaction_canvas.remark'), data.remark);
    }

    const getTurnover = () => {
        if (data.type !== 3 || !data.bonus_turnover) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.turnover'), data.bonus_turnover);
    }

    const getRemainingTurnover = () => {
        if (data.type !== 3 || !data.bonus_remaining_turnover) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.remaining_turnover'), data.bonus_remaining_turnover);
    }

    const getExpiredAt = () => {
        if (data.type !== 3 || !data.bonus_expired_at) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.expired_at'), data.bonus_expired_at);
    }

    const getPromo = () => {
        if (data.type !== 3 || !data.promo_code) {
            return <></>;
        }
        return renderCardBody(t('transaction_canvas.promotion'), data.promo_code);
    }

    const renderCardBody = (title, value) => {
        return <Card.Body>
            <div className="d-flex justify-content-between">
                <div>{title}</div>
                <div className="card-item font-bold text-end">{value}</div>
            </div>
        </Card.Body>
    }

    if (!data) {
        return <></>;
    }
    return <Offcanvas
        id="TransactionCanvas"
        aria-labelledby="TransactionCanvasLabel"
        placement="end"
        backdrop="true"
        className="w-100"
        show={props.data.TransactionCanvas.status}
        onHide={props.data.TransactionCanvas.func.close}
    >
        <Offcanvas.Body>
            <Offcanvas.Title className="text-center mb-4">
                <CloseButton onClick={close} />
                <h3 className="fw-bold">{t('transaction_canvas.transaction_details')}</h3>
            </Offcanvas.Title>
            <Container>
                <h4>{t('transaction-canvas.details')}</h4>
                <Card className="bg-white">
                    {getType()}
                    {getAmount()}
                    {getPayment()}
                    {getBank()}
                    {getBankHolderName()}
                    {getBankAccount()}
                    {getPromo()}
                    {getWallet()}
                    {getFromWallet()}
                    {getToWallet()}
                    {getDateTime()}
                    {getStatus()}
                    {getRemark()}
                    {getTurnover()}
                    {getRemainingTurnover()}
                    {getExpiredAt()}
                    {getTransactionId()}
                </Card>
            </Container>
        </Offcanvas.Body>
    </Offcanvas>;
};


export default TransactionCanvas;