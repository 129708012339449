import { Container, Modal } from "react-bootstrap";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import "./style.css";
import { MissionService } from "../../Models/Missions/MissionService";
import Loading from "../../Components/Loading/Loading";

export const MissionModal = (props) => {
    const toast = props.data.toast;
    const helpers = props.data.helpers;
    const missionModal = props.data.MissionModal;
    const show = () => props.data.MobileSidebar.func.close();
    const [tab, setTab] = useState("active");
    const [missions, setMissions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState("home");
    const [currentMission, setCurrentMission] = useState(null);

    useEffect(() => {
        show();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [missionModal.status])

    useEffect(() => {
        setMissions([]);
        setIsLoading(true);
        loadMissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    const loadMissions = () => {
        MissionService.get({ tab: tab }).then(response => {
            setIsLoading(false);
            if (response.status) {
                setMissions(response.data);
            }
        });
    }

    const close = () => {
        setPage("home");
        props.data.MissionModal.func.close();
    }

    const back = () => {
        setPage("home");
    }

    const open_mission = (mission) => {
        setPage("mission");
        setIsLoading(true);
        MissionService.view({ mission_id: mission.id }).then(response => {
            setIsLoading(false);
            if (response.status) {
                setCurrentMission(response.data);
            } else {
                setPage("home");
                loadMissions();
            }
        });
    }

    const claim = () => {
        setIsLoading(true);

        MissionService.claim({ mission_id: currentMission.id }).then(response => {
            setIsLoading(false);
            if (response.status) {
                toast.success(t(response.message));
                setCurrentMission(response.data);
                props.data.Authentication.func.get();
            } else {
                setPage("home");
                loadMissions();
            }
        });
    }

    const home = () => {
        return <>
            <div className="tabs">
                <div className={tab === "active" ? "tab active" : "tab"} onClick={() => setTab("active")}>
                    {t("mission.active_mission")}
                </div>
                <div className={tab === "past" ? "tab active" : "tab"} onClick={() => setTab("past")}>
                    {t("mission.past_mission")}
                </div>
            </div>

            {isLoading ? <Loading /> : <div className="missions">
                {Object.values(missions).map(v => <div key={v.id} className="mission" onClick={() => open_mission(v)}>
                    <span className="fs-smaller font-bold">{helpers.t(v.title)}</span>
                </div>)}
            </div>}
        </>;
    }

    const render_task_bullets = (currentMission) => {
        return <div className="mission-bullets">
            {currentMission.task_bullets.map((v, i) => {
                return <div key={i} className={v.status ? "mission-bullet active" : "mission-bullet"}>
                    {v.text}
                </div>;
            })}
        </div>;
    }

    const render_mission_button = (currentMission) => {
        if (currentMission.claimed) {
            return <button className="btn btn-blue btn-block my-2" onClick={back}>
                {t("mission.view_other_mission")}
            </button>;
        }
        if (currentMission.isActive) {
            if (parseInt(currentMission.completed_tasks) === parseInt(currentMission.total_tasks)) {
                return <button className="btn btn-blue btn-block my-2" onClick={claim}>
                    {t("mission.claim_now")}
                </button>;
            } else {
                return <button className="btn btn-blue btn-block my-2" disabled>
                    {t("mission.claim_now")}
                </button>;
            }
        } else {
            return <button className="btn btn-blue btn-block my-2" onClick={back}>
                {t("mission.view_other_mission")}
            </button>;
        }
    }

    const mission = () => {
        if (isLoading || !currentMission) {
            return <Loading />
        }
        return <div className={currentMission.isActive ? "active" : "inactive"}>
            <div className="content">
                <div className="card mission-card">
                    <div className="card-body">
                        <div className="title">{t("mission.mission_progress", {
                            completed_tasks: currentMission.completed_tasks,
                            total_tasks: currentMission.total_tasks
                        })}</div>

                        {render_task_bullets(currentMission)}
                    </div>
                    <div className="card-footer">
                        {currentMission.claimed ? t("mission.completed") : currentMission.isActive ? t('mission.on_going') : t('mission.expired')}
                    </div>
                </div>

                <div className="text" dangerouslySetInnerHTML={{ __html: helpers.t(currentMission.content) }} ></div>

                {render_mission_button(currentMission)}
            </div>
        </div>;
    }

    const render_body = () => {
        if (page === "home") {
            return home();
        }
        if (page === "mission") {
            return mission();
        }
    }

    return <Modal
        className="MissionModal"
        size="lg"
        show={missionModal.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Header>
            {(() => {
                if (page !== "home") {
                    return <button type="button" className="btn-back" aria-label="Back" onClick={back}></button>
                }
            })()}
            <Container className="text-center">
                <h4 className="title mb-0">{t('mission.title')}</h4>
            </Container>
            <button type="button" className="btn-close" aria-label="Close" onClick={close}></button>
        </Modal.Header>
        <Modal.Body className="p-0">
            {render_body()}
        </Modal.Body>

    </Modal>
}