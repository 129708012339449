import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Slider from "react-slick";

export const FeaturedOnMobile = (props) => {
    const { t } = useTranslation();
    const isAuth = () => {
        return props.data.Authentication.func.isLogin();
    }

    if (isAuth()) {
        return <></>;
    }

    if (!props.data.isMobile) {
        return <></>;
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: 0,
        dots: false,
        arrow: false,
        infinite: true,
        speed: 10000,
        slide: 'div',
        cssEase: 'linear',
        swipe: false,
        prevArrow: <></>,
        nextArrow: <></>,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3
                }
            }
        ]
    };

    return <>
        <div className="container pb-3" >
            <h5 className="pt-3 pt-md-0">{t("Featured On")}</h5>

            <Slider {...settings}>
                <div className="p-1">
                    <a href="https://www.bloomberg.com/press-releases/2023-02-03/bd88-launches-new-game-aviator" target="_blank" rel="noreferrer" >
                        <LazyLoadImage
                            className="w-100"
                            src={"/assets/news/Bloomberg.jpg"}
                            placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                            effect="blur"
                            delayTime={300} />
                    </a>
                </div>
                <div className="p-1">
                    <a href="https://www.digitaljournal.com/pr/news/bd88-launches-new-game-aviator-" target="_blank" rel="noreferrer" >
                        <LazyLoadImage
                            className="w-100"
                            src={"/assets/news/Digital-Journal.jpg"}
                            placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                            effect="blur"
                            delayTime={300} />
                    </a>
                </div>
                <div className="p-1">
                    <a href="https://www.finanznachrichten.de/nachrichten-2023-02/58220686-exsim-marketing-enterprise-bd88-launches-new-game-aviator-296.htm" target="_blank" rel="noreferrer" >
                        <LazyLoadImage
                            className="w-100"
                            src={"/assets/news/FinanzNachrichten.jpg"}
                            placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                            effect="blur"
                            delayTime={300} />
                    </a>
                </div>
                <div className="p-1">
                    <a href="https://www.marketwatch.com/press-release/bd88-launches-new-game-aviator-2023-02-02?mod=mw_quote_news_seemore" target="_blank" rel="noreferrer" >
                        <LazyLoadImage
                            className="w-100"
                            src={"/assets/news/market-watch.jpg"}
                            placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                            effect="blur"
                            delayTime={300} />
                    </a>
                </div>
                <div className="p-1">
                    <a href="https://www.benzinga.com/pressreleases/23/02/30701499/bd88-launches-new-game-aviator" target="_blank" rel="noreferrer" >
                        <LazyLoadImage
                            className="w-100"
                            src={"/assets/news/benzinga.jpg"}
                            placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                            effect="blur"
                            delayTime={300} />
                    </a>
                </div>
                <div className="p-1">
                    <a href="https://www.theglobeandmail.com/investing/markets/markets-news/NewsFile/13869388/bd88-launches-new-game-aviator/" target="_blank" rel="noreferrer" >
                        <LazyLoadImage
                            className="w-100"
                            src={"/assets/news/TheGlobeAndMail.jpg"}
                            placeholderSrc={<span className="placeholder w-100" style={{ height: "200px" }}></span>}
                            effect="blur"
                            delayTime={300} />
                    </a>
                </div>
            </Slider>
        </div >
    </>;
}