import { request } from '../Core.js';

export const MissionService = { get, view, claim };

function get(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/missions', params));
    })
}

function view(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/missions/view', params));
    })
}

function claim(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/missions/claim', params));
    })
}