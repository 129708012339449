import React from 'react';
import { useTranslation } from "react-i18next";
import "./Sidebar.css";
import { Col, Row, Button, Nav, InputGroup, Form, Badge } from "react-bootstrap";
import logo from "../../Assets/logo.png";
import logo_cn from "../../Assets/logo_cn.png";
import { ProfileBadge } from "../ProfileBadge/ProfileBadge";
import { Link, useNavigate } from "react-router-dom";
import language_logo from "../../Assets/MobileSidebar/language_logo.png";
import sportsbook from "../../Assets/MobileSidebar/sportsbook.png";
import slots from "../../Assets/MobileSidebar/slots.png";
import live_dealer from "../../Assets/MobileSidebar/live_dealer.png";
import promotions from "../../Assets/MobileSidebar/promotions.png";
import vip from "../../Assets/MobileSidebar/vip.png";
import contact_us from "../../Assets/MobileSidebar/contact_us.png";
import help from "../../Assets/MobileSidebar/help.png";
import logout_logo from "../../Assets/MobileSidebar/logout.png";
import deposit_logo from "../../Assets/MobileSidebar/deposit.png";
import withdrawal_logo from "../../Assets/MobileSidebar/withdrawal.png";
import transfer_logo from "../../Assets/MobileSidebar/transfer.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import mission from "../../Assets/MobileSidebar/mission.png";
import referral from "../../Assets/MobileSidebar/referral.png";
import { useEffect, useState } from "react";

export const Sidebar = (props) => {
    const [menu, setMenu] = useState(localStorage.getItem('menu') ? JSON.parse(localStorage.getItem('menu')) : {
        cashier: true,
        games: true,
        others: true,
    });

    useEffect(() => {
        localStorage.setItem('menu', JSON.stringify(menu));
    }, [menu]);

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    const sidebar = props.data.Sidebar;

    const changeLanguageHandler = (e) => {
        props.data.Language.setLanguage(e.target.value);
    }

    const myAccount = () => {
        navigate("/profile");
    };

    const logout = () => {
        props.data.Authentication.func.logout();
        props.data.MobileSidebar.func.close();
    };

    const isAuth = () => {
        return props.data.Authentication.func.isLogin();
    };

    const openLiveChat = () => {
        props.data.LiveChat.func.open();
    }

    const launch_aviator = () => {
        if (!props.data.Authentication.func.isLogin()) {
            return props.data.LoginModal.func.open();
        }

        const launch = function () {
            props.data.Launcher.func.start({
                product_id: 42,
                type: 4,
                code: "spribe_aviator",
            });
        };

        const wallet = props.data.Authentication.wallets.filter((v, i) => {
            return v.type === 4;
        })[0];

        if (wallet === undefined) {
            launch();
        } else if (wallet.current_balance < 10) {
            props.data.CashierModal.func.quick_transfer(4, () => {
                launch();
            })
        } else {
            launch();
        }
    }

    const renderUserInfo = () => {
        if (!isAuth()) {
            return <></>;
        }
        return (
            <>
                <div className="sidebar-body">
                    <ProfileBadge
                        data={props.data}
                        user={props.data.Authentication.user}
                        wallets={props.data.Authentication.wallets}
                    />
                </div>

                <div className="sidebar-body mb-3">
                    <Row>
                        <Col xs="12" className="mt-2 mb-2">
                            <Button
                                className="w-100 font-bolder"
                                variant="blue"
                                onClick={props.data.CashierModal.func.open}
                            >
                                <div className="d-flex justify-content-center align-items-center">
                                    {t("sidebar.cashier")}
                                </div>
                            </Button>
                        </Col>
                        <Col xs="12" className="mb-2">
                            <Button
                                className="w-100 text-grey font-bolder"
                                variant="light"
                                onClick={myAccount}
                            >
                                {t("sidebar.my_account")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </>
        );
    };

    return (
        <>
            <nav className="sidebar">
                <button className="sidebar-button" onClick={sidebar.func.toggle}>
                    {" "}
                    {sidebar.status ? <FontAwesomeIcon icon={solid('arrow-left')} /> :
                        <FontAwesomeIcon icon={solid('arrow-right')} />}
                </button>

                <div className="sidebar-content">
                    <div className="sidebar-inner">
                        <div className="sidebar-header" onClick={() => navigate("/")}>
                            <img src={i18n.language === "en" ? logo : logo_cn} width="70%" alt="BD88 Logo" />
                        </div>
                        {renderUserInfo()}

                        <Nav className="d-block px-3">
                            {!isAuth() ? (
                                <></>
                            ) : (
                                <>
                                    <p className="h5 font-bolder mb-2 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setMenu({ ...menu, cashier: !menu.cashier })}>
                                        {t("sidebar.cashier")}
                                        {!menu.cashier ? <FontAwesomeIcon className="small-icon" icon={solid('plus')} /> : <FontAwesomeIcon className="small-icon cursor-pointer" icon={solid('minus')} />}
                                    </p>
                                    {menu.cashier ? <>
                                        <Nav.Link onClick={props.data.CashierModal.func.deposit} className="">
                                            <img
                                                className="pe-2"
                                                src={deposit_logo}
                                                alt="{t('deposit')}"
                                                height="30"
                                            />
                                            <span className="fs-small text-muted font-bold">{t("deposit")}</span>
                                        </Nav.Link>
                                        <Nav.Link onClick={props.data.CashierModal.func.withdrawal} className="">
                                            <img
                                                className="pe-2"
                                                src={withdrawal_logo}
                                                alt="{t('withdrawal')}"
                                                height="30"
                                            />
                                            <span className="fs-small text-muted font-bold">
                                                {t("withdrawal")}
                                            </span>
                                        </Nav.Link>
                                        <Nav.Link onClick={props.data.CashierModal.func.transfer} className="">
                                            <img
                                                className="pe-2"
                                                src={transfer_logo}
                                                alt="{t('transfer')}"
                                                height="30"
                                            />
                                            <span className="fs-small text-muted font-bold">
                                                {t("transfer")}
                                            </span>
                                        </Nav.Link>
                                    </> : <></>
                                    }

                                    <hr />
                                </>
                            )}

                            <p className="h5 font-bolder mb-2 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setMenu({ ...menu, games: !menu.games })}>
                                {t("sidebar.games")}
                                {!menu.games ? <FontAwesomeIcon className="small-icon cursor-pointer" icon={solid('plus')} /> : <FontAwesomeIcon className="small-icon cursor-pointer" icon={solid('minus')} />}
                            </p>
                            {menu.games ? <>
                                <Nav.Link as={Link} to="/slots" className={window.location.pathname === "/slots" ? " active" : ""}>
                                    <img
                                        className="pe-2"
                                        src={slots}
                                        alt="{t('slots')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">{t("slots")}</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to="/live-dealer" className={window.location.pathname === "/live-dealer" ? " active" : ""}>
                                    <img
                                        className="pe-2"
                                        src={live_dealer}
                                        alt="{t('live_dealer')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">{t("live_dealer")}</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to="/sportsbook" className={window.location.pathname === "/sportsbook" ? " active" : ""}>
                                    <img
                                        className="pe-2"
                                        src={sportsbook}
                                        alt="{t('sportsbook')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">{t("sportsbook")}</span>
                                </Nav.Link>
                                <Nav.Link as={Link} to="/#" className="" onClick={launch_aviator}>
                                    <img
                                        className="pe-1"
                                        src="/assets/aviator.png"
                                        alt="{t('aviator')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">
                                        <img src={i18n.language === "en" ? "/assets/aviator_text.png" : "/assets/aviator_text_cn.svg"} height="22" alt={"aviator"} />
                                    </span>
                                </Nav.Link>
                            </> : <></>
                            }

                            <hr />
                            <p className="h5 font-bolder mb-2 d-flex justify-content-between align-items-center cursor-pointer" onClick={() => setMenu({ ...menu, others: !menu.others })}>
                                {t("sidebar.others")}
                                {!menu.others ? <FontAwesomeIcon className="small-icon cursor-pointer" icon={solid('plus')} /> : <FontAwesomeIcon className="small-icon cursor-pointer" icon={solid('minus')} />}
                            </p>

                            {menu.others ? <>
                                <Nav.Link as={Link} to="/promotions" className={window.location.pathname === "/promotions" ? " active" : ""}>
                                    <img
                                        className="pe-2"
                                        src={promotions}
                                        alt="{t('sidebar.promotions')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">
                                        {t("sidebar.promotions")}
                                    </span>
                                </Nav.Link>
                                <Nav.Link as={Link} to="/rewards" className={window.location.pathname === "/rewards" ? " active" : ""}>
                                    <img
                                        className="pe-2"
                                        src={vip}
                                        alt="{t('sidebar.vip')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">{t("sidebar.vip")}</span>
                                </Nav.Link>
                                <Nav.Link onClick={isAuth() ? props.data.MissionModal.func.open : props.data.LoginModal.func.open} className="">
                                    <img
                                        className="pe-2"
                                        src={mission}
                                        alt="{t('sidebar.mission')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">{t("sidebar.mission")}</span>
                                    <Badge className="ms-2" bg="danger">{t('new')}</Badge>
                                </Nav.Link>
                                <Nav.Link onClick={isAuth() ? () => { navigate("referral"); } : () => { props.data.LoginModal.func.open(); }} className={window.location.pathname === "/referral" ? " active" : ""}>
                                    <img
                                        className="pe-2"
                                        src={referral}
                                        alt="{t('sidebar.referral')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">{t("sidebar.referral")}</span>
                                    <Badge className="ms-2" bg="danger">{t('new')}</Badge>
                                </Nav.Link>
                                <Nav.Link as={Link} to="/contact-us" className={window.location.pathname === "/contact-us" ? " active" : ""}>
                                    <img
                                        className="pe-2"
                                        src={contact_us}
                                        alt="{t('sidebar.contact_us')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">
                                        {t("sidebar.contact_us")}
                                    </span>
                                </Nav.Link>
                                <Nav.Link onClick={openLiveChat} className="">
                                    <img
                                        className="pe-2"
                                        src={help}
                                        alt="{t('sidebar.help')}"
                                        height="30"
                                    />
                                    <span className="fs-small text-muted font-bold">
                                        {t("sidebar.livechat")}
                                    </span>
                                </Nav.Link>

                                {isAuth() ? (
                                    <Nav.Link onClick={logout} className="">
                                        <img
                                            className="pe-2"
                                            src={logout_logo}
                                            alt="{t('sidebar.logout')}"
                                            height="30"
                                        />
                                        <span className="fs-small text-muted font-bold">
                                            {t("sidebar.logout")}
                                        </span>
                                    </Nav.Link>
                                ) : (
                                    <></>
                                )}

                            </> : <></>
                            }
                            <hr />
                            <InputGroup className="language-picker mt-4">
                                <InputGroup.Text>
                                    <img src={language_logo} width="24px" alt="Language Logo" />
                                </InputGroup.Text>
                                <Form.Select
                                    as="select"
                                    onChange={changeLanguageHandler}
                                    value={i18n.language}
                                >
                                    <option value="en">English</option>
                                    <option value="zh_CN">中文</option>
                                </Form.Select>
                            </InputGroup>
                        </Nav>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default Sidebar;
