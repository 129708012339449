import { useState, useEffect } from "react";
import { GameService } from "../../Models/Game/GameService";

export default function MaintenanceModalHook() {
    const [status, setStatus] = useState(false);
    const [data, setData] = useState(false);
    const [maintenances, setMaintenances] = useState([]);
    const [endAt, setEndAt] = useState(false);
    const open = (data, end_at) => {
        setData(data);
        setEndAt(end_at);
        setStatus(true)
    };

    const close = () => setStatus(false);

    useEffect(() => {
        GameService.product_maintenances({}).then(response => {
            setMaintenances(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        status: status,
        data: data,
        maintenances: maintenances,
        endAt: endAt,
        func: {
            open,
            close
        },
    };
}