import { request } from '../Core.js';

export const PageService = { get, promotions, contact, home };

function get(params) {
    return new Promise((resolve, reject) => {
        resolve(request('/pages', params));
    })
}

function home(params) {
    return new Promise((resolve, reject) => {
        resolve(request('/pages/home', params));
    })
}

function promotions(params) {
    return new Promise((resolve, reject) => {
        resolve(request('/promotions', params));
    })
}

function contact(params) {
    return new Promise((resolve, reject) => {
        resolve(request('/contact', params));
    })
}