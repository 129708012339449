import React from 'react';
import { Modal } from "react-bootstrap"
import { t } from "i18next";
import "./style.css";

export const LauncherLoading = (props) => {
    const Launcher = props.data.Launcher;

    return <Modal
        className="LauncherLoadingModal"
        size="lg"
        show={Launcher.isLoading}
        onHide={false}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center p-0">
            <h3 className="py-5">{t("loading")}</h3>
        </Modal.Body>
    </Modal>
}