import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from 'react';
import { GameService } from "../../Models/Game/GameService";
import Circles from "./Circles";
import GameList from "./GameList";
import "./style.css";

import like_histories from "../../Assets/Game/like_histories.png";
import like_histories_active from "../../Assets/Game/like_histories_active.png";
import play_histories from "../../Assets/Game/play_histories.png";
import play_histories_active from "../../Assets/Game/play_histories_active.png";
import filter from "../../Assets/Game/filter.png";
import filter_active from "../../Assets/Game/filter_active.png";

import Select, { components } from "react-select";

function Game(props) {
    const { t, i18n } = useTranslation();
    const maintenances = props.data.MaintenanceModal.maintenances;
    const current_category = props.current_category;
    const [category, setCategory] = useState(current_category ?? "slots");
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [games, setGames] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLike, setIsLike] = useState(false);
    const [isPlay, setIsPlay] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    useEffect(() => {
        if (current_category) {
            setCategory(current_category);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_category]);

    useEffect(() => {
        GameService.products({ category: category }).then(response => {
            setProducts(response.sort((a, b) => a.sequence - b.sequence));
        });
        setSelectedProducts([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    useEffect(() => {
        setGames([]);

        var type = null;

        if (isLike) {
            type = "like";
        }
        if (isPlay) {
            type = "play";
        }

        setIsLoading(true);

        GameService.games({ category: category, product: selectedProducts, type: type }).then(response => {
            setIsLoading(false);
            if (type) {
                setGames(response);
            } else {
                setGames(response.filter(a => !maintenances.hasOwnProperty(a.product_id) ? true : false));
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category, selectedProducts[0], selectedProducts.length, isLike, isPlay]);

    const getTitle = () => {
        if (isLike) {
            return t("game.favourite_game");;
        }
        if (isPlay) {
            return t("game.last_played");;
        }

        if (selectedProducts.length > 0) {
            if (selectedProducts.length === 1) {
                return products.filter(v => {
                    if (v && selectedProducts.includes(v.id)) {
                        return true;
                    }
                    return false;
                }).map(v => {
                    return t('game.product_games', { product_name: v.name });
                });
            }
            return t('game.games');
        }

        return t("game.all_games");
    }

    const handleProducts = (v) => {
        var new_products = [];
        v.map(d => {
            return new_products.push(d.value);
        });
        setSelectedProducts(new_products);
    }

    const getTitleAndToolsbar = () => {
        return <div className="mb-2">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="title">
                    <h5 className="mb-0">{getTitle()}</h5>
                </div>
                <div className="toolbar">
                    <div className="toolbar-button" onClick={toggleLikeHistories}>
                        <img src={!isLike ? like_histories : like_histories_active} alt="like_histories" />
                        <span>{t('favorites')}</span>
                    </div>
                    <div className="toolbar-button" onClick={togglePlayHistories}>
                        <img src={!isPlay ? play_histories : play_histories_active} alt="play_histories" />
                        <span>{t('history')}</span>
                    </div>
                    <div className="toolbar-button" onClick={toggleFilter}>
                        <img src={!isFilter ? filter : filter_active} alt="filter" />
                        <span>{t('filter')}</span>
                    </div>
                </div>
            </div>
            <div className={isFilter ? "filter" : "filter d-none"}>
                <Select
                    options={products.map(product => { return { value: product.id, label: product.name } })}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    blurInputOnSelect={false}
                    isSearchable={false}
                    placeholder={t("game.providers")}
                    components={{
                        Option
                    }}
                    onChange={handleProducts}
                    value={
                        products.map(product => { return { value: product.id, label: product.name } }).filter(function (option) {
                            if (selectedProducts.includes(option.value)) {
                                return true;
                            }
                            return false;
                        })
                    }
                />
            </div>
        </div>;
    }

    const toggleLikeHistories = () => {
        if (!props.data.Authentication.func.isLogin()) {
            return props.data.LoginModal.func.open();
        }
        setIsLike(!isLike);
        setIsPlay(false);
    }

    const togglePlayHistories = () => {
        if (!props.data.Authentication.func.isLogin()) {
            return props.data.LoginModal.func.open();
        }
        setIsPlay(!isPlay);
        setIsLike(false);
    }

    const toggleFilter = () => {
        setIsFilter(!isFilter);
    }

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props} className="bg-transparent text-dark">
                    <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const launch_aviator = () => {
        if (!props.data.Authentication.func.isLogin()) {
            return props.data.LoginModal.func.open();
        }

        const launch = function () {
            props.data.Launcher.func.start({
                product_id: 42,
                type: 4,
                code: "spribe_aviator",
            });
        };

        const wallet = props.data.Authentication.wallets.filter((v, i) => {
            return v.type === 4;
        })[0];

        if (wallet === undefined) {
            launch();
        } else if (wallet.current_balance < 10) {
            props.data.CashierModal.func.quick_transfer(4, () => {
                launch();
            })
        } else {
            launch();
        }
    }

    const categoryList = () => {
        if (!props.hasCategories) {
            return <></>;
        }
        return <>
            <h5 className="mb-0">{t("game.products")}</h5>
            <div className="category">
                <div className={category === "slots" ? "category-selection active" : "category-selection"} onClick={() => setCategory("slots")}>
                    {t("slots")}
                </div>
                <div className={category === "live_dealer" ? "category-selection active" : "category-selection"} onClick={() => setCategory("live_dealer")}>
                    {t("live_dealer")}
                </div>
                <div className={category === "sportsbook" ? "category-selection active" : "category-selection"} onClick={() => setCategory("sportsbook")}>
                    {t("sportsbook")}
                </div>
                <div className="category-selection" onClick={launch_aviator}>
                    <img src={i18n.language === "en" ? "/assets/aviator_text.png" : "/assets/aviator_text_cn.svg"} height="20" alt={"aviator"} />
                </div>
            </div>
        </>;
    }

    return <>
        <div>
            {categoryList()}
            <Circles data={props.data} category={category} products={products} selectedProducts={selectedProducts} setSelectedProducts={setSelectedProducts} />
            {getTitleAndToolsbar()}
            <GameList data={props.data} games={games} isLoading={isLoading} hasAdvertisment={category === "slots"} />
        </div>
    </>;
}

export default Game;