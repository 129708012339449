import { Nav, Offcanvas, Form, InputGroup, Container, CloseButton, Col, Row, Button, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./MobileSidebar.css";
import logo from "../../Assets/logo.png";
import logo_cn from "../../Assets/logo_cn.png";
import language_logo from "../../Assets/MobileSidebar/language_logo.png";
import sportsbook from "../../Assets/MobileSidebar/sportsbook.png";
import slots from "../../Assets/MobileSidebar/slots.png";
import live_dealer from "../../Assets/MobileSidebar/live_dealer.png";
import promotions from "../../Assets/MobileSidebar/promotions.png";
import vip from "../../Assets/MobileSidebar/vip.png";
import contact_us from "../../Assets/MobileSidebar/contact_us.png";
import help from "../../Assets/MobileSidebar/help.png";
import logout_logo from "../../Assets/MobileSidebar/logout.png";
import deposit_logo from "../../Assets/MobileSidebar/deposit.png";
import withdrawal_logo from "../../Assets/MobileSidebar/withdrawal.png";
import transfer_logo from "../../Assets/MobileSidebar/transfer.png";
import referral_logo from "../../Assets/MobileSidebar/referral.png";
import mission from "../../Assets/MobileSidebar/mission.png";
import { Link, useNavigate } from "react-router-dom";
import { ProfileBadge } from "../ProfileBadge/ProfileBadge";

export const MobileSidebar = (props) => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    const changeLanguageHandler = (e) => {
        props.data.Language.setLanguage(e.target.value);
    }

    const logout = () => {
        getAuth().func.logout();
        props.data.MobileSidebar.func.close();
    }

    const close = () => {
        props.data.MobileSidebar.func.close();
    }

    const myAccount = () => {
        props.data.MobileSidebar.func.close();
        navigate('/profile');
    }

    const getAuth = () => {
        return props.data.Authentication;
    }

    const isAuth = () => {
        return getAuth().func.isLogin();
    }

    const get = () => {
        return getAuth().user;
    }

    const openLiveChat = () => {
        props.data.LiveChat.func.open();
    }

    const launch_aviator = () => {
        if (!props.data.Authentication.func.isLogin()) {
            return props.data.LoginModal.func.open();
        }

        const launch = function () {
            props.data.Launcher.func.start({
                product_id: 42,
                type: 4,
                code: "spribe_aviator",
            });
        };

        const wallet = props.data.Authentication.wallets.filter((v, i) => {
            return v.type === 4;
        })[0];

        if (wallet === undefined) {
            launch();
        } else if (wallet.current_balance < 10) {
            props.data.CashierModal.func.quick_transfer(4, () => {
                launch();
            })
        } else {
            launch();
        }

        close();
    }

    const renderUserInfo = () => {
        if (!isAuth() || !get()) {
            return "";
        }

        return (
            <Row>
                <Col xs="12" className="mb-3">
                    <ProfileBadge
                        data={props.data}
                        user={props.data.Authentication.user}
                        wallets={props.data.Authentication.wallets} />
                </Col>
                <Col xs="6" className="pe-1 mb-2">
                    <Button className="w-100 font-bolder" variant="blue" onClick={props.data.CashierModal.func.open}>
                        <div className="d-flex justify-content-center align-items-center">
                            {t('sidebar.cashier')}
                        </div>
                    </Button>
                </Col>
                <Col xs="6" className="ps-1">
                    <Button className="w-100 text-grey font-bolder" variant="light" onClick={myAccount}>
                        {t('sidebar.my_account')}
                    </Button>
                </Col>
            </Row>
        );
    }

    return <Offcanvas
        id="phone-sidebar"
        aria-labelledby="phone-sidebarLabel"
        placement="start"
        backdrop="true"
        className="w-100"
        show={props.data.MobileSidebar.status}
        onHide={props.data.MobileSidebar.func.close}
    >
        <Offcanvas.Body>
            <Container>
                <Offcanvas.Title id="phone-sidebarLabel clearfix">
                    <img src={i18n.language === "en" ? logo : logo_cn} className="logo mt-3" alt="BD88 Logo" height="30px" />
                    <CloseButton className="float-end" onClick={close} />
                </Offcanvas.Title>

                {renderUserInfo()}

                <hr />

                <Nav className="d-block">
                    {
                        !isAuth() ? <></> :
                            <>
                                <p className="h4 text-muted font-bolder mb-2">{t('sidebar.cashier')}</p>
                                <Nav.Link onClick={props.data.CashierModal.func.deposit}>
                                    <img className="pe-2" src={deposit_logo} alt="{t('deposit')}" height="30" />
                                    <span className="text-muted font-bold">{t('deposit')}</span>
                                </Nav.Link>
                                <Nav.Link onClick={props.data.CashierModal.func.withdrawal}>
                                    <img className="pe-2" src={withdrawal_logo} alt="{t('withdrawal')}" height="30" />
                                    <span className="text-muted font-bold">{t('withdrawal')}</span>
                                </Nav.Link>
                                <Nav.Link onClick={props.data.CashierModal.func.transfer}>
                                    <img className="pe-2" src={transfer_logo} alt="{t('transfer')}" height="30" />
                                    <span className="text-muted font-bold">{t('transfer')}</span>
                                </Nav.Link>
                                <hr />
                            </>
                    }

                    <p className="h4 text-muted font-bolder mb-2">{t('sidebar.games')}</p>
                    <Nav.Link as={Link} to="/slots" onClick={close}>
                        <img className="pe-2" src={slots} alt="{t('slots')}" height="30" />
                        <span className="text-muted font-bold">{t('slots')}</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/live-dealer" onClick={close}>
                        <img className="pe-2" src={live_dealer} alt="{t('live_dealer')}" height="30" />
                        <span className="text-muted font-bold">{t('live_dealer')}</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/sportsbook" onClick={close}>
                        <img className="pe-2" src={sportsbook} alt="{t('sportsbook')}" height="30" />
                        <span className="text-muted font-bold">{t('sportsbook')}</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/sportsbook" onClick={launch_aviator}>
                        <img className="pe-1" src="/assets/aviator.png" alt="{t('aviator')}" height="30" />
                        <img src={i18n.language === "en" ? "/assets/aviator_text.png" : "/assets/aviator_text_cn.svg"} height="22" alt={"aviator"} />
                    </Nav.Link>
                    <Nav.Link as={Link} to="/promotions" onClick={close}>
                        <img className="pe-2" src={promotions} alt="{t('sidebar.promotions')}" height="30" />
                        <span className="text-muted font-bold">{t('sidebar.promotions')}</span>
                    </Nav.Link>
                    <hr />
                    <p className="h4 text-muted font-bolder mb-2">{t('sidebar.others')}</p>

                    <Nav.Link as={Link} to="/rewards" onClick="close">
                        <img className="pe-2" src={vip} alt="{t('sidebar.vip')}" height="30" />
                        <span className="text-muted font-bold">{t('sidebar.vip')}</span>
                    </Nav.Link>
                    <Nav.Link onClick={isAuth() ? props.data.MissionModal.func.open : props.data.LoginModal.func.open}>
                        <img className="pe-2" src={mission} alt="{t('sidebar.mission')}" height="30" />
                        <span className="text-muted font-bold">{t('sidebar.mission')}</span>
                        <Badge className="ms-2" bg="danger">{t('new')}</Badge>
                    </Nav.Link>
                    <Nav.Link onClick={isAuth() ? () => { navigate("referral"); close(); } : () => { props.data.LoginModal.func.open(); close(); }}>
                        <img className="pe-2" src={referral_logo} alt="{t('sidebar.referral')}" height="30" />
                        <span className="text-muted font-bold">{t('sidebar.referral')}</span>
                        <Badge className="ms-2" bg="danger">{t('new')}</Badge>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/contact-us" onClick={close}>
                        <img className="pe-2" src={contact_us} alt="{t('sidebar.contact_us')}" height="30" />
                        <span className="text-muted font-bold">{t('sidebar.contact_us')}</span>
                    </Nav.Link>
                    <Nav.Link onClick={openLiveChat}>
                        <img className="pe-2" src={help} alt="{t('sidebar.help')}" height="30" />
                        <span className="text-muted font-bold">{t('sidebar.livechat')}</span>
                    </Nav.Link>

                    {isAuth() ? (
                        <Nav.Link onClick={logout}>
                            <img className="pe-2" src={logout_logo} alt="{t('sidebar.logout')}" height="30" />
                            <span className="text-muted font-bold">{t('sidebar.logout')}</span>
                        </Nav.Link>
                    ) : (<></>)}

                    <hr />
                    <InputGroup className="language-picker mt-4">
                        <InputGroup.Text><img src={language_logo} width="24px" alt="Language Logo" /></InputGroup.Text>
                        <Form.Select as="select" onChange={changeLanguageHandler} value={i18n.language}>
                            <option value="en" >English</option>
                            <option value="zh_CN" >中文</option>
                        </Form.Select>
                    </InputGroup>
                </Nav>
            </Container>
        </Offcanvas.Body>
    </Offcanvas>;
};


export default MobileSidebar;