import i18next from "i18next";
import { useState, useEffect } from "react";
import { UserService } from "./UserService";
import { deposit_success, join_now } from "../Pixel/Pixel";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export default function AuthenticationHook() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [authentication, setAuthentication] = useState(sessionStorage.getItem("authentication"));

  useEffect(() => {
    if (user) {
      window.Echo.channel(`authenticate.${user.code}`).listen('LoginEvent', (e) => {
        logout();
      })
      return () => {
        if (user) {
          window.Echo.leave(`authenticate.${user.code}`);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const get = async () => {
    const fp = await FingerprintJS.load();
    const { visitorId } = await fp.get();

    return UserService.get({ token: authentication, fingerprint: visitorId }).then(response => {
      setUser(response);
      setWallet(response.user_wallets);
      if (!response.status) {
        setUser(null);
        setWallet(null);
      }
      return response;
    });
  }

  const updateEvent = async (event_id) => {
    return await UserService.update_event({ token: authentication, event_id: event_id });
  }

  const getTransactions = async () => {
    return await UserService.getTransactions({ token: authentication });
  }

  const removeBalance = () => {
    setWallet(null);
  }

  useEffect(() => {
    setLoading(true);

    if (authentication && authentication !== "null") {
      get().then(() => {
        setLoading(false);
      });

    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authentication]);

  useEffect(() => {
    if (user) {
      var userId = user.id || null; // Replace your_user_id with your own if available.
      window.hj('identify', userId, {
        'Username': user.username,
      });

      if (user.user_events) {
        user.user_events.map((v) => {
          if (v.type === "deposit_success") {
            deposit_success(v.params.username, v.params.transaction_id, v.params.amount);;
            updateEvent(v.id);
          } else if (v.type === "join_now") {
            join_now(v.params.username);
            updateEvent(v.id);
          } else {
            if (window.fbq) {
              window.fbq('track', v.type, v.params);
              updateEvent(v.id);
            }

            if (window.gtag) {
              window.gtag("event", v.type, v.params);
              updateEvent(v.id);
            }
          }

          return true;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const updateAuthentication = async (token) => {
    sessionStorage.setItem("authentication", token);
    setAuthentication(token);
  }

  const logout = async () => {
    window.Echo.leaveChannel(`authenticate.${authentication}`);
    sessionStorage.setItem("authentication", null);
    setAuthentication(null);
    setUser(null);
    setWallet(null);
  }

  const isLogin = () => {
    if (authentication === "null" || authentication === null) {
      return false;
    }

    if (user === undefined) {
      return false;
    }

    if (user === "null" || user === null) {
      return false;
    }

    if (user.status === false) {
      return false;
    }

    return true;
  }

  const getLocale = () => {
    return i18next.language;
  }

  const getRank = () => {
    if (user) {
      if (user.level === 1) {
        return "blue;";
      }
      if (user.level === 2) {
        return "silver";
      }
      if (user.level === 3) {
        return "gold";
      }
      if (user.level === 4) {
        return "platinum";
      }
      if (user.level === 5) {
        return "legend";
      }
    }
  }

  const getPercentageToUpgrade = () => {
    var total = 0;
    if (user) {
      if (user.level === 1 || user.level === 2) {
        if (parseFloat(user.level_point) >= user.point_to_upgrade) {
          total = 100;
        } else {
          total = 100 * (parseFloat(user.level_point) / user.point_to_upgrade);
        }
      } else {
        if (user.level === 4 || user.level === 5) {
          if (parseFloat(user.level_point) >= user.point_to_maintain) {
            total = 100;
          } else {
            total = 100 * (parseFloat(user.level_point) / user.point_to_maintain);
          }
        } else {
          if (parseFloat(user.level_point) >= user.point_to_maintain) {
            total = 30;
          } else {
            total = 30 * (parseFloat(user.level_point) / user.point_to_maintain);
          }

          if (total === 30) {
            if (parseFloat(user.level_point) >= user.point_to_upgrade) {
              total += 70;
            } else if ((parseFloat(user.level_point) - user.point_to_maintain) > 0) {
              total = total + (70 * ((parseFloat(user.level_point) - user.point_to_maintain) / (user.point_to_upgrade - user.point_to_maintain)));
            }
          }
        }
      }

      return Math.floor(total);
    }
  }

  const getRankImage = () => {
    if (user) {
      return getRank() + "-" + getLocale() + ".png"
    }
  }

  const requireVerify = () => {
    if (user) {
      return user.user_identifies.length > 0 ? true : false;
    }
    return false;
  }

  return {
    loading: loading,
    status: authentication,
    user: user,
    wallets: wallet,
    func: {
      updateAuthentication: updateAuthentication,
      logout: logout,
      isLogin: isLogin,
      getRankImage: getRankImage,
      getRank: getRank,
      get: get,
      removeBalance: removeBalance,
      getTransactions: getTransactions,
      requireVerify: requireVerify,
      getPercentageToUpgrade: getPercentageToUpgrade,
      updateEvent: updateEvent,
    },
  };
}