import { useEffect, useState } from "react";

export default function ProviderHook() {
    const [category, setCategory] = useState(null);
    const update = (value) => { setCategory(value) };

    useEffect(() => {
        if (window.location.pathname === "/slots") {
            update("slots");
        } else if (window.location.pathname === "/live-dealer") {
            update("live-dealer");
        } else if (window.location.pathname === "/sportsbook") {
            update("sportsbook");
        } else if (window.location.pathname === "/promotions") {
            update("promos");
        } else {
            update(null);
        }
    });

    return {
        status: category,
        update: update,
    };
}