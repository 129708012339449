import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { GameService } from "../../Models/Game/GameService";
import GameCard from "./GameCard";
import GameCardPlaceholder from "./GameCardPlaceholder";
import { Swiper, SwiperSlide } from 'swiper/react';

function HistoryGames(props) {
    const [games, setGames] = useState([]);
    const [swiper, setSwiper] = useState(null);
    const slidesPerView = (window.innerWidth >= 768) ? (window.innerWidth >= 1024) ? 5 : 3.85 : 2.5;

    useEffect(() => {
        GameService.games({ type: "histories_game" }).then(response => {
            if (response.length === 0) {
                setGames(false);
            } else {
                setGames(response);
            }
        });
    }, []);

    if (!games) {
        return <></>;
    }

    return <div className="mb-4">
        <h5>{t('game.last_played')}</h5>
        <Swiper
            className="position-relative"
            spaceBetween={0}
            slidesPerView={slidesPerView}
            loop
            observer
            observeParents
            parallax
            onSwiper={(s) => setSwiper(s)}
        >
            {games.length === 0 ? <>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
                <SwiperSlide>
                    <GameCardPlaceholder />
                </SwiperSlide>
            </> : <>
                {games.map((element, i) => {
                    return <SwiperSlide key={i}>
                        <GameCard data={props.data} key={i} element={element} index={i} isShow={true} />
                    </SwiperSlide>
                })}
            </>}

            <div className='swiper-navigations games'>
                <div className='swiper-navigations-wrapper'>
                    <div className="swiper-navigation swiper-slider-prev" onClick={() => swiper.slidePrev()} >
                        <img className="carousel-icon" src={"/assets/icons/prev.png"} alt="prev" />
                    </div>
                    <div className="swiper-navigation swiper-slider-next" onClick={() => swiper.slideNext()} >
                        <img className="carousel-icon" src={"/assets/icons/next.png"} alt="next" />
                    </div>
                </div>
            </div>
        </Swiper>
    </div>;
}

export default HistoryGames;