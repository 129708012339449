import * as qs from 'query-string';
import { request } from '../Models/Core';

const parsed_query = qs.parse(window.location.search);

if (parsed_query.extra) {
    localStorage.setItem("extra", parsed_query.extra);
}

if (parsed_query.ref) {
    request("ref", { ref: parsed_query.ref });
    sessionStorage.setItem('referral', parsed_query.ref);
}
if (parsed_query.login) {
    sessionStorage.setItem('authentication', parsed_query.login);
}

if (parsed_query.lang === "en") {
    localStorage.setItem("i18nextLng", "en");
}

if (parsed_query.lang === "zh-CN") {
    localStorage.setItem("i18nextLng", "zh_CN");
}