import { request } from '../Core.js';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function SeoHook() {
    const location = useLocation();
    const [seos, setSeos] = useState([]);
    const [data, setData] = useState(null);

    const fetch = (params) => {
        params = { ...params, "token": sessionStorage.getItem("authentication") };

        return new Promise((resolve, reject) => {
            resolve(request('/seo', params));
        });
    }

    useEffect(() => {
        fetch().then(response => {
            if (response) {
                setSeos(Object.values(response));
            }
        });
    }, [])

    useEffect(() => {
        const loadSeo = () => {
            seos.filter(seo => seo.url === location.pathname || "/" + seo.url === location.pathname).map(seo => {
                setData(seo)
                return false;
            });
        }
        setData(null);
        loadSeo();
    }, [seos, location]);

    return {
        data: data,
    };
}