import React from 'react'
import ReactDOM from 'react-dom'
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from './App';
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pusher from 'pusher-js';
import TagManager from 'react-gtm-module'
import Echo from 'laravel-echo';

TagManager.initialize({
  gtmId: 'GTM-KVB92SC',
});


window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: "62c212a97eb6b868dcc0",
  wsHost: `ws-ap1.pusher.com`,
  wsPort: 80,
  wssPort: 443,
  forceTLS: true,
  enabledTransports: ['ws', 'wss'],
  cluster : 'ap1',
});

const rootElement = document.getElementById("app");
if (rootElement.hasChildNodes()) {
  hydrate(<BrowserRouter>
    <App />
    {ReactDOM.createPortal(<ToastContainer />, document.getElementById('Toastify-Container'))}
  </BrowserRouter>, rootElement);
} else {
  render(<BrowserRouter>
    <App />
    {ReactDOM.createPortal(<ToastContainer />, document.getElementById('Toastify-Container'))}
  </BrowserRouter>, rootElement);
}