import React from 'react';
import ReactLoading from 'react-loading';

const Loading = ({ type }) => (
    <>
        <div className='d-flex align-items-center justify-content-center w-100 h-inherit m-auto'>
            {
                (window.innerWidth > 768) ?
                    <div className="d-flex align-items-center justify-content-center">
                        <ReactLoading type={'bubbles'} color={'#ff4f0f'} height={'unset'} width={'200px'} />
                    </div>
                    : <ReactLoading type={'bubbles'} color={'#ff4f0f'} height={'unset'} width={'100px'} />
            }
        </div>
    </>
);

export default Loading;