import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { Promotion } from '../../Components/Promotion/Promotion';
import Game from '../../Components/Games/Game';
import PopularGames from '../../Components/Games/PopularGames';
import NewGames from '../../Components/Games/NewGames';
import './Home.css';
import { t } from "i18next";
import HistoryGames from '../../Components/Games/HistoryGames';
import { home } from '../../Models/Pixel/Pixel';
import { FeaturedOnDesktop } from '../../Components/FeaturedOn/FeaturedOnDesktop';
import { FeaturedOnMobile } from '../../Components/FeaturedOn/FeaturedOnMobile';
import { Testimonials } from '../../Components/Testimonials';

function Home(props) {
  const seo = props.data.Seo.data;
  const helpers = props.data.helpers;

  const getAuth = () => {
    return props.data.Authentication;
  }

  const isAuth = () => {
    return getAuth().func.isLogin();
  }

  useEffect(() => {
    home();
    return () => { };
  }, []);

  return (
    <div className={isAuth() ? "content logined" : "content"}>
      <Helmet>
        <title> {seo ? helpers.t(seo.meta_title) : t('home') + " | BD88"}</title>
        <meta name="keywords" content={seo ? helpers.t(seo.meta_keyword) : ""} />
        <meta name="description" content={seo ? helpers.t(seo.meta_description) : ""} />
      </Helmet>
      <div className="background">
        <Promotion data={props.data} />
        <Container className='py-2 pb-2 px-lg-4'>
          {props.data.isMobile ? <>
            {isAuth() ? <HistoryGames data={props.data} /> : <></>}
            <PopularGames data={props.data} />

            <NewGames data={props.data} />
            <Game hasCategories={true} data={props.data} />
          </>
            : <>
              <div className='card special-card'>
                <div className='card-body'>
                  {isAuth() ? <HistoryGames data={props.data} /> : <></>}
                  <PopularGames data={props.data} />
                  <NewGames data={props.data} />
                  <Game hasCategories={true} data={props.data} />
                </div>
              </div>
            </>
          }
        </Container>

        <Testimonials />
        <FeaturedOnDesktop data={props.data} />
        <FeaturedOnMobile data={props.data} />
      </div>
    </div>
  );
}

export default Home;
