import moment from "moment/moment";
import { useEffect, useState } from "react";

export default function AngpowHook() {
    const [status, setStatus] = useState(true);
    const open = () => { setStatus(true) };
    const close = () => setStatus(false);
    const toggle = () => setStatus(!status);

    useEffect(() => {
        if (moment() > moment("2023-01-20 00:00:00") && moment() < moment("2023-02-06 00:00:00")) {
            var expired_at = window.sessionStorage.getItem('angpow_expired_at');
            if (!expired_at) {
                setStatus(true);
                window.sessionStorage.setItem('angpow_expired_at', (new Date().getTime()) + (1000 * 60 * 60 * 24));
            } else if (expired_at && new Date().getTime() > expired_at) {
                setStatus(true);
                window.sessionStorage.setItem('angpow_expired_at', (new Date().getTime()) + (1000 * 60 * 60 * 24));
            }
        }
    }, []);

    return {
        status: status,
        func: {
            open,
            close,
            toggle
        },
    };
}