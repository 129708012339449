import React from "react";
import './MobileFooter.css';
import { Link } from 'react-router-dom';
import { Image, Nav, Row } from 'react-bootstrap';
import home_logo from '../../Assets/MobileFooter/home.png'
import home_active_logo from '../../Assets/MobileFooter/home_active.png'
import vip_logo from '../../Assets/MobileFooter/vip.png'
import vip_active_logo from '../../Assets/MobileFooter/vip_active.png'
import reward_logo from '../../Assets/MobileFooter/reward.png'
import reward_active_logo from '../../Assets/MobileFooter/reward_active.png'
import profile_logo from '../../Assets/MobileFooter/profile.png'
import promotions_logo from '../../Assets/MobileFooter/promotions.png'
import promotions_active_logo from '../../Assets/MobileFooter/promotions_active.png'
import help_logo from '../../Assets/MobileFooter/help.png'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom'

export const MobileFooter = (props) => {
    const location = useLocation();
    const { t } = useTranslation();

    const setActive = (value) => {
        props.data.MobileFooter.func.setPage(value);
    };

    const register = () => {
        props.data.RegisterModal.func.open();
    }

    const cashier = () => {
        props.data.CashierModal.func.wallet();
    }

    const openLiveChat = () => {
        props.data.LiveChat.func.open();
    }

    const isAuth = () => {
        return props.data.Authentication.func.isLogin();
    }

    return (
        <div className="phone-navbar d-lg-none">
            <Nav bg="light" expand="lg" activeKey={props.data.MobileFooter.status} onSelect={(selectedKey) => setActive(selectedKey)}>
                <Row>
                    <Nav.Link as={Link} to="/" className={(location.pathname === "/") ? "col text-center active" : "col text-center"}>
                        <Image src={(location.pathname === "/") ? home_active_logo : home_logo} alt={t('phone_footer.home')} />
                        <span>{t('phone_footer.home')}</span>
                    </Nav.Link>
                    <Nav.Link as={Link} to="/promotions" className={(location.pathname === "/promotions") ? "col text-center active" : "col text-center"}>
                        <Image src={(location.pathname === "/promotions") ? promotions_active_logo : promotions_logo} alt={t('phone_footer.promotions')} />
                        <span>{t('phone_footer.promotions')}</span>
                    </Nav.Link>

                    {isAuth() ? (
                        <Nav.Link onClick={cashier} className="col text-center">
                            <div className="highlight-nav">
                                <div className="highlight-nav-inner">
                                    <Image src={profile_logo} alt="{t('phone_footer.cashier')}" />
                                    <span>{t('phone_footer.cashier')}</span>
                                </div>
                            </div>
                        </Nav.Link>
                    ) : (
                        <Nav.Link onClick={register} className="col text-center">
                            <div className="highlight-nav">
                                <div className="highlight-nav-inner">
                                    <Image src={profile_logo} alt="{t('phone_footer.sign_up')}" />
                                    <span>{t('phone_footer.sign_up')}</span>
                                </div>
                            </div>
                        </Nav.Link>
                    )}

                    {
                        isAuth()
                            ? <Nav.Link as={Link} to="/rewards" className={(location.pathname === "/rewards") ? "col text-center active" : "col text-center"}>
                                <Image src={(location.pathname === "/rewards") ? vip_active_logo : vip_logo} alt={t('phone_footer.vip')} />
                                <span>{t('phone_footer.vip')}</span>
                            </Nav.Link>
                            : <Nav.Link as={Link} to="/reward-points-points-redemption" className={(location.pathname === "/reward-points-points-redemption") ? "col text-center active" : "col text-center"}>
                                <Image src={(location.pathname === "/reward-points-points-redemption") ? reward_active_logo : reward_logo} alt={t('phone_footer.reward')} />
                                <span>{t('phone_footer.reward')}</span>
                            </Nav.Link>
                    }


                    <Nav.Link className="col text-center" onClick={openLiveChat}>
                        <Image src={help_logo} alt={t('phone_footer.livechat')} />
                        <span>{t('phone_footer.livechat')}</span>
                    </Nav.Link>
                </Row>
            </Nav>
        </div>
    );

};


export default MobileFooter;