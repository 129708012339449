import { request, requestWithoutStatusError } from '../Core.js';

export const UserService = {
    unique,
    register,
    login,
    get,
    getTransactions,
    update_profile,
    verify_email,
    verify_phone,
    verify_phone_by_whatsapp,
    verify_identify,
    redeem_points,
    notifications,
    notifications_views,
    transaction_getDetails,
    inboxes,
    inbox_view,
    inbox_send,
    inbox_compose,
    inbox_send_attachment,
    forget_password,
    update_event,
    inactive
};

function unique(params) {
    if (params.phone !== null) {
        params = { ...params, "phone": "+60" + params.phone };
    }
    return new Promise((resolve, reject) => {
        resolve(request('/user/unique', params));
    })
}

function register(params) {
    if (params.phone !== null) {
        params = { ...params, "phone": "+60" + params.phone };
    }
    return new Promise((resolve, reject) => {
        resolve(request('/user/register', params));
    })
}

function login(params) {
    return new Promise((resolve, reject) => {
        resolve(request('/user/login', params));
    });
}

function get(params) {
    return new Promise((resolve, reject) => {
        resolve(request('/user', params));
    });
}

function getTransactions(params) {
    return new Promise((resolve, reject) => {
        resolve(request('/user/getTransactions', params));
    });
}

function update_profile(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/update_profile', params));
    });
}

function verify_email(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/verify_email', params));
    });
}

function verify_phone(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/verify_phone', params));
    });
}


function verify_phone_by_whatsapp(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(requestWithoutStatusError('/user/verify_phone_by_whatsapp', params));
    });
}

function verify_identify(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/verify_identify', params));
    });
}

function redeem_points(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/redeem_points', params));
    });
}

function notifications(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/notifications', params));
    });
}

function notifications_views(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/notifications_views', params));
    });
}

function inboxes(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/inboxes', params));
    });
}

function inbox_view(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/inboxes/inbox_view', params));
    });
}

function inbox_compose(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/inboxes/inbox_compose', params));
    });
}

function inbox_send(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/inboxes/inbox_send', params));
    });
}

function inbox_send_attachment(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/inboxes/inbox_send_attachment', params));
    });
}

function transaction_getDetails(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/transaction/getDetails', params));
    });
}

function forget_password(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/forget_password', params));
    });
}

function update_event(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/update_event', params));
    });
}

function inactive(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/user/inactive', params));
    });
}