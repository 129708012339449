import React from 'react';
import { t } from "i18next";
import { Offcanvas } from "react-bootstrap";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import "./PromoTerms.css";

export const PromoTerms = (props) => {
  const user = props.data.Authentication.user;
  const data = props.data.PromoTerms.data;
  const helpers = props.data.helpers;

  return (
    <>
      <Offcanvas
        id="PromoTerms"
        placement="end"
        backdrop="true"
        className="w-100"
        show={props.data.PromoTerms.status}
        onHide={props.data.PromoTerms.func.close}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("promoTerms.title")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <b>{data.title}</b>
          <table className="table table-bordered fs-small mt-3">
            <thead>
              <tr>
                <th className="w-50">{t('promoTerms.products')}</th>
                <th className="w-50">{t(helpers.getWalletType(data.product))}</th>
              </tr>
            </thead>
            <tbody>

              <tr>
                <td className="font-bold">{t('promoTerms.bonus_percentage')}</td>
                <td>{data.percentage} %</td>
              </tr>

              <tr>
                <td className="font-bold">{t('promoTerms.maximum_bonus')}</td>
                <td>{user.currency} {data.max_bonus_amount}</td>
              </tr>

              <tr>
                <td className="font-bold">{t('promoTerms.minimun_deposit')}</td>
                <td>{user.currency} {data.min_deposit}</td>
              </tr>

              <tr>
                <td className="font-bold">
                  {t('promoTerms.rollover_requirements')}<br />
                  {t('promoTerms.deposit_and_bonus')}
                </td>
                <td>{data.turnover_multiplyer}x</td>
              </tr>

              {!data.odds ? <></> :
                <tr className="terms_odds">
                  <td className="font-bold">{t('promoTerms.minimun_odd')}</td>
                  <td>{data.odds}</td>
                </tr>
              }

            </tbody>
          </table>

          <p className="fs-small">
            <Trans i18nKey="promoTerms.declariation">
              *All bd88 Promotions are subject to the general term of use of the <Link className="text-link" to="/terms-and-conditions" target="_blank">bd88 Standard Promotion Terms and Conditions</Link>.
            </Trans>
          </p>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
