import React from 'react';
import { Button, Card, Col, Container, Row, Modal, Alert } from "react-bootstrap";
import { WalletCard } from "./Components/WalletCard";
import { ProfileBadge } from "../../ProfileBadge/ProfileBadge";
import "./Wallet.css";
import { RecentTransactions } from "./Components/RecentTransactions";
import { t } from "i18next";
import identify_verify_logo from "../../../Assets/Cashier/identify-verify.png";
import { WalletCardLoader } from './Components/WalletCardLoader';

export const Wallet = (props) => {
    const helpers = props.data.helpers;
    const cashierModal = props.data.CashierModal;
    const user = props.user ?? null;

    const deposit = () => {
        cashierModal.func.deposit();
    }

    const withdrawal = () => {
        cashierModal.func.withdrawal();
    }

    const transfer = () => {
        cashierModal.func.transfer();
    }

    const gamelogs = () => {
        cashierModal.func.gamelogs();
    }

    const WalletCards = () => {
        return <div className="wallets">
            {user && user.user_wallets ? <>
                {user.user_wallets.sort((a, b) => {
                    return parseFloat(a.current_balance) > parseFloat(b.current_balance) ? -1 : 1;
                }).map(wallet =>
                    <WalletCard key={wallet.id} data={props.data} user={user} wallet={wallet} setDepositWallet={props.setDepositWallet} setUser={props.setUser} />)
                }
            </> : <>
                {Object.values(helpers.walletTypes()).map((wallet, k) =>
                    <WalletCardLoader key={k} data={props.data} user={user} wallet={wallet} setDepositWallet={props.setDepositWallet} setUser={props.setUser} />
                )}
            </>}

        </div>;
    }

    return <>
        <Modal.Header closeButton>
            <Container className="text-center">
                <span className="title">{t('cashier.title')}</span>
            </Container>
        </Modal.Header>
        <Modal.Body className="p-0">
            <Container className="mb-3" fluid>
                <WalletCards />
            </Container>

            <Card className="card-wallet p-0">
                <Card.Body className="px-0">
                    <Container>
                        <Row className="mb-3">
                            <Col md="6">
                                <ProfileBadge
                                    data={props.data}
                                    user={props.data.Authentication.user}
                                    wallets={props.data.Authentication.wallets} />
                            </Col>
                        </Row>
                        {props.data.Authentication.func.requireVerify() ? <>
                            <Alert variant="verify" className="d-flex align-items-center">
                                <div>
                                    <img src={identify_verify_logo} width={"32px"} alt="" />
                                </div>
                                <div className="ms-2 d-flex w-100 align-items-center justify-content-between">
                                    <h5 className="mb-0 fs-smaller font-bold">{t('profile.verify_title')}</h5>
                                    <button className="btn btn-verify-identify btn-sm btn-default fs-smaller float-end" onClick={props.data.IdentifyVerify.func.open}>{t('profile.verify_button')}</button>
                                </div>
                            </Alert>
                        </> : <></>}
                        <div className="row">
                            <div className='col-6 col-md-3 mb-2'>
                                <Button variant="outline-secondary" size="sm" className="w-100 btn-bold" onClick={deposit}>{t('make_a_deposit')}</Button>
                            </div>
                            <div className='col-6 col-md-3 mb-2'>
                                <Button variant="outline-secondary" size="sm" className="w-100 btn-bold" onClick={withdrawal}>{t('make_a_withdrawal')}</Button>
                            </div>
                            <div className='col-6 col-md-3 mb-2'>
                                <Button variant="outline-secondary" size="sm" className="w-100 btn-bold" onClick={transfer}>{t('make_a_transfer')}</Button>
                            </div>
                            <div className='col-6 col-md-3 mb-2'>
                                <Button variant="outline-secondary" size="sm" className="w-100 btn-bold" onClick={gamelogs}>{t('check_gamelogs')}</Button>
                            </div>
                        </div>
                    </Container>
                    <hr />
                    <RecentTransactions data={props.data} />
                </Card.Body>
            </Card>
        </Modal.Body>
    </>;
}