import React, { useEffect, useState } from "react";
import "./style.css";

export const TopBar = (props) => {
    // const { t } = useTranslation();
    const helpers = props.data.helpers;
    const [currentTopBar, setCurrentTopBar] = useState(null);

    useEffect(() => {
        if (props.data.Campaign.topbars.length === 0) {
            return false;
        }
        setCurrentTopBar(shuffle(props.data.Campaign.topbars)[0]);
        props.data.TopBar.func.open();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.Campaign.topbars])

    function shuffle(a) {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }
        return a;
    }

    if (!currentTopBar) {
        return <div className="topbar">
            <div className="container px-lg-4">
                <div className="d-flex align-items-center justify-between placeholder-glow">
                    <span className="placeholder me-auto"></span>
                    <button className="btn btn-close" onClick={() => props.data.TopBar.func.close()}></button>
                </div>
            </div>
        </div>;
    }

    if (!props.data.TopBar.status) {
        return <></>;
    }

    return <div className="topbar">
        <div className="container px-lg-4">
            <div className="d-flex align-items-center justify-between">
                <a href={props.data.Campaign.topbars[0].url} className="text-white font-bolder text-decoration-underline me-auto">
                    {helpers.t(props.data.Campaign.topbars[0].text)}
                </a>
                <button className="btn btn-close" onClick={() => props.data.TopBar.func.close()}></button>
            </div>
        </div>
    </div>;
};

export default TopBar;
