import React from 'react';
import { t } from "i18next"
import { Card } from "react-bootstrap"
import { WalletService } from "../../../../Models/User/WalletService";

export const WalletCard = (props) => {
    const toast = props.data.toast;

    function openDeposit() {
        props.data.CashierModal.func.deposit();
        props.setDepositWallet(props.wallet.id)
    }

    async function redeemRebate() {
        WalletService.redeem_rebate({ wallet: props.wallet.id }).then(response => {
            if (response !== undefined) {
                if (response.status) {
                    toast.success(t(response.message));
                    props.data.Authentication.func.get().then((response) => {
                        props.setUser(response);
                    });
                } else {
                    toast.error(t(response.message));
                }
            }
        });
    }

    return <Card className="wallet">
        <Card.Body>
            <Card.Title>{t(props.wallet.name)}</Card.Title>
            <div className="balance-info">
                <span className="currency">{props.user.currency}</span>
                <span className="balance">{parseFloat(props.wallet.current_balance).toFixed(2)}</span>
            </div>
            <div className="turnover-info">
                <span className="title d-block">{t('cashier.turnover_left')} {props.wallet.turnover}</span>
                <span className="title d-block">{t('cashier.rebate')} : {props.wallet.rebate}
                    {props.wallet.rebate > 0 ? <span className="ms-1 font-boldest text-link" onClick={() => redeemRebate()}>{t('cashier.redeem')}</span> : <></>}
                </span>
            </div>
        </Card.Body>
        <Card.Footer>
            <span className="action" onClick={openDeposit}>
                {t('cashier.deposit_now')}
            </span>
        </Card.Footer>
    </Card>
}