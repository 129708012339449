import {  useState } from "react";

export default function InactivePopupHook() {
    const [status, setStatus] = useState(false);
    const [content, setContent] = useState(null);
    const open = () => setStatus(true);
    const close = () => setStatus(false);

    return {
        status: status,
        content: content,
        func: {
            open,
            close,
            setContent,
        },
    };
}