import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Modal, Container, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { UserService } from "../../Models/User/UserService";
import "./ForgetPasswordModal.css";

export const ForgetPasswordModal = (props) => {
    const [tab, setTab] = useState("email");
    const [isWaitCode, setIsWaitCode] = useState(false);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const [form, setForm] = useState({
        email: null,
        phone: null,
        code: null,
        password: null,
        password_confirmation: null,
    });

    const [errors, setErrors] = useState({
        email: null,
        phone: null,
        code: null,
        password: null,
        password_confirmation: null,
    });

    const validator = new SimpleReactValidator({
        element: false,
        validators: {
            same: {  // name the rule
                rule: (val, params, validator) => {
                    return (form[params]) ? val === form[params] : true;
                },
            },
            phone_format: {
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^(1)[0-46-9][0-9]{7,8}$/i) && params.indexOf(val) === -1;
                },
            }
        }
    });

    const validate = () => {
        return new Promise(async function (resolve, reject) {
            var $errors = {};
            for (const [key, value] of Object.entries(validator.fields)) {
                if (!value) {
                    $errors[key] = validator.errorMessages[key];
                } else {
                    $errors[key] = null;
                }
            }

            return resolve($errors);
        });
    }

    validator.message('code', form.code, 'required', {
        messages: {
            required: t('code.required'),
        },
    });

    validator.message('phone', form.phone, 'phone_format', {
        messages: {
            phone_format: t('phone.phone_format'),
        },
    });

    validator.message('email', form.email, 'email', {
        messages: {
            email: t('email.email'),
        },
    });

    validator.message('password', form.password, 'required|min:6', {
        messages: {
            required: t('password.required'),
            min: t('password.min'),
        },
    });

    validator.message('password_confirmation', form.password_confirmation, 'required|min:6|same:password', {
        messages: {
            required: t('password_confirmation.required'),
            min: t('password_confirmation.min'),
            same: t('password_confirmation.same'),
        },
    });

    useEffect(() => {
        setForm({ email: null, phone: null, code: null, password: null, password_confirmation: null });
        setIsWaitCode(false);
        setIsResetPassword(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab]);

    useEffect(() => {
        validate().then(errors => setErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const handleInputs = name => event => {
        setForm({ ...form, [name]: event.target.value });
    };

    const back = () => {
        props.data.ForgetPasswordModal.func.close();
        props.data.LoginModal.func.open();
    }

    const submit = (e) => {
        setIsSubmit(true);
        e.preventDefault();

        UserService.forget_password({ ...form, tab: tab, isWaitCode: isWaitCode, isResetPassword: isResetPassword }).then(response => {
            setIsSubmit(false);
            if (response !== undefined && response.status) {
                if (!isWaitCode) {
                    setIsWaitCode(true);
                } else {
                    if (!isResetPassword) {
                        setIsResetPassword(true);
                    } else {
                        toast.success(t(response.message));
                        close();
                    }
                }
            } else {
                toast.error(t(response.message));
            }
        });
    }

    const close = () => {
        props.data.ForgetPasswordModal.func.close();
    }

    return <Modal
        className="ForgetPasswordModal"
        size="lg"
        show={props.data.ForgetPasswordModal.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Header closeButton>
            <button type="button" className="btn-back" aria-label="Back" onClick={back}></button>
            <Container className="text-center">
                <h4 className="mb-0">{t("forget_password.title")}</h4>
            </Container>
        </Modal.Header>
        <Modal.Body>
            <ul className="nav nav-tabs mb-3">
                <li className="nav-item w-50 text-center">
                    <span className={tab === "email" ? "nav-link text-link active" : "nav-link text-link"} onClick={() => setTab("email")}>
                        {t('forget_password.reset_by_email')}
                    </span>
                </li>
                <li className="nav-item w-50 text-center">
                    <span className={tab === "sms" ? "nav-link text-link active" : "nav-link text-link"} onClick={() => setTab("sms")}>
                        {t('forget_password.reset_by_sms')}
                    </span>
                </li>
            </ul>

            <Form onSubmit={submit} noValidate>
                {tab === "sms"
                    ? <>
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel className="form-control-phone" controlId="forget_password_phone" label={t('phone')}>
                                <Form.Control
                                    name="phone"
                                    type="text"
                                    value={form.phone == null ? '' : form.phone}
                                    onChange={handleInputs("phone")}
                                    placeholder=" "
                                    isInvalid={form.phone !== null && errors.phone !== null}
                                    isValid={form.phone !== null && errors.phone === null}
                                    autoComplete="none"
                                />
                                <div className="form-control-prefix"><span>+60</span></div>

                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </>
                    : <></>
                }
                {tab === "email"
                    ? <>
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel controlId="forget_password_email" label={t('email')}>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    value={form.email == null ? '' : form.email}
                                    onChange={handleInputs("email")}
                                    placeholder=" "
                                    isInvalid={form.email !== null && errors.email !== null}
                                    isValid={form.email !== null && errors.email === null}
                                    autoComplete="none"
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>
                    </>
                    : <></>
                }

                {
                    isWaitCode
                        ? <>
                            <Form.Group as={Col} xs={12} className="mb-3">
                                <FloatingLabel controlId="forget_password_code" label={t('forget_password.code')}>
                                    <Form.Control
                                        name="code"
                                        type="text"
                                        value={form.code == null ? '' : form.code}
                                        onChange={handleInputs("code")}
                                        placeholder=" "
                                        isInvalid={form.code !== null && errors.code !== null}
                                        isValid={form.code !== null && errors.code === null}
                                        autoComplete="none"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.code}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </>
                        : <></>
                }

                {
                    isResetPassword
                        ? <>
                            {/* Password Input */}
                            <Form.Group as={Col} xs={12} className="mb-3">
                                <FloatingLabel controlId="register_password" label={t('password')}>
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        value={form.password == null ? '' : form.password}
                                        onChange={handleInputs("password")}
                                        placeholder=" "
                                        isInvalid={form.password !== null && errors.password !== null}
                                        isValid={form.password !== null && errors.password === null}
                                        autoComplete="none"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>

                            {/* Password Input */}
                            <Form.Group as={Col} xs={12} className="mb-3">
                                <FloatingLabel controlId="register_password_confirmation" label={t('password_confirmation')}>
                                    <Form.Control
                                        name="password_confirmation"
                                        type="password"
                                        value={form.password_confirmation == null ? '' : form.password_confirmation}
                                        onChange={handleInputs("password_confirmation")}
                                        placeholder=" "
                                        isInvalid={form.password_confirmation !== null && errors.password_confirmation !== null}
                                        isValid={form.password_confirmation !== null && errors.password_confirmation === null}
                                        autoComplete="none"
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.password_confirmation}</Form.Control.Feedback>
                                </FloatingLabel>
                            </Form.Group>
                        </>
                        : <></>
                }


                <Col xs={12} className="mb-3">
                    <div className="d-grid gap-2">
                        <Button type="submit" disabled={isSubmit}>
                            {t('forget_password.submit')}
                        </Button>
                    </div>
                </Col>
            </Form>
        </Modal.Body>
    </Modal>
}