import { useState } from "react";

export default function PhoneVerifyHook() {
    const [status, setStatus] = useState(false);
    const [step, setStep] = useState(1);
    const open = () => setStatus(true);
    const close = () => setStatus(false);

    return {
        status: status,
        step: step,
        func: { open, close, setStep },
    };
}