import "./WorldCupGiveaway.css";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import i18next from "i18next";
import deposit_en from "./assets/angpow/deposit_en.png";
import deposit_cn from "./assets/angpow/deposit_cn.png";
import claim_en from "./assets/angpow/claim_en.png";
import claim_cn from "./assets/angpow/claim_cn.png";
import claim_grey_en from "./assets/angpow/claim_grey_en.png";
import claim_grey_cn from "./assets/angpow/claim_grey_cn.png";

import "./Angpow.css";
import moment from "moment";
import { SpecialEventsService } from "../../Models/SpecialEvents/SpecialEventsService";

export const Angpow = (props) => {
    const { Authentication, Angpow, CashierModal, toast } = props.data;
    const [canRedeem, setCanReeem] = useState(false);

    const getLanguage = () => {
        if (i18next.language === "zh_CN") {
            return "cn";
        }
        return "en";
    }

    const deposit = () => {
        Angpow.func.close();
        CashierModal.func.deposit();
    }

    const redeem = () => {
        setCanReeem(false);
        SpecialEventsService.angpow_redeem().then(response => {
            if (response) {
                if (!response.status) {
                    toast.error(response.message);
                    return false
                }
                toast.success(response.message);
                Angpow.func.close();
                return true;
            }
        });
    }

    useEffect(() => {
        if (!Authentication.func.isLogin()) {
            return false;
        }
        SpecialEventsService.angpow_index().then(response => {
            setCanReeem(response.status);
        })
        return () => { };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (moment() < moment("2023-01-20 00:00:00") || moment() > moment("2023-02-06 00:00:00")) {
        return <></>;
    }

    if (!Authentication.func.isLogin()) {
        return <></>;
    }

    return <Modal
        className={getLanguage() === "en" ? "angpowModal" : "angpowModal cn"}
        size="lg"
        show={Angpow.status}
        onHide={Angpow.func.close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Body className="p-0">
            <div className="angpow-body">
                <button type="button" className="btn-close" aria-label="Close" onClick={Angpow.func.close}></button>
                <div className="angpow-sentence">
                    <div className="angpow-deposit" onClick={deposit}>
                        {getLanguage() === "en" ? <img src={deposit_en} alt="deposit" /> : <img src={deposit_cn} alt="deposit" />}
                    </div>
                    <div className="angpow-claim">
                        {canRedeem ? <>
                            <div className="angpow-claim" onClick={redeem}>
                                {getLanguage() === "en" ? <img src={claim_en} alt="claim" /> : <img src={claim_cn} alt="claim" />}
                            </div>
                        </> : <>
                            <div className="angpow-claim">
                                {getLanguage() === "en" ? <img src={claim_grey_en} alt="claim" /> : <img src={claim_grey_cn} alt="claim" />}
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>
};

export default Angpow;
