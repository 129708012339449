import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import "./CashierModal.css";
import { Wallet } from "./Wallet/Wallet";
import { Transfer } from "./Transfer/Transfer";
import Loading from "../Loading/Loading";
import { Withdrawal } from "./Withdrawal/Withdrawal";
import { Deposit } from "./Deposit/Deposit";
import { Gamelogs } from "./Gamelog/Gamelog";
import { QuickTransfer } from "./QuickTransfer/QuickTransfer";

export const CashierModal = (props) => {
    const cashierModal = props.data.CashierModal;
    const [user, setUser] = useState(null);
    const [depositWallet, setDepositWallet] = useState(null);

    const authentication = props.data.Authentication;

    const fetchUserBalance = async () => {
        authentication.func.get().then(response => {
            setUser(response);
        });
    }

    const removeUserBalance = async () => {
        setUser(null);
    }

    useEffect(() => {
        if (cashierModal.status === true) {
            fetchUserBalance();
        } else {
            removeUserBalance();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashierModal.status]);

    const close = () => {
        cashierModal.func.close();
    }
    const getPage = () => {
        if (user === null) {
            return <Loading />;
        }

        if (cashierModal.page === "deposit") {
            return <Deposit data={props.data} user={user} depositWallet={depositWallet} setDepositWallet={setDepositWallet} />;
        }

        if (cashierModal.page === "transfer") {
            return <Transfer data={props.data} setUser={setUser} user={user} />;
        }

        if (cashierModal.page === "withdrawal") {
            return <Withdrawal data={props.data} setUser={setUser} user={user} />;
        }

        if (cashierModal.page === "wallet") {
            return <Wallet data={props.data} user={user} setUser={setUser} setDepositWallet={setDepositWallet} />;
        }

        if (cashierModal.page === "gamelogs") {
            return <Gamelogs data={props.data} user={user} setUser={setUser} />;
        }

        if (cashierModal.page === "quick_transfer") {
            return <QuickTransfer data={props.data} setUser={setUser} user={user} />;
        }
    }

    return <Modal
        className="CashierModal"
        size="lg"
        show={cashierModal.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        {getPage()}
    </Modal>
}