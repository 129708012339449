import { request } from '../Core.js';

export const GameService = {
    products,
    product_maintenances,
    games,
    like,
    launch,
};

function products(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/products', params));
    });
}

function product_maintenances(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/products/product_maintenances', params));
    });
}

function games(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/products/games', params));
    });
}

function like(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/products/games/like', params));
    });
}


function launch(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/products/launch', params));
    });
}
