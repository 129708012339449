import React, { useState, useEffect } from "react";
import { Offcanvas, Container, CloseButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./SearchCanvas.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SearchService } from "../../Models/Search/SearchService";
import GameCard from "../Games/GameCard";
import PromoCard from "../../Pages/Promos/PromoCard";
import Loading from "../Loading/Loading";

export const SearchCanvas = (props) => {
    const { t } = useTranslation();
    const [form, setForm] = useState({
        query: "",
        category: "all",
    });
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const newGames = props.data.NewGame;
    const hotGames = props.data.PopularGame;

    useEffect(() => {
        const loadData = () => {
            if (form.query !== "") {
                setIsLoading(true);
                SearchService.search(form).then(response => {
                    if (response.status) {
                        setData(response.data);
                    }
                    setIsLoading(false);
                });
            } else {
                setData(null);
            }
        }

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.query, form.category]);

    const close = () => {
        setForm({ ...form, query: "" });
        setData(null);
        props.data.SearchCanvas.func.close()
    }

    if (!props.data.SearchCanvas.status) {
        return <></>;
    }

    return <Offcanvas
        id="SearchCanvas"
        aria-labelledby="SearchCanvasLabel"
        placement="end"
        backdrop="true"
        className="w-100"
        show={props.data.SearchCanvas.status}
        onHide={props.data.SearchCanvas.func.close}
    >
        <Offcanvas.Body>
            <Offcanvas.Title className="text-center mb-4">
                <CloseButton onClick={close} />
                <h3 className="fw-bold">{t('search_canvas.search')}</h3>
            </Offcanvas.Title>
            <Container>
                <div className="header-wrap">
                    <div className="header-search" data-search="">
                        <div className="input-group input-group-search">
                            <input className="form-control" type="search" placeholder={t("search.placeholder")} onChange={e => setForm({ ...form, query: e.target.value })} />
                            <span className="input-group-prepend">
                                <div className="input-group-text border-0 bg-transparent">
                                    <FontAwesomeIcon icon={solid('magnifying-glass')} />
                                </div>
                            </span>
                        </div>
                    </div>
                    {
                        form.query !== "" ? <>
                            <div className="mt-3">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="category" id="search_filter_all" value="all" onClick={() => setForm({ ...form, category: "all" })} checked={form.category === "all"} />
                                    <label class="form-check-label" for="search_filter_all">{t("all")}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="category" id="search_filter_slots" value="slots" onClick={() => setForm({ ...form, category: "slots" })} checked={form.category === "slots"} />
                                    <label class="form-check-label" for="search_filter_slots">{t('slots')}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="category" id="search_filter_live_dealer" value="live_dealer" onClick={() => setForm({ ...form, category: "live_dealer" })} checked={form.category === "live_dealer"} />
                                    <label class="form-check-label" for="search_filter_live_dealer">{t('live_dealer')}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" name="category" id="search_filter_sportsbook" value="sportsbook" onClick={() => setForm({ ...form, category: "sportsbook" })} checked={form.category === "sportsbook"} />
                                    <label class="form-check-label" for="search_filter_sportsbook">{t('sportsbook')}</label>
                                </div>
                            </div>
                        </> : <>  </>
                    }
                </div>
                <hr></hr>

                <div className="search-list">
                    {isLoading ? <Loading /> : <>
                        {form.query === "" ? <>
                            {newGames && newGames.length > 0 ? <>
                                <h4>{t('game.new_games')}</h4>
                                {newGames.map((element, i) => {
                                    return <GameCard data={props.data} key={i} element={element} isShow={true} />
                                })}
                            </> : <></>}
                            {hotGames && hotGames.length > 0 ? <>
                                <h4>{t('game.new_games')}</h4>
                                {hotGames.map((element, i) => {
                                    return <GameCard data={props.data} key={i} element={element} isShow={true} />
                                })}
                            </> : <></>}
                        </> : <>
                            <div className="mb-3">
                                {data && data.games.length > 0 ? <h4>{t('search_canvas.games')}</h4> : <>
                                    <h4>{t('search_canvas.games')}</h4>
                                    {t('search_canvas.no_games_result')}
                                </>}
                                {data ? data.games.map((element, i) => {
                                    return <GameCard data={props.data} key={i} element={element} isShow={true} />
                                }) : <></>}
                            </div>
                            <div className="mb-3">
                                {data && data.pages.length > 0 ? <h4>{t('search_canvas.pages')}</h4> : <>
                                    <h4>{t('search_canvas.pages')}</h4>
                                    {t('search_canvas.no_page_result')}
                                </>}
                                {data ? data.pages.map((element, i) => {
                                    return <PromoCard data={props.data} key={i} value={element} close={() => close()} />
                                }) : <></>}
                            </div>
                        </>
                        }
                    </>}
                </div>
            </Container>
        </Offcanvas.Body>
    </Offcanvas>;
};


export default SearchCanvas;