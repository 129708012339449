import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./style.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css'

export const Popup = (props) => {
    const helpers = props.data.helpers;
    const popups = props.data.Campaign.popups;

    useEffect(() => {
        if (popups.length === 0) {
            return false;
        }
        props.data.Popup.func.open();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [popups])

    return <Modal
        className="PopupModal"
        size="lg"
        show={props.data.Popup.status}
        backdrop="static"
        keyboard={false}
        animation={false}
    >

        <Modal.Body>
            <Swiper
                slidesPerView="1"
            >

                {popups.map((value, index) => {
                    return <SwiperSlide key={index}>
                        <div className="swiper-slide-inner">
                            <a href={value.url}>
                                <img src={"https://cdn.bd88my.online/" + (window.innerWidth < 768 ? helpers.t(value.image_mobile) : helpers.t(value.image_desktop))} alt={value.name} />
                            </a>
                        </div>
                    </SwiperSlide>
                })}
            </Swiper>

            <button type="button" className="btn-close" aria-label="Close" onClick={props.data.Popup.func.close}></button>
        </Modal.Body>
    </Modal>;
};

export default Popup;
