import { t } from "i18next";
import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import { toast } from "react-toastify";
import { UserService } from "../../Models/User/UserService";

export const PhoneVerify = (props) => {
    const authentication = props.data.Authentication;
    const PhoneVerify = props.data.PhoneVerify;

    const [isSubmit, setIsSubmit] = useState(false);
    const [form, setForm] = useState({
        phone: authentication.user.phone ? authentication.user.phone.replace("+60", "") : null,
        code: null,
    });
    const [verifyText, setVerifyText] = useState("");


    const close = () => {
        PhoneVerify.func.close()
        PhoneVerify.func.setStep(1);
    }

    const handleInputs = name => event => {
        setForm({ ...form, [name]: event.target.value });
    };

    const verify = async (e) => {
        setIsSubmit(true);
        e.preventDefault();

        UserService.verify_phone_by_whatsapp({ ...form, step: PhoneVerify.step }).then((response) => {
            setIsSubmit(false);
            if (response.status) {
                toast.success(t(response.message));
                if (PhoneVerify.step === 1) {
                    setVerifyText(response.token);
                    PhoneVerify.func.setStep(2);

                    var intervalWA = null;
                    intervalWA = setInterval(function () {
                        UserService.verify_phone_by_whatsapp({ ...form, step: 2 }).then(async (response) => {
                            if (response && response.status) {
                                toast.success(t(response.message));
                                props.data.Authentication.func.get();
                                close();
                                clearInterval(intervalWA);
                            }
                        });
                    }, 1000);
                } else {
                }
            }
        }).catch((res) => {
            setIsSubmit(false);
        })
    }

    const step_one = () => {
        return <>
            <Form onSubmit={verify}>
                <p>{t('phone_verify.description')}</p>
                <Row>
                    <Col xs={12} className="mb-3">
                        <FloatingLabel className="form-control-phone" label={t('phone')}>
                            <Form.Control
                                name="phone"
                                type="text"
                                value={form.phone == null ? '' : form.phone}
                                onChange={handleInputs("phone")}
                                placeholder={t("phone_verify.phone")}
                                autoComplete="none"
                                readOnly
                            />
                            <div className="form-control-prefix"><span>+60</span></div>

                        </FloatingLabel>
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button className="btn-sm w-100 d-flex justify-content-center align-items-center" disabled={isSubmit} onClick={verify}>
                            {t('phone_verify.next')}
                            <img src="/assets/icons/WhatsApp.png" alt="whatsapp" style={{ height: "1.5rem", marginLeft: ".5rem" }} />
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>;
    }

    const step_two = () => {
        return <>
            <Form onSubmit={verify}>
                {props.data.isMobile ? <>
                    <p>{t('phone_verify.whatsapp_description_mobile')}</p>
                </> : <p>{t('phone_verify.whatsapp_description_desktop')}</p>}


                <Row>
                    <Col xs={12} className="text-center mb-3">
                        {props.data.isMobile ? <></> : <>
                            <img src={"https://api.38386868.com/qrcode?link=https://wa.me/60108640962?text=" + verifyText} alt="qrcode" style={{ height: "200px", width: "200px" }} />
                        </>}
                    </Col>
                    <Col xs={12} className="mb-3">
                        <FloatingLabel className="form-control-phone" label={t('phone')}>
                            <Form.Control
                                name="phone"
                                type="text"
                                value={form.phone == null ? '' : form.phone}
                                onChange={handleInputs("phone")}
                                placeholder={t("phone_verify.phone")}
                                autoComplete="none"
                                readOnly={true}
                            />
                            <div className="form-control-prefix"><span>+60</span></div>
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} className="text-center">
                        {props.data.isMobile ? <>
                            <a className="w-100 btn btn-primary w-100 mb-2" href={"https://wa.me/60108640962?text=" + verifyText} target="_blank" rel="noreferrer">{t('phone_verify.whatsapp_send')}</a>
                        </> : <></>}
                    </Col>
                </Row>
            </Form>
        </>;
    }

    return <Modal
        className="PhoneVerify"
        size="lg"
        show={PhoneVerify.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('phone_verify.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {PhoneVerify.step === 1 ? <>{step_one()}</> : <></>}
            {PhoneVerify.step === 2 ? <>{step_two()}</> : <></>}
        </Modal.Body>
    </Modal>
}