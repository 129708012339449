import React from "react";
import like from "../../Assets/Game/like.png";

function GameCardPlaceholder(props) {

    return <div className="game-card show">
        <div className="placeholder-glow w-100">
            <span className="placeholder image-square" style={{ width: "100% !important", borderRadius: "5px" }}></span>
        </div>
        <div className="details-wrapper d-flex align-items-center justify-content-between mt-2 w-100 placeholder-glow">
            <div className="game-card-desc">
                <span className="d-block font-bolder fs-small white-space-nowrap placeholder mb-2"></span>
                <span className="d-block fs-smaller placeholder mb-2"></span>
            </div>

            <div className="game-card-action" >
                <img className="like" src={like} alt="like" />
            </div>
        </div>
    </div>;
}
export default GameCardPlaceholder;