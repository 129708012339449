import { request } from '../Core.js';

export const ReferralService = {
    index,
    upgrade,
    withdraw,
    withdrawal_banks,
};

function index(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/referral', params));
    });
}

function upgrade(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/referral/upgrade', params));
    });
}

function withdraw(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/referral/withdraw', params));
    });
}

function withdrawal_banks(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/referral/withdrawal_banks', params));
    });
}
