import { t } from "i18next";
import React, { useState } from "react";
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import { toast } from "react-toastify";
import { UserService } from "../../Models/User/UserService";

export const EmailVerify = (props) => {
    const authentication = props.data.Authentication;
    const EmailVerify = props.data.EmailVerify;

    const [isSubmit, setIsSubmit] = useState(false);
    const [form, setForm] = useState({
        email: authentication.user.email,
        code: null,
    });

    const close = () => {
        EmailVerify.func.close()
        EmailVerify.func.setStep(1);
    }

    const handleInputs = name => event => {
        setForm({ ...form, [name]: event.target.value });
    };

    const verify = async (e) => {
        setIsSubmit(true);
        e.preventDefault();

        UserService.verify_email({ ...form, step: EmailVerify.step }).then((response) => {
            setIsSubmit(false);
            if (response.status) {
                toast.success(t(response.message));
                if (EmailVerify.step === 1) {
                    EmailVerify.func.setStep(2);
                } else {
                    props.data.Authentication.func.get();
                    close();
                }
            }
        }).catch((res) => {
            setIsSubmit(false);
        })
    }

    const step_one = () => {
        return <>
            <Form onSubmit={verify}>
                <p>{t('email_verify.description')}</p>
                <Row>
                    <Col xs={12} className="mb-3">
                        <FloatingLabel label={t('email_verify.email')}>
                            <Form.Control type="email" value={form.email} onChange={handleInputs("email")} placeholder={t("email_verify.email")} />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} className="text-center">
                        <Button className="w-100" disabled={isSubmit} onClick={verify}>{t('email_verify.next')}</Button>
                    </Col>
                </Row>
            </Form>
        </>;
    }

    const step_two = () => {
        return <>
            <Form onSubmit={verify}>
                <p>{t('email_verify.description')}</p>
                <Row>
                    <Col xs={12} className="mb-3">
                        <FloatingLabel label={t('email_verify.email')}>
                            <Form.Control type="email" value={form.email} onChange={handleInputs("email")} readOnly={true} placeholder={t("email_verify.email")} />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} className="mb-3">
                        <FloatingLabel label={t('email_verify.verify_code')}>
                            <Form.Control type="text" value={form.code} onChange={handleInputs("code")} placeholder={t("email_verify.verify_code")} />
                        </FloatingLabel>
                    </Col>
                    <Col xs={12} className="text-center mb-3">
                        <Button className="w-100" disabled={isSubmit} onClick={verify}>{t('email_verify.next')}</Button>
                    </Col>
                </Row>
            </Form>
        </>;
    }

    return <Modal
        className="EmailVerify"
        size="lg"
        show={EmailVerify.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Header closeButton>
            <Modal.Title>{t('email_verify.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {EmailVerify.step === 1 ? <>{step_one()}</> : <></>}
            {EmailVerify.step === 2 ? <>{step_two()}</> : <></>}
        </Modal.Body>
    </Modal>
}