import React from 'react';
import { t } from "i18next";
import { Offcanvas, CloseButton } from "react-bootstrap";
import "./PaymentCanvas.css";

export const PaymentCanvas = (props) => {
    return <Offcanvas
        id="PaymentCanvas"
        aria-labelledby="PaymentCanvasLabel"
        placement="end"
        backdrop="true"
        className="w-100"
        show={props.data.PaymentCanvas.status}
        onHide={props.data.PaymentCanvas.func.close}
    >
        <Offcanvas.Body>
            <Offcanvas.Title className="text-center">
                <CloseButton onClick={props.data.PaymentCanvas.func.close} />
            </Offcanvas.Title>
            {props.data.PaymentCanvas.data ? <iframe title={t('transaction.payments')} src={props.data.PaymentCanvas.data} height="100%" width="100%" /> : <></> }
        </Offcanvas.Body>
    </Offcanvas>;
};


export default PaymentCanvas;