import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, FloatingLabel, Form, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { UserService } from "../../Models/User/UserService";
import "./RegisterModal.css";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import PasswordChecklist from "react-password-checklist"
import { sign_up } from "../../Models/Pixel/Pixel";

export const RegisterModal = (props) => {
    const { t } = useTranslation();
    const [isSubmit, setIsSubmit] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordChecklist, setShowPasswordChecklist] = useState(false);

    const [form, setForm] = useState({
        email: null,
        password: null,
        password_confirmation: null,
        username: null,
        phone: null,
        full_name: null,
        referral: null,
        fingerprint: null,
    });

    const [errors, setErrors] = useState({
        email: null,
        password: null,
        password_confirmation: null,
        username: null,
        phone: null,
        full_name: null,
    });

    const validator = new SimpleReactValidator({
        element: false,
        validators: {
            same: {  // name the rule
                rule: (val, params, validator) => {
                    return (form[params]) ? val === form[params] : true;
                },
            },
            phone_format: {
                rule: (val, params, validator) => {
                    return validator.helpers.testRegex(val, /^(1)[0-46-9][0-9]{7,8}$/i) && params.indexOf(val) === -1;
                },
            }
        }
    });

    useEffect(() => {
        if (window.register_validation) {
            window.clearTimeout(window.register_validation);
        }

        window.register_validation = setTimeout(() => {
            validate().then(errors => setErrors(errors));
        }, 250);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    useEffect(() => {
        sign_up();
        if (sessionStorage.getItem('referral')) {
            setForm({ ...form, referral: sessionStorage.getItem('referral') });
        } else {
            setForm({ ...form, referral: null });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data.RegisterModal.status])

    useEffect(() => {
        FingerprintJS.load().then(async fp => {
            const { visitorId } = await fp.get();
            setForm({ ...form, fingerprint: visitorId });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleInputs = name => event => {
        setForm({ ...form, [name]: event.target.value });
    };

    const login = () => {
        props.data.RegisterModal.func.close();
        props.data.LoginModal.func.open();
    }

    const validate = () => {
        return new Promise(async function (resolve, reject) {
            var $errors = {};
            for (const [key, value] of Object.entries(validator.fields)) {
                if (!value) {
                    $errors[key] = validator.errorMessages[key];
                } else {
                    $errors[key] = null;
                }
            }

            var email = false;
            var username = false;
            var phone = false;

            /* eslint-disable no-unused-vars */
            for (const [key, value] of Object.entries(validator.fields)) {
                if (key === "email" && $errors[key] === null) {
                    email = true;
                }

                if (key === "username" && $errors[key] === null) {
                    username = true;
                }

                if (key === "phone" && $errors[key] === null) {
                    phone = true;
                }
            }
            /* eslint-enable no-unused-vars */

            if (email || username || phone) {
                await UserService.unique({
                    email: form.email,
                    username: form.username,
                    phone: form.phone
                }).then((response) => {
                    if (response.status) {
                        if (response.email === false) {
                            $errors["email"] = t('email.unique');
                        }
                        if (response.username === false) {
                            $errors["username"] = t('username.unique');
                        }
                        if (response.phone === false) {
                            $errors["phone"] = t('phone.unique');
                        }
                    } else {
                        props.data.toast.error(t('network.error'));
                    }
                })
            }

            return resolve($errors);
        });
    };

    const register = async (e) => {
        setIsSubmit(true);
        e.preventDefault();

        if (!validator.allValid()) {
            setForm({
                email: form.email ?? "",
                password: form.password ?? "",
                password_confirmation: form.password_confirmation ?? "",
                username: form.username ?? "",
                phone: form.phone ?? "",
                full_name: form.full_name ?? "",
                referral: form.referral ?? "",
            })
            validator.showMessages();
            setIsSubmit(false);
            return false;
        }

        await UserService.register(form).then((response) => {
            setIsSubmit(false);
            if (!response.status) {
                toast.error(t(response.message));
                return false;
            }
            props.data.Authentication.func.updateAuthentication(response.token);
            props.data.RegisterModal.func.close();
            props.data.Campaign.update();
            if (window.fbq) {
                window.fbq('track', 'CompleteRegistration');
            }
            if (window.gtag) {
                window.gtag("event", "sign_up", {
                    method: "Manual"
                });
            }

            props.data.CashierModal.func.deposit();
        }).catch((res) => {
            setIsSubmit(false);
        })
        return false;
    }

    const close = () => {
        setForm({
            email: null,
            password: null,
            password_confirmation: null,
            username: null,
            phone: null,
            full_name: null,
        });
        props.data.RegisterModal.func.close();
    }

    validator.message('email', form.email, 'required|email', {
        messages: {
            required: t('email.required'),
            email: t('email.email'),
        },
    });

    validator.message('password', form.password, 'required|min:6', {
        messages: {
            required: t('password.required'),
            min: t('password.min'),
        },
    });

    validator.message('password_confirmation', form.password_confirmation, 'required|min:6|same:password', {
        messages: {
            required: t('password_confirmation.required'),
            min: t('password_confirmation.min'),
            same: t('password_confirmation.same'),
        },
    });

    validator.message('username', form.username, 'required|string|min:6|max:20|alpha_num_dash_space', {
        messages: {
            required: t('username.required'),
            min: t('username.min'),
            max: t('username.max'),
            alpha_num_dash_space: t('username.alpha_num_dash_space'),
        },
    });

    validator.message('phone', form.phone, 'required|phone_format', {
        messages: {
            required: t('phone.required'),
            phone_format: t('phone.phone_format'),
        },
    });

    validator.message('full_name', form.full_name, 'required|min:4', {
        messages: {
            required: t('full_name.required'),
            min: t('full_name.min'),
        },
    });

    // return <Modal
    //     className="RegisterModal"
    //     size="lg"
    //     show={props.data.RegisterModal.status}
    //     onHide={close}
    //     backdrop="static"
    //     keyboard={false}
    //     animation={false}
    // >
    //     <button type="button" className="btn-close" aria-label="Close" onClick={props.data.RegisterModal.func.close}></button>
    //     <a href="https://www.bk8goals.com/en-my/home?affid=4314">
    //         <img src="/assets/strongertogether.jpg" style={{ width: "100vw" }} />
    //     </a>
    // </Modal>;

    return <Modal
        className="RegisterModal"
        size="lg"
        show={props.data.RegisterModal.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Body>
            <Form onSubmit={register} noValidate>
                <button type="button" className="btn-close" aria-label="Close" onClick={props.data.RegisterModal.func.close}></button>
                <Container>
                    <Row>
                        <Col xs={12} className="mb-2"><h3 className="modal-title mt-3">{t('register.title')}</h3></Col>
                        {/* Email Input */}
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel controlId="register_email" label={t('email')}>

                                <Form.Control
                                    name="email"
                                    type="email"
                                    value={form.email == null ? '' : form.email}
                                    onChange={handleInputs("email")}
                                    placeholder=" "
                                    isInvalid={form.email !== null && errors.email !== null}
                                    isValid={form.email !== null && errors.email === null}
                                    autoComplete="none"
                                />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        {/* Password Input */}
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel controlId="register_password" label={t('password')}>
                                <Form.Control
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    value={form.password == null ? '' : form.password}
                                    onChange={handleInputs("password")}
                                    placeholder=" "
                                    isInvalid={form.password !== null && errors.password !== null}
                                    isValid={form.password !== null && errors.password === null}
                                    autoComplete="none"
                                    onFocus={() => setShowPasswordChecklist(true)}
                                    onBlur={() => setShowPasswordChecklist(false)}
                                />
                                {showPassword ? <>
                                    <img className="showPassword" src="/assets/eye.svg" alt="eye" width="32px" onClick={() => setShowPassword(!showPassword)} />
                                </> : <>
                                    <img className="showPassword" src="/assets/eye-slash.svg" alt="eye-slash" width="32px" onClick={() => setShowPassword(!showPassword)} /></>}
                            </FloatingLabel>
                        </Form.Group>

                        {/* Password Input */}
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel controlId="register_password_confirmation" label={t('password_confirmation')}>
                                <Form.Control
                                    name="password_confirmation"
                                    type={showPassword ? "text" : "password"}
                                    value={form.password_confirmation == null ? '' : form.password_confirmation}
                                    onChange={handleInputs("password_confirmation")}
                                    placeholder=" "
                                    isInvalid={form.password_confirmation !== null && errors.password_confirmation !== null}
                                    isValid={form.password_confirmation !== null && errors.password_confirmation === null}
                                    autoComplete="none"
                                    onFocus={() => setShowPasswordChecklist(true)}
                                    onBlur={() => setShowPasswordChecklist(false)}
                                />
                                {showPassword ? <>
                                    <img className="showPassword" src="/assets/eye.svg" alt="eye" width="32px" onClick={() => setShowPassword(!showPassword)} />
                                </> : <>
                                    <img className="showPassword" src="/assets/eye-slash.svg" alt="eye-slash" width="32px" onClick={() => setShowPassword(!showPassword)} /></>}
                            </FloatingLabel>
                        </Form.Group>


                        <div className={showPasswordChecklist ? "d-block" : "d-none"}>
                            <div className="password-check-list card mb-3">
                                <div className="card-body p-1 mb-0">
                                    <PasswordChecklist
                                        rules={["minLength", "match"]}
                                        minLength={6}
                                        value={form.password ?? ""}
                                        valueAgain={form.password_confirmation ?? ""}
                                        onChange={(isValid) => { }}
                                        messages={{ minLength: t("password.min"), match: t("password.confirmed") }}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Username Input */}
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel controlId="register_username" label={t('username')}>
                                <Form.Control
                                    name="username"
                                    type="text"
                                    value={form.username == null ? '' : form.username}
                                    onChange={handleInputs("username")}
                                    placeholder=" "
                                    isInvalid={form.username !== null && errors.username !== null}
                                    isValid={form.username !== null && errors.username === null}
                                    autoComplete="none"
                                />
                                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        {/* Phone Input */}
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel className="form-control-phone" controlId="register_phone" label={t('phone')}>
                                <Form.Control
                                    name="phone"
                                    type="text"
                                    value={form.phone == null ? '' : form.phone}
                                    onChange={handleInputs("phone")}
                                    placeholder=" "
                                    isInvalid={form.phone !== null && errors.phone !== null}
                                    isValid={form.phone !== null && errors.phone === null}
                                    autoComplete="none"
                                />
                                <div className="form-control-prefix"><span>+60</span></div>

                                <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form.Group>

                        {/* Full Name Input */}
                        <Form.Group as={Col} xs={12} className="mb-3">
                            <FloatingLabel controlId="register_full_name" label={t('full_name')}>
                                <Form.Control
                                    name="full_name"
                                    type="text"
                                    value={form.full_name == null ? '' : form.full_name}
                                    onChange={handleInputs("full_name")}
                                    placeholder=" "
                                    isInvalid={form.full_name !== null && errors.full_name !== null}
                                    isValid={form.full_name !== null && errors.full_name === null}
                                    autoComplete="none"
                                />
                                <Form.Control.Feedback type="invalid">{errors.full_name}</Form.Control.Feedback>
                            </FloatingLabel>
                            <div className="w-100 mt-1">
                                <Form.Text className="text-muted">
                                    {t('full_name.helper')}
                                </Form.Text>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} className="mb-3">
                            <div className="w-100">
                                <Form.Text className="text-muted fs-smallest">
                                    <Trans i18nKey="register_modal.declariation">
                                        By clicking on “Join Now”, you certify that you have read and agree to our <Link className="text-link" to="/terms-and-conditions" onClick={() => props.data.RegisterModal.func.close()}>Terms of Service</Link> and that you are of legal gambling age in your jurisdiction.
                                    </Trans>
                                </Form.Text>
                            </div>
                        </Form.Group>

                        <Col xs={12} className="mb-3">
                            <div className="d-grid gap-2">
                                <Button type="submit" disabled={isSubmit}>
                                    {t('register.submit')}
                                </Button>
                            </div>
                        </Col>

                        <Form.Group as={Col} xs={12} className="mb-3">
                            <div className="w-100 text-center">
                                <Form.Text className="text-muted">
                                    <Trans i18nKey="register_modal.login_now">
                                        Already have an account with BD88? <span className="text-link" onClick={login}>Login Now</span>
                                    </Trans>
                                </Form.Text>
                            </div>
                        </Form.Group>
                    </Row>
                </Container>
            </Form>
        </Modal.Body>
    </Modal >
}