import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export default function LanguageHook() {
    const { i18n } = useTranslation();

    const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") ?? "en");

    useEffect(() => {
        localStorage.setItem("i18nextLng", language);
        i18n.changeLanguage(language);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language]);

    return {
        language: language,
        setLanguage: setLanguage,
    };

}