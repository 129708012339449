import React from 'react';
import { Link } from 'react-router-dom';

export const PromoImage = (props) => {
    const helpers = props.data.helpers;

    return <Link to={props.banner.location} className="promotion-card" key={props.banner.sequence}>
        <div className="promotion" draggable>
            <img className="promotion-image" src={props.data.isMobile ? helpers.t(props.banner.mobile_image) : helpers.t(props.banner.banner)} alt="banner" />
        </div>
    </Link>;
}