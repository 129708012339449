import { t } from 'i18next';
import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import "./Keypad.css";

function Keypad(props) {
    const { onChange, error, value, isMobile } = props;
    const [open, setOpen] = useState(false);
    const [tempValue, setTempValue] = useState("");

    const updateValue = (newValue) => {
        var new_value = parseInt(tempValue.toString() + newValue.toString());

        if (numberValidator(2, false)(new_value)) {
            setTempValue(new_value);
        }
    }

    const deleteValue = () => {
        var new_value = tempValue.toString().length === 1 ? null : tempValue.toString().slice(0, -1);

        if (numberValidator(2, false)(new_value)) {
            setTempValue(new_value);
        } else {
            setTempValue(0.00);
        }
    }

    const submit = () => {
        onChange({ target: { value: final_value(tempValue) } });
        setOpen(false);
    }

    const expand = () => {
        if (isMobile) {
            setOpen(true);
        }
    }

    const close = () => setOpen(false);

    const numberValidator = (decimal, negative) => value => {
        const positiveValidation = value => {
            if (value === 0 && Object.is(value, -0)) {
                return false;
            }
            return parseFloat(value) >= 0;
        };

        const integerValidation = value => parseFloat(value) % 1 === 0;
        if (value === '-' && negative) {
            return true;
        }
        if (Number.isNaN(Number(value))) {
            return false;
        }

        const floatValue = parseFloat(value);

        if (!decimal && !integerValidation(floatValue)) {
            return false;
        }

        if (typeof decimal === 'number' && decimal > 0) {
            if ((floatValue.toString().split('.')[1] || '').length > decimal) {
                return false;
            }
        }

        if (!negative && !positiveValidation(floatValue)) {
            return false;
        }
        return true;
    };

    const final_value = (value) => (value / 100).toFixed(2);

    useEffect(() => {
        setTempValue(0);
    }, [open]);

    return (
        <>
            <Form.Control
                id="deposit_amount"
                type="number"
                className="form-control text-grey"
                value={value === null ? '' : value}
                placeholder=" "
                isInvalid={value !== null && error !== null}
                isValid={value !== null && error === null}
                autoComplete="none"
                onChange={e => onChange(e)}
                onFocus={expand}
                readOnly={isMobile ? true : false}
            />

            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            <div className='keypad'>
                <div className={!open ? "offcanvas offcanvas-bottom offcanvas-keypad" : "offcanvas offcanvas-bottom offcanvas-keypad show"} tabIndex="-1">

                    <div className="offcanvas-body small">
                        <div className='d-flex justify-content-end'>
                            <button type="button" className="btn-close" onClick={close}></button>
                        </div>

                        <div className='d-flex justify-content-center mb-3'>
                            <h1>{final_value(tempValue)}</h1>
                        </div>
                        <div className="row">
                            <div className="col-4 text-end mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(1)}>1</button>
                            </div>
                            <div className="col-4 text-center mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(2)}>2</button>
                            </div>
                            <div className="col-4 text-start mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(3)}>3</button>
                            </div>
                            <div className="col-4 text-end mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(4)}>4</button>
                            </div>
                            <div className="col-4 text-center mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(5)}>5</button>
                            </div>
                            <div className="col-4 text-start mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(6)}>6</button>
                            </div>
                            <div className="col-4 text-end mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(7)}>7</button>
                            </div>
                            <div className="col-4 text-center mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(8)}>8</button>
                            </div>
                            <div className="col-4 text-start mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(9)}>9</button>
                            </div>
                            <div className="col-4 text-center mb-3"></div>
                            <div className="col-4 text-center mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => updateValue(0)}>0</button>
                            </div>
                            <div className="col-4 text-start mb-3">
                                <button className="btn btn-keypad" type="button" onClick={() => deleteValue()}>C</button>
                            </div>
                            <div className='col-12 text-center mt-2'>
                                <button className="btn btn-primary w-50" type="button" onClick={submit}>{t("Submit")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={!open ? '' : "offcanvas-backdrop keypad"} onClick={close}></div>
            </div>
        </>
    )
}

export default Keypad;