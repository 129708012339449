import React from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { t } from "i18next";

function NotFound(props) {

  const getAuth = () => {
    return props.data.Authentication;
  }

  const isAuth = () => {
    return getAuth().func.isLogin();
  }

  return (
    <div className={isAuth() ? "content logined" : "content"}>
      <Helmet>
        <title>{t("error.page_not_found")} | BD88</title>
      </Helmet>
      <Container>
        {t("error.page_not_found")}
      </Container>
    </div>
  );
}

export default NotFound;
