import React from 'react';
import { t } from "i18next";
import moment from 'moment';
import i18n from "../../Scripts/i18n";

export const MessageDescription = (props) => {
    require("moment/min/locales.min");
    moment.locale(i18n.language);
    const active = props.active;
    const notification = props.notification;
    const TransactionCanvas = props.data.TransactionCanvas;
    const InboxCanvas = props.data.InboxCanvas;

    const action = async (id) => {
        if (active === "inbox") {
            InboxCanvas.setData(id);
            InboxCanvas.func.open();
        } else {
            if (notification.type === "App\\Notifications\\TransactionStatus") {
                TransactionCanvas.setData(id.id);
                TransactionCanvas.func.open();
            }

        }
    }

    if (!notification) {
        return <></>;
    }

    if (active === "notification") {
        if (!notification.data) {
            return <></>;
        }
        return <div className="card" onClick={() => action(notification.data)}>
            <div className="card-body">
                <p className="notification-description">
                    {t(notification.data.message,notification.data)}
                </p>
                <p className="notification-datetime">
                    {moment(notification.data.datetime).fromNow()}
                </p>
            </div>
        </div>
    }

    if (active === "inbox") {
        return <div className="card" onClick={() => action(notification.id)}>
            <div className="card-body">
                <p className="inbox notification-description font-bolder">
                    {t(notification.title)}
                </p>
                <p className="notification-datetime">
                    {moment(notification.updated_at).fromNow()}
                </p>
            </div>
        </div>
    }

    return <></>;
}