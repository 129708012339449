import { request } from '../Core.js';

export const SearchService = {
    search,
};

function search(params) {
    params = { ...params, "token": sessionStorage.getItem("authentication") };

    return new Promise((resolve, reject) => {
        resolve(request('/search', params));
    });
}