import React from 'react';
import { Modal } from "react-bootstrap"
import { t } from "i18next";
import "./style.css";
import moment from "moment";

export const MaintenanceModal = (props) => {
    const data = props.data.MaintenanceModal.data;
    const end_at = props.data.MaintenanceModal.endAt;
    const close = () => {
        props.data.MaintenanceModal.func.close();
    }

    if (!data) {
        return <></>;
    }

    return <Modal
        className="MaintenanceModal"
        size="lg"
        show={props.data.MaintenanceModal.status}
        onHide={close}
        backdrop="static"
        keyboard={false}
        animation={false}
    >
        <Modal.Body className="d-flex flex-column align-items-center justify-content-center py-5">
            <button type="button" className="btn-close" aria-label="Close" onClick={props.data.MaintenanceModal.func.close}></button>
            <div className="d-flex justify-content-center">
                <img className="w-50" src={"https://assets.bd88my.online/assets/products/" + data.name + ".png"} alt={data.name} />
            </div>
            <div className="text-center mt-3">
                <p className="font-bolder fs-largest">{t("maintenance_modal.under_maintenance")}</p>
                {end_at === true ? <p>{t("maintenance_modal.message")}</p> : <p>{t("maintenance_modal.end_at_message", { end_at: moment(end_at).format('DD-MMMM-YYYY, hh:mm A') })}</p>}
            </div>
        </Modal.Body>
    </Modal>
}