import { Helmet } from "react-helmet";
import "./Profile.css";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { t } from "i18next";
import { ProfileBadge } from "../../Components/ProfileBadge/ProfileBadge";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { UserService } from "../../Models/User/UserService";
import SimpleReactValidator from "simple-react-validator";
import verify_logo from "../../Assets/Profile/verify.png";

function Profile(props) {
    const seo = props.data.Seo.data;
    const helpers = props.data.helpers;
    const data = props.data;
    const authentication = data.Authentication;
    const toast = props.data.toast;
    const [isSubmit, setIsSubmit] = useState(false);
    const months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

    const [form, setForm] = useState({
        birthday_day: authentication.user.birthday_day ?? null,
        birthday_month: authentication.user.birthday_month ?? null,
        birthday_year: authentication.user.birthday_year ?? null,

        old_password: null,
        new_password: null,
        new_password_confirmation: null,
    });

    const [errors, setErrors] = useState({
        old_password: null,
        new_password: null,
        new_password_confirmation: null,
    });

    const handleInputs = name => event => {
        if (name === "birthday_day" || name === "birthday_month" || name === "birthday_year") {
            if (authentication.user.birthday === null) {
                setForm({ ...form, [name]: event.value });
            }
        } else {
            setForm({ ...form, [name]: event.target.value });
        }
    };

    useEffect(() => {
        authentication.func.get();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const validator = new SimpleReactValidator({
            element: false,
            validators: {
                confirmed: {
                    rule: (val, params, validator) => {
                        return val === form[params[0]];
                    },
                }
            }
        });

        validator.message('old_password', form.old_password, 'required', {
            messages: {
                required: t('old_password.required'),
            },
        });

        validator.message('new_password', form.new_password, 'required', {
            messages: {
                required: t('new_password.required'),
            },
        });

        validator.message('new_password_confirmation', form.new_password_confirmation, 'required|confirmed:new_password', {
            messages: {
                required: t('new_password_confirmation.required'),
                confirmed: t('new_password_confirmation.confirmed'),
            },
        });

        const validate = () => {
            return new Promise(async function (resolve, reject) {
                var $errors = {};
                for (const [key, value] of Object.entries(validator.fields)) {
                    if (!value) {
                        $errors[key] = validator.errorMessages[key];
                    } else {
                        $errors[key] = null;
                    }
                }
                return resolve($errors);
            });
        }

        validate().then(errors => setErrors(errors));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);


    const getAuth = () => {
        return authentication;
    };

    const isAuth = () => {
        return getAuth().func.isLogin();
    };

    function generateArrayOfDay() {
        var min = 1
        var max = 31
        var output = []

        for (var i = min; i <= max; i++) {
            output.push({
                label: i,
                value: i
            });
        }

        return output;
    }

    function generateArrayOfMonth() {

        var min = 1
        var max = 12
        var output = []

        for (var i = min; i <= max; i++) {
            output.push({
                label: t(months[i - 1]),
                value: i
            });
        }
        return output;
    }

    function generateArrayOfYear() {
        var max = new Date().getFullYear() - 18;
        var min = 1900;
        var output = []

        for (var i = max; i >= min; i--) {
            output.push({
                label: i,
                value: i
            });
        }

        return output;
    }

    const update_profile = async (e) => {
        setIsSubmit(true);
        e.preventDefault();

        await UserService.update_profile(form).then((response) => {
            setIsSubmit(false);
            if (response.status) {
                toast.success(t(response.message));
                authentication.get();
                setForm({
                    birthday_day: form.birthday_day,
                    birthday_month: form.birthday_month,
                    birthday_year: form.birthday_year,

                    old_password: null,
                    new_password: null,
                    new_password_confirmation: null,
                });
            } else {
                toast.error(t(response.message));
            }

        }).catch((res) => {
            setIsSubmit(false);
        })

        return false;
    }

    return (
        <div className={isAuth() ? "content logined" : "content"}>
            <>
                <Helmet>
                    <title> {seo ? helpers.t(seo.meta_title) : t('profile.title') + " | BD88"}</title>
                    <meta name="keywords" content={ seo ? helpers.t(seo.meta_keyword) : ""} />
                    <meta name="description" content={ seo ? helpers.t(seo.meta_description) : ""}  />
                </Helmet>
                <Form onSubmit={update_profile} noValidate>
                    <Container className="py-3 px-lg-4">
                        <Row>
                            <Col xs="12" md="12" lg="4" xl="3" className="mb-3">
                                <ProfileBadge data={data} user={authentication.user} wallets={authentication.wallets} />
                            </Col>
                            <Col xs="12">
                                <h6 className="font-bolder">{t('profile.personal_details')}</h6>
                            </Col>
                            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                                <label className="text-grey font-bold fs-small">{t('profile.username')}</label>
                                <Form.Control type="text" value={authentication.user.username} placeholder={t('profile.username')} readOnly={true} />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                                <label className="text-grey font-bold fs-small">{t('profile.full_name')}</label>
                                <Form.Control type="text" value={authentication.user.full_name} placeholder={t('profile.full_name')} readOnly={true} />
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6} className="mb-2 position-relative">
                                <label className="text-grey font-bold fs-small">{t('profile.email')}</label>
                                <Form.Control type="text" value={authentication.user.email} placeholder={t('profile.email')} readOnly={true} />
                                {
                                    authentication.user.email_verify ? <>
                                        <img className="verify-logo" src={verify_logo} alt={t('profile.verfiy')} />
                                    </> :
                                        <>
                                            <Button className="btn-verify" size="sm" onClick={props.data.EmailVerify.func.open}>{t('profile.verify')}</Button>
                                        </>
                                }
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6} className="mb-2 position-relative">
                                <label className="text-grey font-bold fs-small">{t('profile.phone_number')}</label>
                                <Form.Control type="text" value={authentication.user.phone} placeholder={t('profile.phone_number')} readOnly={true} />
                                {
                                    authentication.user.phone_verify ? <>
                                        <img className="verify-logo" src={verify_logo} alt={t('profile.verfiy')} />
                                    </> :
                                        <>
                                            <Button className="btn-verify" size="sm" onClick={props.data.PhoneVerify.func.open}>{t('profile.verify')}</Button>
                                        </>
                                }
                            </Form.Group>
                            <Form.Group as={Col} xs={12} className="mb-3">
                                <label className="text-grey font-bold fs-small">{t('profile.date_of_birth')}</label>
                                <div className="d-flex-equal-width">
                                    <div>
                                        {
                                            !authentication.user.birthday ?
                                                <Select
                                                    className="fs-small font-bold text-grey"
                                                    value={generateArrayOfDay().filter(function (option) {
                                                        return parseInt(option.value) === parseInt(form.birthday_day);
                                                    })}
                                                    options={generateArrayOfDay()}
                                                    placeholder={t('profile.birthday_day')}
                                                    onChange={handleInputs('birthday_day')}
                                                    styles={helpers.reactSelectStyles()}
                                                    isSearchable={false}
                                                /> :
                                                <Form.Control
                                                    type="text"
                                                    value={form.birthday_day}
                                                    placeholder={t('profile.birthday_day')}
                                                    readOnly={true}
                                                />
                                        }
                                    </div>
                                    <div>
                                        {
                                            !authentication.user.birthday ?
                                                <Select
                                                    className="fs-small font-bold text-grey"
                                                    value={generateArrayOfMonth().filter(function (option) {
                                                        return parseInt(option.value) === parseInt(form.birthday_month);
                                                    })}
                                                    options={generateArrayOfMonth()}
                                                    onChange={handleInputs('birthday_month')}
                                                    placeholder={t('profile.birthday_month')}
                                                    styles={helpers.reactSelectStyles()}
                                                    isSearchable={false}
                                                /> :
                                                <Form.Control
                                                    type="text"
                                                    value={t(months[form.birthday_month - 1])}
                                                    placeholder={t('profile.birthday_month')}
                                                    readOnly={true}
                                                />
                                        }
                                    </div>
                                    <div>
                                        {
                                            !authentication.user.birthday ?
                                                <Select
                                                    className="fs-small font-bold text-grey"
                                                    value={generateArrayOfYear().filter(function (option) {
                                                        return parseInt(option.value) === parseInt(form.birthday_year);
                                                    })}
                                                    options={generateArrayOfYear()}
                                                    onChange={handleInputs('birthday_year')}
                                                    placeholder={t('profile.birthday_year')}
                                                    styles={helpers.reactSelectStyles()}
                                                    isSearchable={false}
                                                /> :
                                                <Form.Control
                                                    type="text"
                                                    value={form.birthday_year}
                                                    placeholder={t('profile.birthday_year')}
                                                    readOnly={true}
                                                />
                                        }
                                    </div>
                                </div>
                                <label className="fs-small text-grey">{t('profile.birthday_description')}</label>
                            </Form.Group>
                            <Col xs="12">
                                <h6 className="font-bolder">{t('profile.update_password')}</h6>
                            </Col>
                            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                                <label className="text-grey font-bold fs-small">{t('profile.old_password')}</label>
                                <Form.Control
                                    type="password"
                                    value={form.old_password === null ? '' : form.old_password}
                                    onChange={handleInputs('old_password')}
                                    placeholder={t('profile.old_password')}
                                    autoComplete="false"

                                    isInvalid={form.old_password !== null && errors.old_password !== null}
                                    isValid={form.old_password !== null && errors.old_password === null}
                                />
                                <Form.Control.Feedback type="invalid">{errors.old_password}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                                <label className="text-grey font-bold fs-small">{t('profile.new_password')}</label>
                                <Form.Control
                                    type="password"
                                    value={form.new_password === null ? '' : form.new_password}
                                    onChange={handleInputs('new_password')}
                                    placeholder={t('profile.new_password')}
                                    autoComplete="false"

                                    isInvalid={form.new_password !== null && errors.new_password !== null}
                                    isValid={form.new_password !== null && errors.new_password === null}
                                />
                                <Form.Control.Feedback type="invalid">{errors.new_password}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} xs={12} md={6} className="mb-2">
                                <label className="text-grey font-bold fs-small">{t('profile.new_password_confirmation')}</label>
                                <Form.Control
                                    type="password"
                                    value={form.new_password_confirmation === null ? '' : form.new_password_confirmation}
                                    onChange={handleInputs('new_password_confirmation')}
                                    placeholder={t('profile.new_password_confirmation')}
                                    autoComplete="false"

                                    isInvalid={form.new_password_confirmation !== null && errors.new_password_confirmation !== null}
                                    isValid={form.new_password_confirmation !== null && errors.new_password_confirmation === null}
                                />
                                <Form.Control.Feedback type="invalid">{errors.new_password_confirmation}</Form.Control.Feedback>
                            </Form.Group>

                            <Col xs={12} className="mt-3">
                                <Button variant="blue" className="w-100" type="submit" disabled={isSubmit}>
                                    {t('profile.submit')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Form>
            </>
        </div>
    );
}

export default Profile;
