import { t } from "i18next";

function Circles(props) {
    const products = props.products;
    const maintenances = props.data.MaintenanceModal.maintenances;
    const selectedProducts = props.selectedProducts;
    const setSelectedProducts = props.setSelectedProducts;

    const setProduct = (product_id) => {
        if (selectedProducts.includes(product_id)) {
            setSelectedProducts([]);
        } else {
            setSelectedProducts([product_id]);
        }
    }

    const openMaintenance = (element) => {
        props.data.MaintenanceModal.func.open(element, maintenances[element.id]);
    }

    const getIndexArray = () => {
        const rows = [];
        for (let i = 0; i < 6; i++) {
            rows.push(i);
        }
        return rows;
    }

    if (!products.length) {
        return <div className="circles mb-3">
            {getIndexArray().map((v, i) => {
                return <div className="circle placeholder-glow" key={i}>
                    <div className="circle-content text-dark">
                        <span className="placeholder image-square" style={{ borderRadius: "50%", height: "64px", width: "64px" }}></span>
                    </div>
                    <span className="circle-title placeholder"></span>
                </div>
            })}
        </div >
    }

    return <>
        <div className="circles mb-3">
            {products ? products.map(element => {
                if (!maintenances.hasOwnProperty(element.id)) {
                    return <div key={element.id} className="circle" onClick={() => setProduct(element.id)}>
                        {element.label === 1 ? <div className="badge game-badge hot">{t("game.hot")}</div> : <></>}
                        {element.label === 2 ? <div className="badge game-badge new">{t("game.new")}</div> : <></>}

                        <div className={selectedProducts.includes(element.id) ? "circle-content active" : "circle-content"}>
                            <img src={element.image} alt={element.name} />
                        </div>
                        <span className="circle-title">{element.name}</span>
                    </div>
                } else {
                    return <div key={element.id} className="circle maintenance" onClick={() => openMaintenance(element)}>
                        {element.label === 1 ? <div className="badge game-badge hot">{t("game.hot")}</div> : <></>}
                        {element.label === 2 ? <div className="badge game-badge new">{t("game.new")}</div> : <></>}

                        <div className={selectedProducts.includes(element.id) ? "circle-content active" : "circle-content"}>
                            <img src={element.image} alt={element.name} />
                        </div>
                        <span className="circle-title">{element.name}</span>
                    </div>
                }
            }) : <></>}
        </div>
    </>;
}

export default Circles;