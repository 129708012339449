import React from 'react';
import "./FloatingIcon.css";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import moment from 'moment';

export const FloatingIcon = (props) => {
    const { t } = useTranslation();
    const status = props.data.FloatingIcon.status;
    const user = props.data.Authentication.user;

    const getLanguage = () => {
        if (i18next.language === "zh_CN") {
            return "cn";
        }
        return "en";
    }

    const click = () => {
        if (user) {
            props.data.MissionModal.func.open();
            return true;
        }
        props.data.LoginModal.func.open();
    }

    const close = () => {
        props.data.FloatingIcon.func.close();
        window.localStorage.setItem('floating_icon', (new Date()).getTime() + 86400000);
    }

    if (!status) {
        return <></>;
    }

    if (moment() > moment("2023-01-20 00:00:00") && moment() < moment("2023-02-06 00:00:00")) {
        return <div className="floating-icon angpow">
            <img src={getLanguage() === "en" ? "/assets/angpow_floating_en.gif" : "/assets/angpow_floating_cn.gif"} alt="angpow" onClick={() => (user) ? props.data.Angpow.func.open() : props.data.LoginModal.func.open()} />
        </div>
    }

    if (!user) {
        return <></>;
    }

    return <>
        {/* <div className="floating-icon">
            <img src="/assets/worldcup.gif" alt="missions" onClick={() => (user) ? props.data.WorldCupGiveaway.func.open() : props.data.LoginModal.func.open()} />
            <div className="floating-label">
                {t('MYR 12,888')}
            </div>
        </div> */}

        <div className="floating-icon">
            <div className="btn-close floating-close-button" onClick={close}>
            </div>
            <img src="/assets/mission_gift.gif" alt="missions" onClick={click} />
            <div className="floating-label">
                {t('mission.title')}
            </div>
        </div>
    </>
};


export default FloatingIcon;
