import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import Loading from '../../Components/Loading/Loading';
import PromoCategory from './PromoCategory';
import { t } from "i18next";
import './Promo.css';

function Promos(props) {
  const seo = props.data.Seo.data;
  const helpers = props.data.helpers;
  const PromoHook = props.data.PromoHook;
  const data = props.data.PromoHook.data;

  const getAuth = () => {
    return props.data.Authentication;
  }

  const isAuth = () => {
    return getAuth().func.isLogin();
  }

  useEffect(() => {
    PromoHook.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={isAuth() ? "content logined" : "content"}>
      <Helmet>
        <title> {seo ? helpers.t(seo.meta_title) : t('promos') + " | BD88"}</title>
        <meta name="keywords" content={seo ? helpers.t(seo.meta_keyword) : ""} />
        <meta name="description" content={seo ? helpers.t(seo.meta_description) : ""} />
      </Helmet>
      <Container className='pt-1 mb-3 px-lg-4'>
        {
          data === null ?
            <Loading /> :
            <PromoCategory data={props.data} promotion_category={data} />
        }
      </Container>
    </div>
  );
}

export default Promos;
