import React from 'react';
import { t } from "i18next"
import { Card } from "react-bootstrap"

export const WalletCardLoader = (props) => {
    return <Card className="wallet">
        <Card.Body>
            <Card.Title>{t(props.wallet.name)}</Card.Title>
            <div className="balance-info">
                <span className="currency"></span>
                <span className="balance">{t('loading')}</span>
            </div>
        </Card.Body>
        <Card.Footer>
            <span className="action" onClick={() => { }}>
                {t('cashier.deposit_now')}
            </span>
        </Card.Footer>
    </Card>
}