import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

export const ProfileInfo = (props) => {
    const user = props.data.Authentication.user;
    const helpers = props.data.helpers;
    const [isLoading, setIsLoading] = useState(false);

    const fetch_balance = () => {
        setIsLoading(true);
        props.data.Authentication.func.get().then(function () {
            setIsLoading(false);
        });
    };

    useEffect(() => {

    }, [user]);

    if (props.data.isMobile || !props.data.Authentication.func.isLogin()) {
        return <></>;
    }

    return <Row className="profile-info ms-auto">
        <Col xs="12" className="text-end">
            <div className="user-info">
                <span className="rank">{t(helpers.getRank(user))}</span>
            </div>
            <div className="balance-info">
                <span className="text-blue" onClick={ !isLoading ? fetch_balance : null}>
                    {isLoading ? <span className="text-dark">{t('loading')}</span> : t('user-balance', { currency: user.currency, balance: helpers.number_format(user.balance, 2) })}&nbsp;
                    <FontAwesomeIcon className={isLoading ? "active spin text-grey" : "text-grey"} icon={solid('refresh')} />
                </span>
                <span className="px-1">|</span>
                <span>{t('user-point', { point: user.point, count: user.point > 0 ? 2 : 1 })}</span>
            </div>
        </Col>
    </Row>;
}